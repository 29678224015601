import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getSumAndNameSubetriesForMonth, getSumPaidCostsForMonth, getAllPaidCostsListForMonthFromSubetries, getSumAndNameArticlesForMonth} from '../../../../redux/admin-reducer'
import CostsList from './CostsList';

class CostsListContainer extends React.Component{
    componentDidMount(){
        // this.props.getSumAndNameArticlesForMonth(this.props.match.params.month, this.props.match.params.year);
        // this.props.getSumPaidCostsForMonth(this.props.match.params.month, this.props.match.params.year);
        this.props.getAllPaidCostsListForMonthFromSubetries(this.props.match.params.month, this.props.match.params.year)
    }
    componentDidUpdate(prevProps){
        if(prevProps.match.params.month !== this.props.match.params.month){
            // this.props.getSumAndNameArticlesForMonth(this.props.match.params.month, this.props.match.params.year);
            // this.props.getSumPaidCostsForMonth(this.props.match.params.month, this.props.match.params.year);
            this.props.getAllPaidCostsListForMonthFromSubetries(this.props.match.params.month, this.props.match.params.year)
        }
        if(prevProps.match.params.year !== this.props.match.params.year){
            // this.props.getSumAndNameArticlesForMonth(this.props.match.params.month, this.props.match.params.year);
            // this.props.getSumPaidCostsForMonth(this.props.match.params.month, this.props.match.params.year);
            this.props.getAllPaidCostsListForMonthFromSubetries(this.props.match.params.month, this.props.match.params.year)
        }
    }
    
    render(){
        return (
            <div>
                <CostsList checkCostsList={this.props.checkCostsList} sumCosts={this.props.sumCosts} subentries={this.props.subentries} month={this.props.match.params.month} articles={this.props.articles} getSumAndNameSubetriesForMonth={this.props.getSumAndNameSubetriesForMonth} year={this.props.match.params.year}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    checkCostsList: state.admin.checkCostsList,
    // sumCosts: state.admin.sumCosts,
    // subentries: state.admin.subentries,
    // articles: state.admin.articles
})
export default compose( connect( mapStateToProps, {getSumAndNameSubetriesForMonth, getSumPaidCostsForMonth, getAllPaidCostsListForMonthFromSubetries, getSumAndNameArticlesForMonth}), withRouter )(CostsListContainer);