import React from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {
    getAllInfoFromCheck,
    getAllModulsFromCheck,
    setNewCourseAndSumCheck,
    setDaysManagerFromCheck,
    setPlanDateFromCheck,
    setCommentForCheck,
    setDontShowKp,
    changeCollModulsInCheck,
    requestForKpStatus,
    deleteCheck
} from '../../../redux/check-reducer';
import {getAllProject} from '../../../redux/project-reducer';
import Check from './Check';

class CheckContainer extends React.Component {
    componentDidMount() {
        this.props.getAllInfoFromCheck(this.props.match.params.idCheck, this.props.match.params.typeCheck);
        this.props.getAllModulsFromCheck(this.props.match.params.idCheck, this.props.match.params.typeCheck);
        this.props.getAllProject();

    }

    render() {
        return (
            <div>
                <Check info={this.props.allInfoFromCheck}
                       moduls={this.props.allModulesFromCheck}
                       type={this.props.match.params.typeCheck}
                       setNewCourseAndSumCheck={this.props.setNewCourseAndSumCheck}
                       setDaysManagerFromCheck={this.props.setDaysManagerFromCheck}
                       setPlanDateFromCheck={this.props.setPlanDateFromCheck}
                       setCommentForCheck={this.props.setCommentForCheck} setDontShowKp={this.props.setDontShowKp}
                       changeCollModulsInCheck={this.props.changeCollModulsInCheck}
                       requestForKpStatus={this.props.requestForKpStatus} allProject={this.props.allProject}
                       deleteCheck={this.props.deleteCheck}
                       useCases={this.useCases}
                       idCheck={this.props.match.params.idCheck}
                />
            </div>
        )
    }
}

let mapStateToProps = (state) => ({
    allInfoFromCheck: state.check.allInfoFromCheck,
    allModulesFromCheck: state.check.allModulesFromCheck,
    allProject: state.project.allProject,
})

export default compose(connect(mapStateToProps, {
    getAllInfoFromCheck,
    getAllModulsFromCheck,
    setNewCourseAndSumCheck,
    setDaysManagerFromCheck,
    setPlanDateFromCheck,
    setCommentForCheck,
    setDontShowKp,
    changeCollModulsInCheck,
    requestForKpStatus,
    getAllProject,
    deleteCheck
}), withRouter)(CheckContainer);
