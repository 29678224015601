import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getModuleSalesStatistics} from '../../../redux/statistics-reducer';
import StatisticsModules from './StatisticsModules';

class StatisticsModulesContainer extends React.Component{
    componentDidMount(){
        this.props.getModuleSalesStatistics();
    }
    componentDidUpdate(prevProps){
        // if(prevProps.moduleSalesStatistics !== this.props.moduleSalesStatistics){
        //     this.props.getModuleSalesStatistics();
        // }
    }
    
    render(){
        return (
            <div>
                <StatisticsModules moduleSalesStatistics={this.props.moduleSalesStatistics} getModuleSalesStatistics={this.props.getModuleSalesStatistics}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    moduleSalesStatistics: state.statistics.moduleSalesStatistics
})
export default compose( connect( mapStateToProps, {getModuleSalesStatistics}), withRouter )(StatisticsModulesContainer);