import React from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {
    addCompanyPartner,
    addNewContact,
    deleteCompanyAgent,
    deleteMessageFromAgent,
    editCompanyComment,
    editCompanyDiscount,
    editCompanyManager1,
    getClientSegmentationsList,
    getCompanyList,
    getCompanyProfile,
    getRegionsList,
    getSegmentationsList,
    setAgentSubscription,
    setClientCompanySegmentation,
    setCompany2Segmentation,
    setCompanyAkonName,
    setCompanyContract,
    setCompanyDeliveryAddress,
    updateCompanyAgent,
    setCompanyEdrpou,
    setCompanyManager2,
    setCompanyMessage,
    setCompanyName,
    setCompanyRegion,
    setCompanySegmentation,
    setCompanySite,
    setCompanyCity,
    setNewCompanyMessage,
    unbindCompanyPartner,
    unbindSupplier,
    bindSupplier,
    bindTag,
    bindNewCategory,
    unbindNewCategory,
    bindNewIndustry,
    unbindNewIndustry,
    unbindMainManager,
    setCompanyToPlannerFromCompanyProfile,
    unbindTag,
    forceRejectCompanySecondManager, getCityList
} from '../../../../redux/company-reducer';
import Cookies from 'js-cookie'
import CompanyProfile from "./CompanyProfile";
import LoadingPlaceholder from "../../common/LoadingComponent/LoadingPlaceholder";
import {rejectCompany2ndManager, setCompanyToPlanner, getAllCompanyPlanerInThisDate} from "../../../../redux/sales-reducer";


class CompanyProfileContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let request = {
            'companyID': this.props.match.params.companyID,
            'managerID': Cookies.get('id'),
        };

        await this.props.getCompanyProfile(request);
        await this.props.getCompanyList();
        await this.props.getRegionsList();
        await this.props.getSegmentationsList();
        await this.props.getClientSegmentationsList();


        // Set loading to false after all data is loaded
        this.setState({isLoading: false});
    }

    render() {
        return (
            <CompanyProfile {...this.props} isLoading={this.state.isLoading}/>
        );
    }
}

let mapStateToProps = (state) => ({
    data: state.company.companyProfile ?? null,
    companyList: state.company.companyList.data ?? null,
    regionsList: state.company.regionsList.data ?? null,
    segmentationsList: state.company.segmentationsList.data ?? null,
    clientSegmentationsList: state.company.clientSegmentationsList.data ?? null,
    cityList: state.company.cityList.data ?? null,
    countCompanyPlanerInThisDate: state.sales.countCompanyPlanerInThisDate,
})
export default compose(connect(mapStateToProps, {
    getCompanyProfile,
    getCompanyList,
    getRegionsList,
    getCityList,
    getSegmentationsList,
    getClientSegmentationsList,
    unbindCompanyPartner,
    unbindSupplier,
    bindSupplier,
    bindTag,
    bindNewCategory,
    unbindNewCategory,
    bindNewIndustry,
    unbindNewIndustry,
    unbindMainManager,
    addNewContact,
    setAgentSubscription,
    updateCompanyAgent,
    deleteCompanyAgent,
    deleteMessageFromAgent,
    setCompanyEdrpou,
    setCompanyToPlannerFromCompanyProfile,
    setCompanyRegion,
    unbindTag,
    setCompanyName,
    setCompanySite,
    setCompanyAkonName,
    setCompanyCity,
    editCompanyDiscount,
    editCompanyComment,
    editCompanyManager1,
    setCompanyManager2,
    addCompanyPartner,
    setCompanyDeliveryAddress,
    setCompanyContract,
    setClientCompanySegmentation,
    setCompanySegmentation,
    setCompany2Segmentation,
    setNewCompanyMessage,
    setCompanyMessage,
    forceRejectCompanySecondManager,
    getAllCompanyPlanerInThisDate
}), withRouter)(CompanyProfileContainer);
