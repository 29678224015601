import React, {useState} from 'react';
import s from "./Suppliers.module.css";
import {ResponsiveContainer, AreaChart, XAxis, YAxis, Area, Tooltip, CartesianGrid} from 'recharts';

const Suppliers = (props) =>{
    let [linkState, setLinkState] = useState("");
    let [showAllSuppliers, setShowAllSuppliers] = useState(false);
    let setShowAllSuppliersF = ()=>{
        setShowAllSuppliers(!showAllSuppliers);
    }
    //функция для работы с датой
    let getNewFormatDate = (date)=>{
        let newDate = date.split("-");
        return newDate[1]+"-"+newDate[0];
    }

    let data = [];
    if(props.purchasedComponents.length > 0){
        for(let i=0; props.purchasedComponents.length > i; i++){
            data.push({
                date: getNewFormatDate(props.purchasedComponents[i].date),
                value: Number(props.purchasedComponents[i].unit_price),
            })
        }
    }
    
    
    //передаём сюда id всех компаний поставщиков и если у данного продукта есть совпадение, возвращаем "правду", таким образом формируем список поставщиков именно этого продукта
    let returnApp = (id) =>{
        for(let i=0; props.componentSuppliers.length > i; i++){
            if(props.componentSuppliers[i] === id){
                return true
            }
        }
    }
    let setDoverka = (doverka) =>{
        if(doverka === "1"){
            return "Генеральная";
        }
        return "Нужна";
    }
    //двумя функциями выясняем проставлена ли галочка или нет
    function checkAvailability(arr, val) {
        return arr.some(function(arrVal) {
          return val === arrVal;
        });
    }
    let setCheckbox = (companyId) =>{
        if(checkAvailability(props.componentSuppliers, companyId)){
            return "checked";
        } 
    }
    let set = (e) =>{
        props.setComponentSuppliers(e.target.value, e.target.checked, props.componentId);
    }
    const setLinkStateF = (e) =>{
        setLinkState(e.target.value)
    }
    const getLinkForBy = (companyId) =>{
        let link = false;
        for(let i=0;props.linkForBuy.length > i;i++){
            if(props.linkForBuy[i].company_accessories_id === companyId){
                link = <a href={props.linkForBuy[i].accessories_link} target="_blank">Ссылка</a>;
            }
        }
        if(link){return link}
        else{
            return <><input value={linkState} onChange={setLinkStateF} /><button onClick={() => setNewLinkForBy(companyId)}>Сохранить ссылку</button></>;
        }   
    }
    const setNewLinkForBy = (companyId) =>{
        props.setNewLinkForBy(companyId,linkState,props.componentId);
    }
    return(<>
    <h1 className={s.textCenter}>{props.storageComponentPersonalData.name+ " - " +props.storageComponentPersonalData.specification_id}</h1>
    <h2>У этих компаний мы закупаем этот продукт:</h2>
    <div className={s.main}>
        <div className={s.header}>#</div>
        <div className={s.header}>Название</div>
        <div className={s.header}>Доверенность нужна/нет</div>
        <div className={s.header}>Ссылка на покупку продукта</div>
        <div className={s.header}>Отметить</div>
        
    {
        props.allSuppliers.map( p => {
            if(returnApp(p.id)){
                return(<>
                <div>{p.id}</div>
                <div>{p.name}</div>
                <div>{setDoverka(p.doverka)}</div>
                <div>{getLinkForBy(p.id)}</div>
                <div><input type="checkbox" checked={setCheckbox(p.id)} value={p.id} onChange={set} /></div>
                </>)}
        })
    }
    </div>
    <h2><span onClick={()=>setShowAllSuppliersF()}>{showAllSuppliers ? "Скрыть список компаний" : "Показать весь список поставщиков"}</span></h2>
    <div className={showAllSuppliers ? s.mainFour : s.unshow}>
        <div className={s.header}>#</div>
        <div className={s.header}>Название</div>
        <div className={s.header}>Доверенность нужна/нет</div>
        <div className={s.header}>Отметить</div>
        
    {
        props.allSuppliers.map( p => {
            if(!returnApp(p.id)){
                return(<>
                <div>{p.id}</div>
                <div>{p.name}</div>
                <div>{setDoverka(p.doverka)}</div>
                <div><input type="checkbox" checked={setCheckbox(p.id)} value={p.id} onChange={set} /></div>
                </>)}
        })
    }
    </div>
    <h2>Динамика цен на компонент</h2>
    <ResponsiveContainer width="100%" height={600} className={s.area}>
        <AreaChart data={data}>
            <defs>
                <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#2451B7" stopOpacity={0.4}/>
                    <stop offset="75%" stopColor="#2451B7" stopOpacity={0.05}/>
                </linearGradient>
            </defs>
            <Area dataKey="value" stroke="#2451B7" fill="url(#color)"/>
            <XAxis dataKey="date"  />
            <YAxis dataKey="value" axisLine={false} tickLine={false} tickCount={8} tickFormatter={ (number) => "₴ "+number.toFixed(2)} className={s.grn} />
            <Tooltip />
            <CartesianGrid opacity={0.1} vertical={false} />
        </AreaChart>
    </ResponsiveContainer>
    </>)
}
export default Suppliers;