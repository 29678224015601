import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { TextField, Button } from "@mui/material";
import { AutoFixHigh } from "@mui/icons-material";
import { connect } from "react-redux";
import { compose } from 'redux';
import TemplateTable from '../common/TemplateTable/TemplateTable';
import s from './AddComponent/AddComponent.module.css';
import sp from "./SectionProduction/SectionProduction.module.css";
import {
    changeCollComponent,
    addSectionURL,
    searchInSection,
    setComponentToSection,
    getComponentFromSection,
    changeComponentSection,
    changeMinColl,
    getSection
} from '../../../redux/addStorage-reducer';
import { withRouter } from "react-router-dom";
import { productionAPI } from "../../../api/api";

const StorageContainer = (props) => {
    const [cookies] = useCookies();
    const [components, setComponents] = useState(props.component);
    const [searchInput, setSearchInput] = useState("");
    const [oldStyleSearch, setOldStyleSearch] = useState(true);
    const [searchByCode, setSearchByCode] = useState(true);
    const [searchByName, setSearchByName] = useState(false);
    const [currentSection, setCurrentSection] = useState(props.sectionURL ?? 19);
    const [storageSectionsList, setStorageSectionsList] = useState([]);

    useEffect(() => {
        props.addSectionURL(currentSection);
        props.getComponentFromSection(currentSection);
    }, [currentSection]);

    useEffect(() => {
        fetchStorageSectionsList();
    }, []);

    useEffect(() => {
        setComponents(props.component);
    }, [props.component]);

    useEffect(() => {
        if (searchInput.length > 0) {
            search(searchInput);
        }
    }, [props.sectionURL]);

    const updateComponentName = (id, newName) => {
        setComponents((prevComponents) =>
            prevComponents.map((component) =>
                component.id === id ? { ...component, name: newName } : component
            )
        );
    };

    const fetchStorageSectionsList = async () => {
        try {
            const response = await productionAPI.getSectionNew();
            const data = response.data;
            setStorageSectionsList(data);
            console.log(data);
        } catch (error) {
            console.error("Error fetching storage sections list:", error);
        }
    };

    const handleSearchNameButton = () => {
        setSearchByName(!searchByName);
    };

    const handleSearchCodeButton = () => {
        setSearchByCode(!searchByCode);
    };

    const handleClick = () => {
        setOldStyleSearch(!oldStyleSearch);
    };

    const handleChange = (searchValue) => {
        setSearchInput(searchValue);
        if (searchValue.length === 0) return;
        search(searchValue);
    };

    const search = (searchVal) => {
        if (searchVal.length === 0) return;
        if (!oldStyleSearch && searchVal.length > 0) {
            props.searchInSection(searchVal, null, null, null);
        }
        if (oldStyleSearch) {
            props.searchInSection(searchVal, currentSection, searchByCode, searchByName);
        }
    };

    const handleSectionChange = (sectionId) => {
        setCurrentSection(sectionId);
    };

    return (
        <div>
            <div className={sp.main}>
                <h3>Подразделы комплектующих:</h3>
                {storageSectionsList && Array.isArray(storageSectionsList) ? storageSectionsList.map(p => (
                    <button
                        key={p.id}
                        id={p.id}
                        onClick={() => handleSectionChange(p.id)}
                        className={currentSection === p.id ? sp.active : ""}
                    >
                        {p.section}
                    </button>
                )) : ''}
            </div>
            {currentSection && (
                <div className={s.search}>
                    <TextField
                        id="outlined-basic"
                        label="Поиск"
                        variant="outlined"
                        value={searchInput}
                        onChange={(e) => handleChange(e.target.value)}
                        fullWidth={true}
                        InputProps={{
                            startAdornment: (
                                <Button
                                    onClick={handleClick}
                                    color={oldStyleSearch ? 'error' : 'success'}
                                    variant={'contained'}
                                    style={{ marginRight: '10px' }}
                                >
                                    <AutoFixHigh />
                                </Button>
                            ),
                            endAdornment: (
                                <>
                                    <Button
                                        onClick={handleSearchCodeButton}
                                        color={searchByCode ? 'success' : 'error'}
                                        style={{ display: oldStyleSearch ? 'inline-block' : 'none' }}
                                        variant={'contained'}
                                    >
                                        Код
                                    </Button>
                                    <Button
                                        onClick={handleSearchNameButton}
                                        color={searchByName ? 'success' : 'error'}
                                        style={{ display: oldStyleSearch ? 'inline-block' : 'none', marginLeft: '10px', marginRight: '10px' }}
                                        variant={'contained'}
                                    >
                                        Название
                                    </Button>
                                </>
                            )
                        }}
                    />
                </div>
            )}
            {currentSection && (
                <TemplateTable
                    changeComponentSection={props.changeComponentSection}
                    component={components.component}
                    changeCollComponent={props.changeCollComponent}
                    sectionURL={currentSection}
                    section={props.section}
                    personalId={cookies.id}
                    setComponentToSection={props.setComponentToSection}
                    searchInput={searchInput}
                    changeMinColl={props.changeMinColl}
                    updateComponentName={updateComponentName}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    component: state.addStorage.component,
    sectionURL: state.addStorage.sectionURL,
    section: state.addStorage.section
});

export default compose(connect(mapStateToProps, {
    changeCollComponent,
    addSectionURL,
    searchInSection,
    setComponentToSection,
    getComponentFromSection,
    changeComponentSection,
    changeMinColl,
    getSection
}), withRouter)(StorageContainer);
