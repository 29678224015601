import React from 'react';
import { withRouter } from 'react-router-dom';
import s from '../DDC.module.css';

const Balance = (props) =>{
    let balance = Number(props.result)-Number(props.cvoMoney)
    balance = balance.toLocaleString()
    return(
        <div>
            <h3><span className={s.title}>% ИД - {props.cvoMoney.toLocaleString()+" грн"}</span></h3>
            <h2><span className={s.title}>Баланс: {balance+" грн"}</span></h2>
            
        </div>
    )
}
export default withRouter(Balance);