import React from 'react';
import {  withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ShipmentStatistics from './ShipmentStatistics';
import {getModuleSalesStatistics} from '../../../redux/statistics-reducer';
import {getAllBomNomenclatures, getAllComponentFromNomenclatures, getStorageAndAveragePrice} from '../../../redux/admin-reducer';
import {getAllComponentsFromStorage} from '../../../redux/addStorage-reducer';

class ShipmentStatisticsContainer extends React.Component{
    
    componentDidMount(){
        this.props.getModuleSalesStatistics(this.props.match.params.year);
        this.props.getAllBomNomenclatures();
        this.props.getAllComponentFromNomenclatures();
        this.props.getStorageAndAveragePrice();
        this.props.getAllComponentsFromStorage();
    }
    componentDidUpdate(prevProps){
        if(this.props.match.params.year !== prevProps.match.params.year){
            this.props.getModuleSalesStatistics(this.props.match.params.year);
            this.props.getAllBomNomenclatures();
            this.props.getAllComponentFromNomenclatures();
            this.props.getStorageAndAveragePrice();
        }
    }
    
    render(){
        return (
        <div>
            <ShipmentStatistics moduleSalesStatistics={this.props.moduleSalesStatistics} nomenclatures={this.props.nomenclatures} allComponentFromNomenclatures={this.props.allComponentFromNomenclatures} storageAndAveragePrice={this.props.storageAndAveragePrice} getModuleSalesStatistics={this.props.getModuleSalesStatistics} allComponentsFromStorage={this.props.allComponentsFromStorage}/>
        </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    moduleSalesStatistics: state.statistics.moduleSalesStatistics,
    nomenclatures: state.admin.nomenclatures,
    allComponentFromNomenclatures: state.admin.allComponentFromNomenclatures,
    storageAndAveragePrice: state.admin.storageAndAveragePrice,
    allComponentsFromStorage: state.addStorage.allComponentsFromStorage
})
export default compose( connect( mapStateToProps, {getModuleSalesStatistics, getAllBomNomenclatures, getAllComponentFromNomenclatures, getStorageAndAveragePrice, getAllComponentsFromStorage}), withRouter )(ShipmentStatisticsContainer);