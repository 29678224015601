import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    acceptCompany2ndManager,
    getAllSharedCompanyWithManager,
    rejectCompany2ndManager
} from '../../../redux/sales-reducer';
import AllSharedCompanyWithManager from './AllSharedCompanyWithManager';
import Cookies from 'js-cookie'

class AllSharedCompanyWithManagerContainer extends React.Component{
    componentDidMount(){
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.match.params.manager;
        if(!isAdmin){
            if(Cookies.get('id')!=currentPersonalId){
                currentPersonalId = Cookies.get('id');
            }
        }
        this.props.getAllSharedCompanyWithManager(currentPersonalId);
        //console.log(this.props.allSharedCompanyWithManager)
    }
    
    render(){
        return (
            <div>
                <AllSharedCompanyWithManager
                    {...this.props}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    allSharedCompanyWithManager: state.sales.allSharedCompanyWithManager,
})
export default compose( connect( mapStateToProps, {
    getAllSharedCompanyWithManager,
    acceptCompany2ndManager,
    rejectCompany2ndManager
}), withRouter )(AllSharedCompanyWithManagerContainer);
