import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getCheckTest, getCheckTestModuls, changeDateReminder, buyModulsTest, returnModulsTest, changeCommentModulsTest, getReturnFromTest, changeNumberReturnFromTest} from '../../../redux/sales-reducer';
import CheckTest from './CheckTest';

class CheckTestContainer extends React.Component{
    componentDidMount(){
        this.props.getCheckTest(1);
        this.props.getCheckTestModuls(1);
        this.props.getReturnFromTest();
    }
    
    render(){
        return (
            <div>
                <CheckTest {...this.props}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    checkTest: state.sales.checkTest,
    checkTestModuls: state.sales.checkTestModuls,
    returnFromTest: state.sales.returnFromTest,
})
export default compose( connect( mapStateToProps, {getCheckTest, getCheckTestModuls, changeDateReminder, buyModulsTest, returnModulsTest, changeCommentModulsTest, getReturnFromTest, changeNumberReturnFromTest}), withRouter )(CheckTestContainer);