import { adminAPI } from "../api/api";

const SET_SENT_INVOICE_FOR_MONTH = "SET_SENT_INVOICE_FOR_MONTH";
const SET_SUM_SENT_INVOICE_FOR_MONTH = "SET_SUM_SENT_INVOICE_FOR_MONTH";
const SET_SUM_SENT_INVOICE_FOR_YEAR = "SET_SUM_SENT_INVOICE_FOR_YEAR";
const SET_SUM_SENT_INVOICE_FOR_MONTH_WITH_DATE_MANAGER = "SET_SUM_SENT_INVOICE_FOR_MONTH_WITH_DATE_MANAGER";


let initialState = {
    sentInvoicesForMonth: [],
    sumSentInvoicesForMonth: [],
    sumSentInvoicesForYear: [],
    invoiceForMonthWithDateManager: [],
}

const shipmentReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_SENT_INVOICE_FOR_MONTH:
            return {
                ...state,
                sentInvoicesForMonth: action.sentInvoicesForMonth
            }
        case SET_SUM_SENT_INVOICE_FOR_MONTH:
            return {
                ...state,
                sumSentInvoicesForMonth: action.sumSentInvoicesForMonth
            }
        case SET_SUM_SENT_INVOICE_FOR_YEAR:
            return {
                ...state,
                sumSentInvoicesForYear: action.sumSentInvoicesForYear
            }
        case SET_SUM_SENT_INVOICE_FOR_MONTH_WITH_DATE_MANAGER:
            return {
                ...state,
                invoiceForMonthWithDateManager: action.invoiceForMonthWithDateManager
            }
        default:
            return state
    }
}

export const setSentInvoicesForMonth= (sentInvoicesForMonth) => ({type: SET_SENT_INVOICE_FOR_MONTH, sentInvoicesForMonth});
export const setSumSentInvoicesForMonth= (sumSentInvoicesForMonth) => ({type: SET_SUM_SENT_INVOICE_FOR_MONTH, sumSentInvoicesForMonth});
export const setSumSentInvoicesForYear= (sumSentInvoicesForYear) => ({type: SET_SUM_SENT_INVOICE_FOR_YEAR, sumSentInvoicesForYear});
export const setSentInvoiceForMonthWithDateManager= (invoiceForMonthWithDateManager) => ({type: SET_SUM_SENT_INVOICE_FOR_MONTH_WITH_DATE_MANAGER, invoiceForMonthWithDateManager});

export const getSentInvoicesForMonth= (month) =>{
    return (dispatch) => {
        adminAPI.getSentInvoicesForMonth(month)
        .then(data => {
            dispatch(setSentInvoicesForMonth(data));
        });
    }
}
export const getSumSentInvoicesForMonth= (month) =>{
    return (dispatch) => {
        adminAPI.getSumSentInvoicesForMonth(month)
        .then(data => {
            dispatch(setSumSentInvoicesForMonth(data));
        });
    }
}
export const getSentInvoiceForMonthWithDateManager= (month, year, dayStart=0, dayEnd=0) =>{
    return (dispatch) => {
        adminAPI.getSentInvoiceForMonthWithDateManager(month, year, dayStart, dayEnd)
        .then(data => {
            dispatch(setSentInvoiceForMonthWithDateManager(data));
        });
    }
}
export const getSumSentInvoicesForYear= () =>{
    return (dispatch) => {
        adminAPI.getSumSentInvoicesForYear()
        .then(data => {
            dispatch(setSumSentInvoicesForYear(data));
        });
    }
}
export const setNewDateManagerInScheta= (id, date, type, month, year) =>{
    return (dispatch) => {
        adminAPI.setNewDateManagerInScheta(id, date, type)
        .then(data => {
            dispatch(getSentInvoiceForMonthWithDateManager(month, year));
        });
    }
}


export default shipmentReducer;

