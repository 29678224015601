import React from 'react';
import {  withRouter, Route } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getSentInvoicesForMonth, getSumSentInvoicesForMonth, getSumSentInvoicesForYear, getSentInvoiceForMonthWithDateManager, setNewDateManagerInScheta} from '../../../../redux/shipment-reducer';
import Shipment from './Shipment';



class ShipmentContainer extends React.Component{
    componentDidMount(){
        this.props.getSentInvoiceForMonthWithDateManager(this.props.match.params.month,this.props.match.params.year)
    }
    componentDidUpdate(prevProps){
        if(prevProps.match.params.month !== this.props.match.params.month){
            this.props.getSentInvoiceForMonthWithDateManager(this.props.match.params.month, this.props.match.params.year)
        }
    }
    
    render(){
        return <Shipment invoiceForMonthWithDateManager={this.props.invoiceForMonthWithDateManager} year={this.props.match.params.year} month={this.props.match.params.month} getSentInvoiceForMonthWithDateManager={this.props.getSentInvoiceForMonthWithDateManager} setNewDateManagerInScheta={this.props.setNewDateManagerInScheta}/>
    } 
}

let mapStateToProps = (state) =>({
    invoiceForMonthWithDateManager: state.shipment.invoiceForMonthWithDateManager,
})
export default compose( connect( mapStateToProps, {getSentInvoicesForMonth, getSumSentInvoicesForMonth, getSumSentInvoicesForYear, getSentInvoiceForMonthWithDateManager, setNewDateManagerInScheta}), withRouter )(ShipmentContainer);