import React from 'react';
import {  withRouter} from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getPlan} from '../../../redux/plan-reducer';
import Plan from './Plan';

class PlanContainer extends React.Component{
    
    componentDidMount(){
        this.props.getPlan()
    }
    componentDidUpdate(prevProps){
        
    }
    
    render(){
        return (
        <div>
            <Plan />
        </div>
        )
    } 
}

let mapStateToProps = (state) =>({
})
export default compose( connect( mapStateToProps, {getPlan}), withRouter )(PlanContainer);