import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import s from '../Task.module.css';

const DeleteTask = (props) =>{
    const Delete = ()=>{
        props.setShowDeleteTask(false)
        props.deleteTask(props.tasksId, props.statusDeleteTask, props.personalId)
    }
    return(
        <div className={s.addComment}>
           <div><h3>Удаление задачи №{props.tasksId}</h3></div>
           <div className={s.center}><button onClick={()=>Delete()}>Удалить</button></div>
        </div>
    )
}
export default withRouter(DeleteTask);