import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getFiltrWants, getCompanyWithFiltrWants, setShowFieldWants, addNewFiltrBlock, getFiltrBlock, deleteFiltrBlock} from '../../../redux/sales-reducer';
import FiltrWants from './FiltrWants';
import Cookies from "js-cookie";

class FiltrWantsContainer extends React.Component{
    componentDidMount(){
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.match.params.personalId;
        if(!isAdmin){
            if(Cookies.get('id')!=currentPersonalId){
                currentPersonalId = Cookies.get('id');
            }
        }
        this.props.getFiltrBlock(currentPersonalId);
        this.props.getFiltrWants(currentPersonalId);
    }

    render(){
        return (
            <div>
                <FiltrWants filtrWants={this.props.filtrWants} getCompanyWithFiltrWants={this.props.getCompanyWithFiltrWants} companyWithFiltrWants={this.props.companyWithFiltrWants} setShowFieldWants={this.props.setShowFieldWants} addNewFiltrBlock={this.props.addNewFiltrBlock} filtrBlock={this.props.filtrBlock} deleteFiltrBlock={this.props.deleteFiltrBlock}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    filtrWants: state.sales.filtrWants,
    companyWithFiltrWants: state.sales.companyWithFiltrWants,
    filtrBlock: state.sales.filtrBlock,
})
export default compose( connect( mapStateToProps, {getFiltrWants, getCompanyWithFiltrWants, setShowFieldWants, addNewFiltrBlock, getFiltrBlock, deleteFiltrBlock}), withRouter )(FiltrWantsContainer);