import React from 'react';
import {withRouter} from 'react-router-dom';
import AddComponent from './AddComponent';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {addSectionURL, setComponentToSection, getSection} from '../../../../redux/addStorage-reducer';

class AddComponentContainer extends React.Component {
    componentDidMount() {
        this.props.addSectionURL(this.props.match.params.section);
        this.props.getSection();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.section !== this.props.match.params.section) {
            this.props.addSectionURL(this.props.match.params.section);
        }
    }

    render() {
        return (
            <div>
                <AddComponent
                    section={this.props.section}
                    sectionURL={this.props.sectionURL}
                    setComponentToSection={this.props.setComponentToSection}
                />
            </div>
        )
    }
}

let mapStateToProps = (state) => ({
    sectionURL: state.addStorage.sectionURL,
    section: state.addStorage.section
})
export default compose(connect(mapStateToProps, {
    addSectionURL,
    setComponentToSection,
    getSection
}), withRouter)(AddComponentContainer);