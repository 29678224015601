import React from 'react';
import { withRouter } from 'react-router-dom';
import s from './Admin.module.css';


const Admin = (props) =>{
    return(
        <div>
            Главная страница
        </div>
    )
}
export default withRouter(Admin);