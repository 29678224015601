import React from 'react';
import s from './Distribution.module.css';
import main from '../../Main.module.css';
import AddDistribution from './AddDistribution.jsx';
import AddNom from './AddNom.jsx';
import AddDistributionStatistics from './AddDistributionStatistics.jsx';
import ChangeDate from './ChangeDate.jsx';
import DeleteDistribution from './DeleteDistribution.jsx';
import ChangeName from './ChangeName.jsx';
import { useCookies } from 'react-cookie';
import { NavLink, withRouter } from 'react-router-dom';
import { useState } from 'react';
import Modal from '../../Production/common/Modal/Modal'
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faPlus, faTrash} from '@fortawesome/free-solid-svg-icons'

const Distribution = (props) =>{
    let typeDistribution = [
        {id:1, name: "общая подборка"}, 
        // {id:2, name: "приглашение на страницу"}, 
        {id:3, name: "персональная рассылка"}
    ]
    const getTypeName = (type)=>typeDistribution.find(k=>k.id==type).name
    let [showAddDistribution, setShowAddDistribution] = useState(false)
    let [showNom, setShowNom] = useState(false)
    let [showStatistics, setShowStatistics] = useState(false)
    let [typeStatistics, setTypeStatistics] = useState(0)
    const setShowStatisticsF = (id,type)=>{
        setShowStatistics(id)
        setTypeStatistics(type)
    }
    const setShowAddDistributionF=()=>setShowAddDistribution(!showAddDistribution)
    let [showNewDate, setShowNewDate] = useState(false)
    let [deleteDistribution, setDeleteDistribution] = useState(false)
    let [showNewName, setShowNewName] = useState(false)
    let [newName, setNewName] = useState(false)
    const setShowNewNameF = (id, name)=>{
        setNewName(name)
        setShowNewName(id)
    }
    return(
        <div className={s.distribution}>
            {(props.personalId == "44" || props.personalId == "34") && 
                <div className={s.dostup}>
                    <NavLink to={"/distribution/486"}><span className={props.match.params.personalId==486 ? s.active : ""}>Олег</span></NavLink>
                </div>
            }
            <h1>Рассылка <span onClick={()=>setShowAddDistributionF()}><FontAwesomeIcon icon={faPlus} /></span></h1>
            <div>
                
                {showAddDistribution &&
                <Modal active={showAddDistribution} setActive={setShowAddDistribution}>
                    <AddDistribution setActive={setShowAddDistribution} active={showAddDistribution} addNewDistribution={props.addNewDistribution} personalId={props.personalId} typeDistribution={typeDistribution}/>
                </Modal>
                }
                {showNom &&
                <Modal active={showNom} setActive={setShowNom}>
                    <AddNom setActive={setShowNom} active={showNom} personalId={props.personalId} addNewNom={props.addNewNom}/>
                </Modal>
                }
                {showStatistics &&
                <Modal active={showStatistics} setActive={setShowStatistics}>
                    <AddDistributionStatistics setActive={setShowStatistics} active={showStatistics} personalId={props.personalId} addNewDistributionStatistics={props.addNewDistributionStatistics} type={typeStatistics}/>
                </Modal>
                }
                {showNewDate &&
                <Modal active={showNewDate} setActive={setShowNewDate}>
                    <ChangeDate setActive={setShowNewDate} active={showNewDate} personalId={props.personalId} changeDateDistribution={props.changeDateDistribution}/>
                </Modal>
                }
                {deleteDistribution &&
                <Modal active={deleteDistribution} setActive={setDeleteDistribution}>
                    <DeleteDistribution setActive={setDeleteDistribution} active={deleteDistribution} personalId={props.personalId} deleteDistribution={props.deleteDistribution}/>
                </Modal>
                }
                {showNewName &&
                <Modal active={showNewName} setActive={setShowNewName}>
                    <ChangeName setActive={setShowNewName} active={showNewName} personalId={props.personalId} newName={newName} changeNameDistribution={props.changeNameDistribution}/>
                </Modal>
                }
                
                <div className={s.main+" "+main.bold}>
                    <div>#</div>
                    <div>Плановая дата</div>
                    <div>Название рассылки</div>
                    <div>Тип отправки</div>
                    <div className={s.mainFor}>
                        <div>Отправлено</div>
                        <div>Доставлено</div>
                        <div>Прочитано</div>
                        <div>Переходы</div>
                    </div>
                    <div>Добавить</div>
                </div>
                {props.allDistribution.map((k,index)=>{
                    return(
                        <div>
                            <div key={"allDistribution"+index} className={s.main}>
                                <div>{k.id}</div>
                                <div className={s.numberBlock}><AnyFormatDate date={k.date_plan} /> <span className={s.plusminChange} onClick={()=>setShowNewDate(k.id)}><FontAwesomeIcon icon={faPencilAlt} /></span></div>
                                <div className={s.numberBlock}>{k.name} <span className={s.plusminChange} onClick={()=>setShowNewNameF(k.id, k.name)}><FontAwesomeIcon icon={faPencilAlt} /></span></div>
                                <div>{getTypeName(k.type)}</div>
                                <div className={s.mainFor}>
                                    <div className={s.numberBlock}>{k.statistics_sent==null 
                                        ? <span className={s.plusmin} onClick={()=>setShowStatisticsF(k.id, 1)}><FontAwesomeIcon icon={faPlus} /></span> 
                                        : <>{k.statistics_sent} <span className={s.plusminChange} onClick={()=>setShowStatisticsF(k.id, 1)}><FontAwesomeIcon icon={faPencilAlt} /></span></>}
                                    </div>
                                    <div className={s.numberBlock}>{k.statistics_delivered==null 
                                    ? <span className={s.plusmin} onClick={()=>setShowStatisticsF(k.id, 2)}><FontAwesomeIcon icon={faPlus} /></span> 
                                    : <>{k.statistics_delivered} <span className={s.plusminChange} onClick={()=>setShowStatisticsF(k.id, 2)}><FontAwesomeIcon icon={faPencilAlt} /></span></>}
                                    </div>
                                    <div className={s.numberBlock}>{k.statistics_read==null 
                                    ? <span className={s.plusmin} onClick={()=>setShowStatisticsF(k.id, 3)}><FontAwesomeIcon icon={faPlus} /></span> 
                                    : <>{k.statistics_read} <span className={s.plusminChange} onClick={()=>setShowStatisticsF(k.id, 3)}><FontAwesomeIcon icon={faPencilAlt} /></span>/ {k.statistics_read_active} <span className={s.plusminChange} onClick={()=>setShowStatisticsF(k.id, 5)}><FontAwesomeIcon icon={faPencilAlt} /></span></>}
                                    </div>
                                    <div className={s.numberBlock}>{k.statistics_conversion==null 
                                    ? <span className={s.plusmin} onClick={()=>setShowStatisticsF(k.id, 4)}><FontAwesomeIcon icon={faPlus} /></span> 
                                    : <>{k.statistics_conversion} <span className={s.plusminChange} onClick={()=>setShowStatisticsF(k.id, 4)}><FontAwesomeIcon icon={faPencilAlt} /></span>/ {k.statistics_conversion_active} <span className={s.plusminChange} onClick={()=>setShowStatisticsF(k.id, 6)}><FontAwesomeIcon icon={faPencilAlt} /></span></>}
                                    </div>
                                </div>
                                <div className={s.actionsDistribution}>
                                    <span onClick={()=>setShowNom(k.id)}><FontAwesomeIcon icon={faPlus} /></span>
                                    <span onClick={()=>setDeleteDistribution(k.id)}><FontAwesomeIcon icon={faTrash} className={main.red}/></span>
                                </div>
                            </div>
                            <div className={s.mainTwo+" "+main.bold}>
                                <div>Название номенклатуры</div>
                                <div>до 3 мес</div>
                                <div>после 3 мес</div>
                                <div>до 6 мес</div>
                                <div>после 6 мес</div>
                            </div>
                            {props.allDistributionNomenclatures.map((j,indexJ)=>{
                                return(
                                    k.id==j.distribution_id &&
                                    <div key={"allDistributionNomenclatures"+indexJ} className={s.mainTwo}>
                                        <div><NavLink to={"/personalModule/"+j.nomenclatures} target="_blank">{j.name+"-"+j.nomenclatures}</NavLink></div>
                                        <div className={Number(j.collBefore3)<Number(j.collAfter3) ? "" : s.green}>{j.collBefore3==null ? 0 : j.collBefore3}</div>
                                        <div className={Number(j.collBefore3)<Number(j.collAfter3) ? s.green : ""}>{j.collAfter3==null ? 0 : j.collAfter3}</div>
                                        <div className={Number(j.collBefore6)<Number(j.collAfter6) ? "" : s.green}>{j.collBefore6==null ? 0 : j.collBefore6}</div>
                                        <div className={Number(j.collBefore6)<Number(j.collAfter6) ? s.green : ""}>{j.collAfter6==null ? 0 : j.collAfter6}</div>
                                    </div>
                                )
                                })
                            }
                        </div>
                    )
                })
                }
            </div>
        </div>
        
    )
}
export default withRouter(Distribution);