import React, { useState } from 'react';
import s from '../SalesPersonal.module.css';
import Cookies from 'js-cookie'
import { withRouter } from 'react-router-dom';

const SetPeriod = (props) =>{
    //высчитываем даты сегодня
    const getCurrentDate = () => {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        return formattedDate;
    };
    let now = getCurrentDate()
    //высчитываем даты на 14 дней раньше
    const getPastDate = (days) => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - days);
        const formattedDate = currentDate.toISOString().split('T')[0];
        return formattedDate;
    };
    let end = getPastDate(14)
    //доступы таким методом пока решаем
    let personalId;
    if(Cookies.get('id')=="44" || Cookies.get('id')=="34"){
        personalId = props.match.params.personalId
    }else{
        personalId = Cookies.get('id')
    }
    //блок выбора периода за который идёт выборка
    let [dateStart, setDateStart] = useState(end)
    const setDateStartF = (e)=> setDateStart(e.target.value)
    let [dateEnd, setDateEnd] = useState(now)
    const setDateEndF = (e)=> setDateEnd(e.target.value)
    const getAllActivityForPeriod=()=>{
        props.getAllActivity(personalId, dateStart, dateEnd, search)
    }
    //поисковая строка
    let [search, setSearch] = useState("")
    const setSearchF=(e)=>setSearch(e.target.value)
    //поиск по нажатию энтера
    let handleKeyPress = (e) =>{
        if (e.charCode === 13){ // Клавиша Enter
            props.getAllActivity(personalId, dateStart, dateEnd, search)
        }
    }
    return(
        <div className={s.searchAndPeriod}>
            <div>
                <input type="text" placeholder='Введите название компании' value={search} className={s.search} onChange={setSearchF} onKeyPress={handleKeyPress} />
            </div>
            <div className={s.period}>
                <div><input type="date" value={dateStart} onChange={setDateStartF} max={dateEnd} /></div>
                <div><input type="date" value={dateEnd} onChange={setDateEndF} min={dateStart}/></div>
                <div><button onClick={()=>getAllActivityForPeriod()}>Выбрать</button></div>
            </div>
        </div>
    )
}

export default withRouter(SetPeriod);