import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {editCompanyStatus, getKp, getSumKpForMonth, setPaidInKp, getStatusKp, changeDateReminderKp, statusWorkingKp, deleteMeeting, сloseMeeting, deleteCompanyFromPlanner} from '../../../redux/sales-reducer';
import KpMain from './KpMain';
import Cookies from "js-cookie";

class KpContainer extends React.Component{
    currentPersonalId = 0;
    componentDidMount(){
        let isAdmin = !!Cookies.get('adminID');
        this.currentPersonalId = this.props.match.params.idManager;
        if(!isAdmin){
            if(Cookies.get('id')!=this.currentPersonalId){
                this.currentPersonalId = Cookies.get('id');
            }
        }
        this.props.getKp(this.currentPersonalId);
        this.props.getStatusKp();
    }
    render(){
        return (
            <div>
                <KpMain {...this.props} personalId={this.currentPersonalId} editCompanyStatus = {this.props.editCompanyStatus}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    kp: state.sales.kp,
    statusKp: state.sales.statusKp,
})
export default compose( connect( mapStateToProps, {editCompanyStatus, getKp, getSumKpForMonth, setPaidInKp, getStatusKp, changeDateReminderKp, statusWorkingKp, deleteMeeting, сloseMeeting, deleteCompanyFromPlanner}), withRouter )(KpContainer);
