import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import s from '../Task.module.css';

const AddTask = (props) =>{
    const [cookies, setCookie] = useCookies();
    let [newWorkers, setNewWorkers] = useState(0)
    const setNewWorkersF = (e)=>{
        setNewWorkers(e.target.value)
    }
    let [textTask, setTextTask] = useState("");
    const setTextTaskF = (e)=>{
        setTextTask(e.target.value)
    }
    let [dateReminder, setDateReminder] = useState("");
    const setDateReminderF = (e)=>{
        setDateReminder(e.target.value)
    }
    const setNewTaskF=()=>{
        if(textTask!=""){
            let array = []
            //записываем id сотрудника
            let worker = props.workerList.find(k=>Number(k.login_id)==Number(cookies.id)).id
            array.push(textTask)
            array.push(newWorkers)
            array.push(worker)
            array.push(props.mainTask)
            array.push(dateReminder)
            props.setNewTask(array, cookies.id)
            setTextTask("")
            props.setShowTask(false)
        }
    }
    return(
        <div className={s.newTask}>
            {props.mainTask==0
            ? <h2>Новая задача</h2>
            : <h2>Подзадача для задачи №{props.mainTask}</h2>
            }
            <div className={s.twoBlock}>
                <div>Выбрать сотрудника: </div>
                <div>
                    <select onChange={setNewWorkersF}>
                        <option value="0">Не выбран</option>
                        {props.workerList.map(k=>{
                            return <option value={k.id} key={k.id}>{k.name}</option>
                        })}
                    </select>
                </div>
                <div className={s.mt10}>Напомнить: </div>
                <div><input type="date" className={s.input} value={dateReminder} onChange={setDateReminderF} /></div>
            </div>
            
            <div><textarea rows="2" onChange={setTextTaskF}>{textTask}</textarea></div>
            <div><button onClick={()=>setNewTaskF()}>Добавить задачу</button></div>
        </div>
    )
}
export default withRouter(AddTask);