import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const NewSchedule = (props) =>{
    let s = []; let averageSum = []; let averageCost = []; let newNums = 0;
    props.sum.map((k)=>averageSum.push(k.sum))
    props.sumCosts.map((k)=>averageCost.push(k.sum))
    console.log(averageSum)
    const getAverage=(nums)=>{
      if(nums.length<1){
        return 0
      }else{
        newNums = nums.reduce((a, b) => (a + b)) / nums.length;
        return newNums.toFixed();
      }
      
    }
    props.sum.map((k)=>{
        props.sumRu.filter(p => {
            if(p.date === k.date){
                props.sumCosts.filter(j=>{
                    if(j.date===k.date){
                        s.push({"sum": p.sum+k.sum, "date": k.date, "cost": j.sum, "averageSum": getAverage(averageSum), "averageCost": getAverage(averageCost)})
                    }
                })
                
            }
        })
    })
    const data = [{name:0, "Приход":0, "Расход":0, "Месяц":0, "Среднее":0, "Среднее Приход": getAverage(averageSum), "Среднее Расход": -getAverage(averageCost)}];
    s.map((k)=>{
        data.push({
            name: k.date,
            "Приход": k.sum,
            "Расход": -k.cost,
            "Месяц": k.date,
            "Среднее": k.sum-k.cost,
            "Среднее Приход": k.averageSum,
            "Среднее Расход": -k.averageCost,
        })
    })
    class CustomizedLabel extends PureComponent {
        render() {
          const { x, y, stroke, value } = this.props;
      
          return (
            <text x={x} y={y} dy={-2} fill={stroke} fontSize={15} fontWeight={"bold"} textAnchor="middle" backgroundColor="#eee">
              {value.toLocaleString()}
            </text>
          );
        }
      }
      
      class CustomizedAxisTick extends PureComponent {
        render() {
          const { x, y, stroke, payload } = this.props;
      
          return (
            <g transform={`translate(${x},${y})`}>
              <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                {payload.value}
              </text>
            </g>
          );
        }
      }
      console.log(data)
    return(
        <div>
            <h2>Таблица приходов/расходов</h2>
           <ResponsiveContainer width="95%" height={1050}>
              <LineChart
                width={700}
                height={1050}
                data={data}
                margin={{
                  top: 40,
                  right: 60,
                  left: 40,
                  bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray="5 5" />
                {/* <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} /> */}
                <YAxis domain={getAverage(averageCost)}/>
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Приход" stroke="#ffc658" label={<CustomizedLabel />}  isAnimationActive={false} />
                <Line type="monotone" dataKey="Среднее" stroke="#fe5000" label={<CustomizedLabel />} strokeDasharray="5 5" isAnimationActive={false} />
                <Line type="monotone" dataKey="Месяц" stroke="#888" label={<CustomizedLabel />} isAnimationActive={false} />
                <Line type="monotone" dataKey="Расход" stroke="#8884d8" label={<CustomizedLabel />} isAnimationActive={false} />
                <Line type="monotone" dataKey="Среднее Приход" stroke="#000"  strokeDasharray="5 5" isAnimationActive={false} />
                <Line type="monotone" dataKey="Среднее Расход" stroke="#000"  strokeDasharray="5 5" isAnimationActive={false} />
              </LineChart>
            </ResponsiveContainer>
        </div>
    )
}
export default withRouter(NewSchedule);