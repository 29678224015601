import React, {useEffect, useState} from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Cookie from 'js-cookie';
import {Storage, Calculate, Assignment} from '@mui/icons-material';
import {managersList} from '../global/ManagersList';
import {adminAPI, loginAPI, privateOfficeAPI} from "../../api/api";
import {toast} from "react-toastify";
import {
    Grid,
    TextField,
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tabs,
    Tab,
    LinearProgress
} from "@mui/material";
import {styled} from '@mui/system';
import {PieChart, Pie, Cell, Tooltip, Legend} from 'recharts';

const AdminSideMenu = (props) => {
    const [openIdDialog, setOpenIdDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [openCostCalculator, setOpenCostCalculator] = useState(false);
    const [nomenclatureNum, setNomenclatureNum] = useState(0);
    const [nomenclatureQty, setNomenclatureQty] = useState(0);
    const [selfCostData, setSelfCostData] = useState({});
    const [tabValue, setTabValue] = useState(0);

    console.log(props)
    const handleIdChange = (event) => {
        setSelectedId(event.target.value);
    };

    const handleIdSubmit = () => {
        Cookie.set('id', selectedId);
        setOpenIdDialog(false);
        window.location.reload();
    };

    const calculateSelfCost = async () => {
        try {
            const response = await adminAPI.getSelfcostByNumFastPanel({
                nomenclatureNum: nomenclatureNum,
                qty: nomenclatureQty,
            });
            toast.info('Успешная загрузка данных');
            if (response.status === 1) {
                setSelfCostData(response.data);
                // Check for any items with absent self-cost
                const missingComponents = {};
                response.data.calculations.forEach((module) => {
                    module.details.forEach((detail) => {
                        if (detail.total_price === 0) {
                            if (missingComponents[detail.code]) {
                                missingComponents[detail.code] += 1;
                            } else {
                                missingComponents[detail.code] = 1;
                            }
                        }
                    });
                });
                const missingComponentsList = Object.entries(missingComponents)
                    .map(([code, count]) => `${code} (${count}pcs)`)
                    .join(', ');
                if (missingComponentsList.length > 0) {
                    toast.warning(`Внимание: Себестоимость элементов отсутствует для следующих кодов: ${missingComponentsList}`, {
                        autoClose: false,
                        sticky: true,
                    });
                }
            } else {
                setSelfCostData({});
                toast.error('Ошибка:', response.message);
            }
        } catch (error) {
            setSelfCostData({});
            toast.error('Ошибка:', error);
        }
    };

    const [sideMenuList, setSideMenuList] = useState([]);
    const getSideMenuList = async () => {
        try {
            const response = await loginAPI.getSideMenuList();

            if (response?.status === 1) {
                setSideMenuList(response?.data);
            } else {
                toast.error('Ошибка при получении списка меню: ' + response?.message);
            }

        } catch (error) {
            console.error('Возникла ошибка:', error);
            toast.error('Ошибка при получении списка меню.');
        }
    }

    useEffect(() => {
        getSideMenuList();
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            calculateSelfCost();
        }
    };

    const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
        height: 10,
        borderRadius: 5,
        backgroundColor: theme.palette.grey ? theme.palette.grey[300] : '#e0e0e0',
        '& .MuiLinearProgress-bar': {
            borderRadius: 5,
            backgroundColor: 'green', // You can change the color according to the segment
            opacity: 1,
        },
    }));

    // Prepare data for the pie chart
    const pieData = selfCostData.calculations ? selfCostData.calculations.reduce((acc, module) => {
        // Exclude modules that are not fully assembled
        if (!module.details.some(detail => detail.deficit > 0)) {
            const cost = parseFloat(module.total_cost.toFixed(2));
            const existingEntry = acc.find(entry => entry.name === `${cost} USD`);
            if (existingEntry) {
                existingEntry.value += 1;
            } else {
                acc.push({name: `${cost} USD`, value: 1});
            }
        }
        return acc;
    }, []) : [];

    // Sort pieData by cost
    pieData.sort((a, b) => parseFloat(a.name) - parseFloat(b.name));

    // Define colors for pie chart based on sorted costs
    const pieColors = pieData.map((entry, index) => {
        if (index === 0) return 'green';
        if (index === pieData.length - 1) return 'red';
        return '#8884d8';
    });
    const getCssClassFromIconName = (iconName) => {
        // Видаляємо префікс 'fa' з назви
        let name = iconName.replace(/^fa/, '');

        // Перетворюємо першу літеру на малу
        name = name.charAt(0).toLowerCase() + name.slice(1);

        // Розділяємо великі літери та вставляємо дефіс між ними
        name = name.replace(/([A-Z])/g, '-$1').toLowerCase();

        // Додаємо префікси 'fas' та 'fa-' для отримання повного класу
        return `fas fa-${name}`;
    };
    let userID = Cookie.get('id');

    return (
        <div>
            <SpeedDial
                ariaLabel="Быстрый доступ"
                sx={{position: 'fixed', bottom: 16, right: 16}}
                style={{
                    opacity: 0.5,
                    transition: 'opacity 0.3s'
                }}
                onMouseOver={e => e.currentTarget.style.opacity = '1'}
                onMouseOut={e => e.currentTarget.style.opacity = '0.5'}
                icon={<SpeedDialIcon/>}
            >
                {props.isAdmin &&
                    <SpeedDialAction
                        icon={<IconButton onClick={() => setOpenIdDialog(true)}><Assignment/></IconButton>}
                        tooltipTitle="Изменить ID"
                    />
                }

                {props.isAdmin &&
                    <SpeedDialAction
                        icon={
                            <a href="https://adm.tools/hosting/account/267029/mysql/databases/" target="_blank"
                               rel="noopener noreferrer">
                                <IconButton>
                                    <Storage/>
                                </IconButton>
                            </a>
                        }
                        tooltipTitle="phpmyadmin"
                    />
                }
                {props.isAdmin &&
                    <SpeedDialAction
                        icon={<IconButton onClick={() => setOpenCostCalculator(true)}><Calculate/></IconButton>}
                        tooltipTitle="Себестоимость"
                    />
                }

                {sideMenuList.map((item, index) => (
                    <SpeedDialAction
                        key={index}
                        icon={
                            <IconButton
                                onClick={() => window.open(
                                    item?.link.startsWith('https')
                                        ? (item?.link + (item?.personalLink ? '/' + userID : ''))
                                        : '/' + item?.link + (item?.personalLink ? '/' + userID : ''))}>
                                <i className={getCssClassFromIconName(item.fa_icon_name)}/>
                            </IconButton>
                        }
                        tooltipTitle={item?.name}
                    />
                ))}


            </SpeedDial>

            <Dialog open={openIdDialog} onClose={() => setOpenIdDialog(false)}>
                <DialogTitle>Изменить ID</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <Select value={selectedId} onChange={handleIdChange} displayEmpty>
                            <MenuItem value="" disabled>Выберите менеджера</MenuItem>
                            {managersList.map(manager => (
                                <MenuItem key={manager.id} value={manager.id}>
                                    {manager.name} ({manager.id})
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenIdDialog(false)}>Отмена</Button>
                    <Button onClick={handleIdSubmit} color="primary">Сохранить</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openCostCalculator}
                onClose={() => setOpenCostCalculator(false)}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>Себестоимость</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{'& .MuiTextField-root': {m: 1, width: '25ch'}}} noValidate
                         autoComplete="off" onKeyPress={handleKeyPress}>
                        <TextField
                            label='Номенклатурный номер'
                            type='number'
                            value={nomenclatureNum}
                            onChange={(e) => setNomenclatureNum(e.target.value)}
                        />
                        <TextField
                            label='Количество'
                            type='number'
                            value={nomenclatureQty}
                            onChange={(e) => setNomenclatureQty(e.target.value)}
                        />
                        <Button onClick={calculateSelfCost}>Рассчитать</Button>
                    </Box>
                    {selfCostData && selfCostData.calculations && (
                        <Box sx={{width: '100%'}}>
                            <Tabs value={tabValue} onChange={handleTabChange}>
                                <Tab label="Главная"/>
                                <Tab label="Спецификации"/>
                                <Tab label="Компоненты"/>
                                {selfCostData.total_deficit && Object.keys(selfCostData.total_deficit).length > 0 && (
                                    <Tab label="Дефицит" style={{color: 'red'}}/>
                                )}
                            </Tabs>
                            {tabValue === 0 && (
                                <Box p={3}>
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                variant="h6">Себестоимость: {selfCostData.total_cost}</Typography>
                                            <Box mt={2}>
                                                <Typography variant="body1">Распределение себестоимости:</Typography>
                                                <Box mt={2}>
                                                    <Box display="flex" alignItems="center" mt={1}>
                                                        <Box width={10} height={10} bgcolor="green" mr={1}></Box>
                                                        <Typography
                                                            variant="body2">Min: {selfCostData.min_self_cost}</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" mt={1}>
                                                        <Box width={10} height={10} bgcolor="orange" mr={1}></Box>
                                                        <Typography
                                                            variant="body2">Avg: {selfCostData.avg_self_cost}</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" mt={1}>
                                                        <Box width={10} height={10} bgcolor="red" mr={1}></Box>
                                                        <Typography
                                                            variant="body2">Max: {selfCostData.top_self_cost}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box mt={2}>
                                                {selfCostData.assembled_modules_percentage &&
                                                    <>
                                                        <Typography variant="body1">Процент собранных
                                                            модулей: {selfCostData.assembled_modules_percentage}%</Typography>
                                                    </>
                                                }
                                                <Typography variant="body1">Не удалось собрать
                                                    модулей: {selfCostData.cannot_assemble_count}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box mt={3}>
                                                <PieChart width={400} height={400}>
                                                    <Pie
                                                        data={pieData}
                                                        cx="50%"
                                                        cy="50%"
                                                        labelLine={false}
                                                        outerRadius={120}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                        label
                                                    >
                                                        {pieData.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={pieColors[index]}/>
                                                        ))}
                                                    </Pie>
                                                    <Tooltip/>
                                                    <Legend/>
                                                </PieChart>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}

                            {tabValue === 1 && (
                                <Box p={3}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Модуль</TableCell>
                                                    <TableCell>Общая стоимость</TableCell>
                                                    <TableCell>Элементы</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {selfCostData.calculations.map((module, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{module.module_index}</TableCell>
                                                        <TableCell>{module.total_cost}</TableCell>
                                                        <TableCell>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Код</TableCell>
                                                                        <TableCell>Количество на единицу</TableCell>
                                                                        <TableCell>Дефицит</TableCell>
                                                                        <TableCell>Суммарная цена</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {module.details.map((detail, idx) => (
                                                                        <TableRow key={idx}>
                                                                            <TableCell>{detail.code}</TableCell>
                                                                            <TableCell>{detail.qty_per_pc}</TableCell>
                                                                            <TableCell>{detail.deficit}</TableCell>
                                                                            <TableCell>{detail.total_price}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            )}
                            {tabValue === 2 && (
                                <Box p={3}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Код</TableCell>
                                                    <TableCell>Суммарная цена</TableCell>
                                                    <TableCell>Минимальная цена</TableCell>
                                                    <TableCell>Максимальная цена</TableCell>
                                                    <TableCell>Средняя цена</TableCell>
                                                    <TableCell>% Изменения себестоимости</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.keys(selfCostData.self_cost_change_dynamics).map((code, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{code}</TableCell>
                                                        <TableCell>{selfCostData.self_cost_change_dynamics[code].total_price}</TableCell>
                                                        <TableCell>{selfCostData.self_cost_change_dynamics[code].min_price}</TableCell>
                                                        <TableCell>{selfCostData.self_cost_change_dynamics[code].max_price}</TableCell>
                                                        <TableCell>{selfCostData.self_cost_change_dynamics[code].avg_price}</TableCell>
                                                        <TableCell>{selfCostData.self_cost_change_dynamics[code].price_change_percentage}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            )}
                            {tabValue === 3 && selfCostData.total_deficit && Object.keys(selfCostData.total_deficit).length > 0 && (
                                <Box p={3}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Код</TableCell>
                                                    <TableCell>Дефицит</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.keys(selfCostData.total_deficit).map((code, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{code}</TableCell>
                                                        <TableCell>{selfCostData.total_deficit[code]}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            )}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setNomenclatureNum(0);
                        setNomenclatureQty(0);
                        setOpenCostCalculator(false);
                    }}>Закрыть</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AdminSideMenu;
