import React from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {
    getALLNomenclatures,
    setNewSpecification,
    getSpecificationsElements,
    changeSpecificationProps,
    getVersionSpecifications,
} from '../../../redux/specification-reducer';
import {getAllComponentsFromStorage} from '../../../redux/addStorage-reducer';
import AddNewSpecification from './AddNewSpecification';

class AddNewSpecificationContainer extends React.Component {
    componentDidMount() {
        this.props.getAllComponentsFromStorage();
        this.props.getALLNomenclatures();
    }

    render() {
        return (
            <div>
                <AddNewSpecification
                    allComponentsFromStorage={this.props.allComponentsFromStorage}
                    allNomenclatures={this.props.allNomenclatures}
                    setNewSpecification={this.props.setNewSpecification}
                    flag={this.props.flag}
                    getSpecificationsElements={this.props.getSpecificationsElements}
                    changeSpecificationProps={this.props.changeSpecificationProps}
                    specificationElements={this.props.specificationElements}
                    getVersionSpecifications={this.props.getVersionSpecifications}
                    versionSpecification={this.props.versionSpecification}
                    setNewVersionSpecification={this.props.setNewVersionSpecification}
                    getALLNomenclatures={this.props.getALLNomenclatures}
                />
            </div>
        )
    }
}

let mapStateToProps = (state) => ({
    allNomenclatures: state.specification.allNomenclatures,
    allComponentsFromStorage: state.addStorage.allComponentsFromStorage,
    flag: state.specification.flag,
    specificationElements: state.specification.specificationElements,
    versionSpecification: state.specification.versionSpecification,
})
export default compose(connect(mapStateToProps, {
    getAllComponentsFromStorage,
    getALLNomenclatures,
    setNewSpecification,
    getSpecificationsElements,
    changeSpecificationProps,
    getVersionSpecifications,
}), withRouter)(AddNewSpecificationContainer);