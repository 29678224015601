import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getSumAndNameSubetriesForMonth, getSumPaidCostsForMonth, getAllPaidCostsForMonthFromSubetries, getSumAndNameArticlesForMonth} from '../../../redux/admin-reducer'
import Costs from './Costs';

class CostsContainer extends React.Component{
    componentDidMount(){
        this.props.getSumAndNameArticlesForMonth(this.props.month, this.props.year);
        this.props.getSumPaidCostsForMonth(this.props.month, this.props.year);
    }
    componentDidUpdate(prevProps){
        if(prevProps.month !== this.props.month){
            // this.props.getSumAndNameSubetriesForMonth(this.props.month);
            this.props.getSumAndNameArticlesForMonth(this.props.month, this.props.year);
            this.props.getSumPaidCostsForMonth(this.props.month, this.props.year);
        }
        if(prevProps.year !== this.props.year){
            // this.props.getSumAndNameSubetriesForMonth(this.props.month);
            this.props.getSumAndNameArticlesForMonth(this.props.month, this.props.year);
            this.props.getSumPaidCostsForMonth(this.props.month, this.props.year);
        }
    }
    
    render(){
        return (
            <div>
                <Costs checkCosts={this.props.checkCosts} sumCosts={this.props.sumCosts} subentries={this.props.subentries} getAllPaidCostsForMonthFromSubetries={this.props.getAllPaidCostsForMonthFromSubetries} month={this.props.month} articles={this.props.articles} getSumAndNameSubetriesForMonth={this.props.getSumAndNameSubetriesForMonth} year={this.props.year}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    checkCosts: state.admin.checkCosts,
    sumCosts: state.admin.sumCosts,
    subentries: state.admin.subentries,
    articles: state.admin.articles
})
export default compose( connect( mapStateToProps, {getSumAndNameSubetriesForMonth, getSumPaidCostsForMonth, getAllPaidCostsForMonthFromSubetries, getSumAndNameArticlesForMonth}), withRouter )(CostsContainer);