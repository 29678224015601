import React,{useState} from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import s from './ShipmentStatistics.module.css';


const ShipmentStatistics = (props) =>{
    let mainColl = props.moduleSalesStatistics.reduce(function(p,c){return Number(p)+Number(c.sum_coll);},'');
    let coll = 0;
    let collNo = 0;
    let nomCollArray = [];
    props.moduleSalesStatistics.map((k)=>{
        let a = props.nomenclatures.some(item => item == k.nomenclatures_id)//проверяем наличие в массиве объектов
        if(a){
            coll = Number(coll)+Number(k.sum_coll)
            //формируем массив только из посчитанных номенклатур
            nomCollArray.push(k);
        }else{
            collNo = Number(collNo)+Number(k.sum_coll)
        }
    })
    //работа с массивами
    let componentNomenclatures = [];
    props.allComponentFromNomenclatures.map(k=>{
        let a = nomCollArray.some(i=>i.nomenclatures_id==k.nomenclatures)
        if(a){
            componentNomenclatures.push(k);
        }
    })
    //умножаем каждый компонент на количество модулей из первого массива
    nomCollArray.map(k=>{
        componentNomenclatures.some(i=>{
            if(k.nomenclatures_id == i.nomenclatures){
                i.coll = Number(i.coll)*Number(k.sum_coll)
            }
        })
    })
    //плюсуем все одинаковые позиции во втором массиве
    let componentMain = [];
    componentNomenclatures.map(k=>{
        let index = componentMain.find(i=>Number(i.storage_component_id) == Number(k.storage_component_id))
        if(!index){
            let a = componentNomenclatures.filter(j=>Number(j.storage_component_id)===Number(k.storage_component_id))
            let c = a.reduce((a, b) => Number(a) + Number(b.coll), 0)
            if(isNaN(c)) c=0
            componentMain.push({storage_component_id:k.storage_component_id, coll: c})
        }
    })
    //добавляем цену за каждый компонент
    let componentMainPrice = [];
    componentMain.map(k=>{
        props.storageAndAveragePrice.map(j=>{
            if(Number(j.id)==Number(k.storage_component_id)){
                componentMainPrice.push({storage_component_id:k.storage_component_id, coll: k.coll, name: j.specification_id, sum:Math.round(Number(k.coll)*Number(j.price))})
            }
        })
    })
   //сортируем по цене компонентов
    componentMainPrice = componentMainPrice.sort((a,b)=>Number(b.sum)-Number(a.sum))
    let mainSum = componentMainPrice.reduce(function(p,c){return Number(p)+Number(c.sum);},'');

    const getAllComponentsFromStorage=(idComponent)=>{
        return props.allComponentsFromStorage.map(k=>{
            if(k.id==idComponent) return k.coll
        })
    }
    // console.log(nomCollArray)
    // console.log(componentNomenclatures)
    // console.log(props.storageAndAveragePrice)
    // console.log(componentMain)
    return(
        <div>
            <div className={s.mainYear}>
                <NavLink to="/admin/shipmentStatistics/0"><div>Все</div></NavLink>
                <NavLink to="/admin/shipmentStatistics/2020"><div>2020</div></NavLink>
                <NavLink to="/admin/shipmentStatistics/2021"><div>2021</div></NavLink>
                <NavLink to="/admin/shipmentStatistics/2022"><div>2022</div></NavLink>
            </div>
            <div>Всего модулей {mainColl}</div>
            <div>Модулей посчитано {coll} - не посчитано {collNo}</div>
            <div>всего сумма {mainSum.toLocaleString()}</div>
            <div className={s.main+" "+s.weight}>
                <div>id компонента</div>
                <div>Название компонента</div>
                <div>Потрачено шт. за год</div>
                <div>Остатки шт.</div>
                <div>Цена грн без НДС потрачено за год</div>
            </div>
            {componentMainPrice.map((k, index)=>{
                return(
                    <div key={"moduleSalesStatistics_"+index} className={s.main}>
                        <div>{k.storage_component_id}</div>
                        <div><NavLink to={"/suppliers/"+k.storage_component_id} target="_blank">{k.name}</NavLink></div>
                        <div>{k.coll}</div>
                        <div>{getAllComponentsFromStorage(k.storage_component_id)}</div>
                        <div>{k.sum.toLocaleString()}</div>
                    </div>
                )
            })}
        </div>
    )
}
export default withRouter(ShipmentStatistics);