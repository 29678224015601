import React from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {getRelatedPaymentForYearMonth} from '../../../redux/sales-reducer';
import Cookies from 'js-cookie'
import ManagerSalary from "./ManagerSalary";

function updateDataRequest(year, month, idManager) {
    let personalId = Cookies.get('id');
    if (Cookies.get('id') == "44" || Cookies.get('id') == "34") {
        personalId = idManager
    }
    let request = {
        'managerID': personalId,
        'controlID': Cookies.get('id'),
        'year': year,
        'month': month,
    }

    return request;

}

class ManagerSalaryContainer extends React.Component {
    componentDidMount() {
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.match.params.idManager;
        if(!isAdmin){
            if(Cookies.get('id')!=currentPersonalId){
                currentPersonalId = Cookies.get('id');
            }
        }
        this.props.getRelatedPaymentForYearMonth(updateDataRequest(this.props.match.params.year, this.props.match.params.month, currentPersonalId))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.year !== prevProps.match.params.year ||
            this.props.match.params.month !== prevProps.match.params.month ||
            this.props.match.params.idManager !== prevProps.match.params.idManager) {
            let isAdmin = !!Cookies.get('adminID');
            let currentPersonalId = this.props.match.params.idManager;
            if(!isAdmin){
                if(Cookies.get('id')!=currentPersonalId){
                    currentPersonalId = Cookies.get('id');
                }
            }
            this.props.getRelatedPaymentForYearMonth(updateDataRequest(this.props.match.params.year, this.props.match.params.month, currentPersonalId))
        }
    }

    render() {
        if (this.props.relatedPaymentForYearMonth.length === 0) return (<div>Загрузка...</div>)
        return (
            <div>
                <ManagerSalary
                    {...this.props}/>
            </div>
        )
    }
}

let mapStateToProps = (state) => ({
    relatedPaymentForYearMonth: state.sales.relatedPaymentForYearMonth,
})
export default compose(connect(mapStateToProps, {
    getRelatedPaymentForYearMonth,
}), withRouter)(ManagerSalaryContainer);
