import React, { useState } from 'react';
import s from './List.module.css';

let List = (props) =>{
    let [listState, setlistState] = useState("-");
    let changelistState = () =>{
        (listState === "-") ? setlistState("+") : setlistState("-")
    }
    let saveComponentsColl = () =>{
        let mainList = [];
        let saveNewComponent = true;
        let coll = 0;
        props.list.map( g =>{
            if(props.addOrTakeProducts == 1){
                g.coll = Number(g.coll) + Number(g.value)
                mainList.push({ id: g.id, coll: g.coll});
            }else{
                coll = g.coll - g.value
                if(coll < 0){
                    saveNewComponent = false;
                }else{
                    g.coll = coll;
                    mainList.push({ id: g.id, coll: g.coll});
                }
            }
            
        })
        if(saveNewComponent){
            props.changeCollComponent(JSON.stringify(mainList), props.sectionURL, props.personalId, props.addOrTakeProducts);
            props.setList([]);
            props.setCollComponents(0);
        }else{
            alert("Значение не может быть меньше 0")
        }
    }
    return (
        <>
        {
        (props.list.length !== 0 && listState !== "+")
        ?    <div className={s.main}>
                <div className={s.list_h3}>{(props.addOrTakeProducts == 1) ? "Добавление" : "Списание"}<div className={s.listState} onClick={changelistState}>{listState}</div></div>
                <div className={s.line}>
                    {
                        props.list.map( p => {
                            return (
                                <>
                                <div className={s.name}>{p.name}</div>
                                <div className={s.coll}>
                                {
                                    (props.addOrTakeProducts == 1) 
                                    ? Number(p.coll) + Number(p.value)
                                    : p.coll - p.value
                                }</div>
                                </>
                            )
                        })
                    }
                </div>
                <div className={s.saveList}><button onClick={saveComponentsColl}>Сохранить</button></div>
            </div>
        : <div className={s.main}>
            <div className={s.list_h3}>{(props.addOrTakeProducts == 1) ? "Добавление" : "Списание"}<div className={s.listState} onClick={changelistState}>{listState}</div></div>
            </div>
        }
        </>
    )
}
export default List;