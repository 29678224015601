import { productionAPI } from "../api/api";
import {toast} from "react-toastify";

const SET_MODULS_PAID = "SET_MODULS_PAID";

let initialState = {
    modulsPaid:[]
}

const modulsPaidReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_MODULS_PAID:
            return {
                ...state,
                modulsPaid: action.modulsPaid
            }
        default:
            return state
    }
}

export const setModulsPaid = (modulsPaid) => ({type: SET_MODULS_PAID, modulsPaid});

export const getModulsPaidAllocated= () =>{
    return (dispatch) => {
        productionAPI.getModulsPaidAllocated()
        .then(data => {
            dispatch(setModulsPaid(data));
        });
    }
}
export const setWhoMake= (modulStatus) =>{
    return (dispatch) => {
        productionAPI.setWhoMake(modulStatus)
        .then(data => {
            dispatch(getModulsPaidAllocated());
        });
    }
}
export const setWhoMakeWithSpecs= (modulStatus) =>{
    return (dispatch) => {
        productionAPI.setWhoMakeWithSpecs(modulStatus)
        .then(data => {
            if(data.status == 1){
                if(data?.additional_message?.length > 0){
                    toast.warning(data.additional_message);
                }
                toast.success(data.message);
                dispatch(getModulsPaidAllocated());
            }else{
                toast.error(data.message);
            }
        });
    }
}
export const setWhoMakeWithoutSpecs= (request) =>{
    return (dispatch) => {
        productionAPI.setWhoMakeWithoutSpecs(request)
        .then(data => {
            if(data.status == 1){
                if(data?.additional_message?.length > 0){
                    toast.warning(data.additional_message);
                }
                toast.success(data.message);
                dispatch(getModulsPaidAllocated());
            }else{
                toast.error(data.message);
            }
        });
    }
}

export const setSeparatorInBase = (id, number1, number2, modulsTypeInProduction) =>{
    return (dispatch) => {
        productionAPI.setSeparatorInBase(id, number1, number2, modulsTypeInProduction)
        .then(data => {
            dispatch(getModulsPaidAllocated());
        });
    }
}
export const setModulsForAkon= (modulsName, modulsColl, nomenclaturesColl) =>{
    return (dispatch) => {
        productionAPI.setModulsForAkon(modulsName, modulsColl, nomenclaturesColl)
        .then(data => {
            dispatch(getModulsPaidAllocated());
        });
    }
}
export const setOtherProducts= (id) =>{
    return (dispatch) => {
        productionAPI.setOtherProducts(id)
        .then(data => {
            dispatch(getModulsPaidAllocated());
        });
    }
}

export default modulsPaidReducer;

