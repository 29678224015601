import React from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {getModuleSalesStatistics} from '../../../redux/statistics-reducer';
import StatisticsModelModules from './StatisticsModelModules';

class StatisticsModelModulesContainer extends React.Component {
    componentDidMount() {
        this.props.getModuleSalesStatistics();

    }

    componentDidUpdate(prevProps) {
        // if(prevProps.moduleSalesStatistics !== this.props.moduleSalesStatistics){
        //     this.props.getModuleSalesStatistics();
        // }
    }

    render() {
        return (
            <div>
                <StatisticsModelModules moduleSalesStatistics={this.props.moduleSalesStatistics}
                                        getModuleSalesStatistics={this.props.getModuleSalesStatistics}/>
            </div>
        )
    }
}

let mapStateToProps = (state) => ({
    moduleSalesStatistics: state.statistics.moduleSalesStatistics
})
export default compose(connect(mapStateToProps, {getModuleSalesStatistics}), withRouter)(StatisticsModelModulesContainer);