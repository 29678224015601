import React from 'react';
import { withRouter } from 'react-router-dom';
// import s from './Plan.module.css';


const Plan = (props) =>{
    return(
        <div>
            план
        </div>
    )
}
export default withRouter(Plan);