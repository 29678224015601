import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getAllPaidCheckForMonth, getSumAllPaidCheckForMonth, getSumAllPaidCheckForMonthRu} from '../../../redux/admin-reducer'
import Income from './Income';

class IncomeContainer extends React.Component{
    componentDidMount(){
        this.props.getAllPaidCheckForMonth(this.props.month, this.props.year);
        this.props.getSumAllPaidCheckForMonth(this.props.month, this.props.year);
        this.props.getSumAllPaidCheckForMonthRu(this.props.month, this.props.year);
    }
    componentDidUpdate(prevProps){
        if(prevProps.month !== this.props.month){
            this.props.getAllPaidCheckForMonth(this.props.month, this.props.year);
            this.props.getSumAllPaidCheckForMonth(this.props.month, this.props.year);
            this.props.getSumAllPaidCheckForMonthRu(this.props.month, this.props.year);
        }
        if(prevProps.year !== this.props.year){
            this.props.getAllPaidCheckForMonth(this.props.month, this.props.year);
            this.props.getSumAllPaidCheckForMonth(this.props.month, this.props.year);
            this.props.getSumAllPaidCheckForMonthRu(this.props.month, this.props.year);
        }
    }
    
    render(){
        return (
            <div>
                <Income check={this.props.check} sum={this.props.sum} incomeList={this.props.incomeList} month={this.props.month} sumRu={this.props.sumRu}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    check: state.admin.check,
    sum: state.admin.sum,
    sumRu: state.admin.sumRu
})
export default compose( connect( mapStateToProps, {getAllPaidCheckForMonth, getSumAllPaidCheckForMonth, getSumAllPaidCheckForMonthRu}), withRouter )(IncomeContainer);