import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getAllActivity} from '../../../redux/salesPersonal-reducer';
import SalesPersonalStatistics from './SalesPersonalStatistics';
import Cookies from 'js-cookie'

class SalesPersonalStatisticsContainer extends React.Component{
    componentDidMount(){
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.match.params.personalId;
        if(!isAdmin){
            if(Cookies.get('id')!=currentPersonalId){
                currentPersonalId = Cookies.get('id');
            }
        }
        this.props.getAllActivity(currentPersonalId, "2023-01-01")
    }
    componentDidUpdate(prevProps){
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.match.params.personalId;
        if(!isAdmin){
            if(Cookies.get('id')!=currentPersonalId){
                currentPersonalId = Cookies.get('id');
            }
        }
        if(prevProps.personalId !== this.props.personalId){

            this.props.getAllActivity(currentPersonalId)
        }
    }
    render(){
        return (
            <div>
                <SalesPersonalStatistics {...this.props} personalId={this.props.match.params.personalId}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    allActivity: state.salesPersonal.allActivity,
})
export default compose( connect( mapStateToProps, {getAllActivity}), withRouter )(SalesPersonalStatisticsContainer);