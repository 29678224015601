import React from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {getAccessoriesList} from '../../../../redux/accessories-reducer';
import AccessoriesList from './AccessoriesList';

class AccessoriesListContainer extends React.Component {
    componentDidMount() {
        this.props.getAccessoriesList();
    }

    render() {
        return (
            <div>
                <AccessoriesList {...this.props}/>
            </div>
        )
    }
}

let mapStateToProps = (state) => ({
    accessoriesList: state.accessories.accessoriesList,
})
export default compose(connect(mapStateToProps, {
    getAccessoriesList,
}), withRouter)(AccessoriesListContainer);
