import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { companyAPI, searchAPI } from "../../../api/api";
import { toast } from "react-toastify";
import { Card, CardContent, Chip, Grid, FormControl, InputLabel, MenuItem, Select, Switch, FormControlLabel } from "@mui/material";

const SearchEquipment = () => {
    const [openSearchWindow, setOpenSearchWindow] = React.useState(false);
    const [searchRequest, setSearchRequest] = React.useState('');
    const [searchResults, setSearchResults] = React.useState([]);
    const [nonStrictSearch, setNonStrictSearch] = React.useState(true);
    const [selectedCategories, setSelectedCategories] = React.useState([]);
    const [industriesList, setIndustriesList] = React.useState([]);

    // Відкриття діалогу пошуку
    const handleClickOpenSearchWindow = () => {
        setOpenSearchWindow(true);
        getIndustriesList(); // Завантажуємо список індустрій при відкритті
    };

    // Закриття діалогу і очищення всіх даних
    const handleClose = () => {
        setOpenSearchWindow(false);
        setSearchRequest('');  // Очищення пошукового запиту
        setSearchResults([]);   // Очищення результатів пошуку
        setSelectedCategories([]);  // Очищення вибраних категорій
        setNonStrictSearch(true);   // Відновлення початкового значення для Switch
    };

    // Зміна пошукового запиту
    const handleSearchChange = (value) => {
        setSearchRequest(value);

        // Виконувати пошук тільки якщо більше 2 символів або вибрані категорії
        if (value.length > 2 || selectedCategories.length > 0) {
            getSearchResults({ search: value, categories: selectedCategories.map(category => category.id) });
        } else if (value.length === 0 && selectedCategories.length === 0) {
            setSearchResults([]); // Очищення результатів, якщо немає категорій і пошукового запиту
        }
    };

    // Отримання результатів пошуку
    const getSearchResults = async (search) => {
        try {
            const response = await searchAPI.getUseCases(search);
            if (response.status === 1) {
                setSearchResults(response.data);
            } else {
                console.error(response.message);
                toast.error('Ошибка: ' + response.message);
            }
        } catch (error) {
            console.error(error.message);
            toast.error('Ошибка: ' + error.message);
        }
    };

    // Отримання списку індустрій
    const getIndustriesList = async () => {
        try {
            const response = await companyAPI.getIndustriesList();
            if (response.status === 1) {
                setIndustriesList(response.data);
            } else {
                console.error('Error fetching industries list:', response.message);
            }
        } catch (error) {
            console.error('Error fetching industries list:', error.message);
        }
    };

    // Зміна вибраних категорій
    const handleCategoryChange = (event) => {
        const selected = event.target.value;
        setSelectedCategories(selected);

        // Виконувати пошук тільки якщо більше 2 символів або є вибрані категорії
        if (searchRequest.length > 2 || selected.length > 0) {
            getSearchResults({ search: searchRequest, categories: selected.map(category => category.id) });
        } else if (selected.length === 0 && searchRequest.length === 0) {
            setSearchResults([]); // Очищення результатів, якщо немає категорій і пошукового запиту
        }
    };

    // Зміна перемикача несторогого пошуку
    const handleSwitchChange = (event) => {
        setNonStrictSearch(event.target.checked);
    };

    return (
        <>
            <NavLink to='#' onClick={handleClickOpenSearchWindow}>
                <div><FontAwesomeIcon icon={faSearch} /> Референс</div>
            </NavLink>
            <Dialog
                fullWidth={true}
                maxWidth={'xl'}
                open={openSearchWindow}
                onClose={handleClose} // Закриваємо і очищаємо всі дані
            >
                <DialogTitle>
                    Поиск по референсу оборудования

                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <InputLabel id="categories-label">Отрасли</InputLabel>
                                <Select
                                    labelId="categories-label"
                                    multiple
                                    value={selectedCategories}
                                    onChange={handleCategoryChange}
                                    renderValue={(selected) => selected.map(category => category.name).join(', ')}
                                    disabled={industriesList.length === 0}
                                >
                                    {industriesList.map((industry) => (
                                        <MenuItem key={industry.id} value={industry}>
                                            {industry.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/*<Grid item xs={12} md={3}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={nonStrictSearch}
                                        onChange={handleSwitchChange}
                                        color="primary"
                                    />
                                }
                                label="Показать весь комплект оборудования"
                            />
                        </Grid>*/}
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        value={searchRequest}
                        onChange={(e) => handleSearchChange(e.currentTarget.value)}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Что ищем?"
                        type="text"
                        fullWidth
                        variant="standard"
                    />

                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {searchResults.length > 0 &&
                            searchResults.map((item, index) => (
                                <Card key={index} sx={{ marginBottom: '16px', padding: '16px' }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} md={3}>
                                            {nonStrictSearch
                                                ? item?.equipment_list?.map((equipment, index) => (
                                                    <Typography variant="h6" component="div" key={index}>
                                                        {index + 1}. {equipment}
                                                    </Typography>
                                                ))
                                                : <Typography variant="h6" component="div">
                                                    {item?.product_name}
                                                </Typography>
                                            }
                                        </Grid>

                                        <Grid item xs={12} md={9}>
                                            <CardContent>
                                                <Typography variant="body2" color="text.main">
                                                    {item.description ?? 'Описания нет. Непорядок!'}
                                                </Typography>
                                                <br />
                                                <NavLink
                                                    to={'/company/profile/' + item.company_id}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {item?.name_with_akon}
                                                </NavLink>
                                                {(item?.invoice_number || item?.kp_number) && (
                                                    <> (
                                                        <NavLink
                                                            to={'/check/' + (item?.kp_number ?? item?.invoice_id) + '/' + (item?.invoice_number ? '0' : '4')}
                                                            target="_blank"
                                                        >
                                                            {item?.invoice_number ? 'Счёт №' + item?.invoice_number : 'КП №' + item?.kp_number}
                                                        </NavLink>
                                                        )
                                                    </>
                                                )}
                                                {item?.industry_ids?.length > 0 && (
                                                    <>
                                                        <br />
                                                        {Object.keys(item?.industries)?.map((index) => (
                                                            <Chip key={item.id + '-' + index} label={item?.industries[index]} />
                                                        ))}
                                                    </>
                                                )}
                                            </CardContent>
                                        </Grid>
                                    </Grid>

                                    <Divider />
                                </Card>
                            ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Закрыть</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SearchEquipment;
