import React from 'react';
import { withRouter } from 'react-router-dom';
import AnyFormatDate from '../../../Production/common/AnyFormatDate/AnyFormatDate';
import MainRecPaya from '../MainRecPaya/MainRecPaya';
import s from './ReceivablesMain.module.css';


const ReceivablesMain = (props) =>{
    let dateNow = new Date();
    let mainSumReceivables = 0;
    let mainSumReceivablesOff = 0;
    let procent = 0
    if(props.receivables.length!==0){
        mainSumReceivables = props.receivables.reduce(function(p,c){return Number(p)+Number(c.numeral)-Number(c.paid);},'');
        mainSumReceivablesOff = props.receivables.reduce(function(p,c){
            let dateCheck = new Date(c.date_receivables);
            if(dateNow.getTime() > dateCheck.getTime())return (
                Number(p)+Number(c.numeral)-Number(c.paid))
        },'');
        procent = mainSumReceivablesOff*100/mainSumReceivables
    }
    
    
    
    return (
    <>
        <h2>Дебиторская задолженность</h2>
        <div className={s.main+" "+s.weight}>
            <div className={s.border}>Всего {mainSumReceivables.toLocaleString()} грн</div>
            <div className={s.border}>Просрочена {mainSumReceivablesOff.toLocaleString()} грн</div>
            <div className={s.border}>Процент просрочки {procent} %</div>
        </div>
        <div className={s.main +" "+s.weight}>
            <div>Название счёта</div>
            <div>Сумма счёта</div>
            <div>Дата постоплаты</div>
        </div>
            {props.receivables.map((k,index)=>{
                return(
                    <div className={s.main} key={"receivables_"+index}>
                        <div>{k.name}</div>
                        <div>{k.numeral}</div>
                        <div>{<AnyFormatDate date={k.date_receivables}/>}</div>
                    </div>
                )
            })

            }
        <MainRecPaya />
    </>)
        
}
export default withRouter(ReceivablesMain);