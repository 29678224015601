export const managersList = [
    {id: 1, name: 'Кузьменко Владислав'},
    {id: 34, name: 'Наталья Александровна'},
    {id: 44, name: 'Дунаев Алексей'},
    {id: 288, name: 'Федосов Максим'},
    {id: 297, name: 'Марченко Андрей' },
    {id: 486, name: 'Осадчий Олег'},
    {id: 1055, name: 'Мартыненко Евгений'},
    {id: 1056, name: 'Кучер Евгений'},
    {id: 1061, name: 'Сталин Дмитрий'},
];

export const salesManagersList = [
    {id: 44, name: 'Дунаев Алексей'},
    {id: 297, name: 'Марченко Андрей' },
    {id: 486, name: 'Осадчий Олег'},
    {id: 1055, name: 'Мартыненко Евгений'},
    {id: 1056, name: 'Кучер Евгений'},
    {id: 1061, name: 'Сталин Дмитрий'},
];