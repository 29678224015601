import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getSumAllPaidCheckForMonth, getSumAllPaidCheckForMonthRu, getSumPaidCostsForMonth} from '../../../../redux/admin-reducer';
import NewSchedule from './NewSchedule';

class NewScheduleContainer extends React.Component{
    
    componentDidMount(){
        let month=12;
        this.props.getSumAllPaidCheckForMonth(month);
        this.props.getSumAllPaidCheckForMonthRu(month);
        this.props.getSumPaidCostsForMonth(month);
    }
    componentDidUpdate(prevProps){
        
    }
    
    render(){
        return (
            <div>
                <NewSchedule sumCosts={this.props.sumCosts} sum={this.props.sum} sumRu={this.props.sumRu}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    sumCosts: state.admin.sumCosts,
    sum: state.admin.sum,
    sumRu: state.admin.sumRu,
})
export default compose( connect( mapStateToProps, {getSumAllPaidCheckForMonth, getSumAllPaidCheckForMonthRu, getSumPaidCostsForMonth}), withRouter )(NewScheduleContainer);