import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getReceivablesAdmin} from '../../../../redux/admin-reducer';
import ReceivablesMain from './ReceivablesMain';

class ReceivablesMainContainer extends React.Component{
    
    componentDidMount(){
        this.props.getReceivablesAdmin()
    }
    componentDidUpdate(prevProps){
        
    }
    
    render(){
        return (
            <ReceivablesMain receivables={this.props.receivables}/>
        )
    } 
}

let mapStateToProps = (state) =>({
    receivables: state.admin.receivables,
})
export default compose( connect( mapStateToProps, {getReceivablesAdmin}), withRouter )(ReceivablesMainContainer);