import React, {useState, useEffect} from 'react';
import s from './SalesPersonal.module.css';
import WhatType from './withType/WhatType';
import SetPeriod from './common/SetPeriod';
import {NavLink, withRouter} from 'react-router-dom';
import Statistics from './common/Statistics';
import Filtr from './Filtr/Filtr';
import Cookies from 'js-cookie'
import AddNewNomenclatures from './AddNewNomenclatures/AddNewNomenclatures';
import Modal from '../../Production/common/Modal/Modal';
import AddNewCompanyContainer from '../Company/AddNewCompany/AddNewCompanyContainer';
import MenuManager from '../common/MenuManager/MenuManager';

const SalesPersonal = (props) => {
    console.log(props.allActivity)
    let personalId;
    if (Cookies.get('id') == "44" || Cookies.get('id') == "34") {
        personalId = props.match.params.personalId
    } else {
        personalId = Cookies.get('id')
    }
    let [allActivity, setAllActivity] = useState(props.allActivity)
    useEffect(() => {
        setAllActivity([...props.allActivity]);
    }, [props.allActivity])
    //сортируем по дате от текущей
    let asd = (arr) => {
        return arr.sort((a, b) => b.dateMain > a.dateMain ? 1 : -1);
    }
    let [showCheck, setShowCheck] = useState(true)
    let [showKp, setShowKp] = useState(true)
    let [showMessage, setShowMessage] = useState(true)
    let [showTech, setShowTech] = useState(true)
    let [showMeeting, setMeeting] = useState(true)
    let [showTestCheck, setShowTestCheck] = useState(true)
    const show = (type) => {
        switch (type) {
            case 1:
                setShowCheck(!showCheck);
                break
            case 2:
                setShowKp(!showKp);
                break
            case 3:
                setShowMessage(!showMessage);
                break
            case 4:
                setShowTech(!showTech);
                break
            case 5:
                setMeeting(!showMeeting);
                break
            case 6:
                setShowTestCheck(!showTestCheck);
                break
        }
    }
    //Показать все счета за 6 месяцев
    const showCheckFor6Mounth = () => {
        setShowKp(false)
        setShowMessage(false)
        setShowTech(false)
        //высчитываем даты сегодня
        let now = new Date();
        let day = ("0" + ((new Date()).getDate())).slice(-2);
        let month = now.getUTCMonth() + 1;
        now = now.getFullYear() + "-" + month + "-" + day
        //высчитываем даты на 14 дней раньше
        let end = new Date(now);
        end = end.setMonth(end.getMonth() - 6)
        end = new Date(end);
        let dayEnd = ("0" + (end.getDate())).slice(-2);
        let monthEnd = end.getUTCMonth() + 1;
        end = end.getFullYear() + "-" + monthEnd + "-" + dayEnd
        console.log(now)
        console.log(end)
        props.getAllActivity(personalId, end, now)
    }
    let [showFiltr, setShowFiltr] = useState(false)
    const setShowFiltrF = () => {
        setShowKp(true)
        setShowMessage(true)
        setShowTech(true)
        setShowFiltr(false)
        //высчитываем даты сегодня
        let now = new Date();
        let day = ("0" + ((new Date()).getDate())).slice(-2);
        let month = now.getUTCMonth() + 1;
        now = now.getFullYear() + "-" + month + "-" + day
        //высчитываем даты на 14 дней раньше
        let end = new Date(now);
        end = end.setDate(end.getDate() - 14)
        end = new Date(end);
        let dayEnd = ("0" + (end.getDate())).slice(-2);
        let monthEnd = end.getUTCMonth() + 1;
        end = end.getFullYear() + "-" + monthEnd + "-" + dayEnd
        props.getAllActivity(personalId, end, now)
    }
    let [addCompany, setAddCompany] = useState(false)
    let [lastIdCompany, setLastIdCompany] = useState(props.lastIdCompany)
    useEffect(() => {
        setLastIdCompany(props.lastIdCompany)
    }, [props.lastIdCompany]);
    return (
        <div>
            <Filtr showCheckFor6Mounth={showCheckFor6Mounth} setShowFiltr={setShowFiltr}/>
            {(Cookies.get('id') == "44" || Cookies.get('id') == "34") &&
            <MenuManager idNow={props.match.params.personalId} link="/salesPersonal"/>
            }
            <div className={s.link}>
                <div><a href='https://sales.akon.com.ua/admin/search/company.php' target='_blank'>Поиск компаний</a></div>
                <div><span onClick={() => setAddCompany(true)}>Добавить компанию</span></div>
                <div><a href='https://sales.akon.com.ua/products.php' target='_blank'>На сайт</a></div>
                <div><AddNewNomenclatures addNewNomenclatures={props.addNewNomenclatures}
                                          statusNomenclatures={props.statusNomenclatures}/></div>
            </div>
            <h1>Кабинет взаимодействия</h1>
            {showFiltr
                ?
                <div className={s.filter}>
                    <h2>Фильтры: <span onClick={() => setShowFiltrF(false)}>Только счета за 6 месяцев <span
                        className={s.close}>х</span></span></h2>
                </div>
                :
                <div>
                    <SetPeriod getAllActivity={props.getAllActivity}/>
                    <Statistics allActivity={allActivity} show={show} showCheck={showCheck} showKp={showKp}
                                showMessage={showMessage} showTech={showTech} showMeeting={showMeeting} showTestCheck={showTestCheck}/>
                </div>
            }

            <div className={s.main + ' ' + s.header}>
                <div>Тип</div>
                <div>Действие</div>
                <div>Дата</div>
            </div>
            {asd(allActivity).map((k, index) => {
                return <WhatType type={k.type} {...k} key={"allActivity" + index} showCheck={showCheck} showKp={showKp}
                                 showMessage={showMessage} showTech={showTech} showMeeting={showMeeting} showTestCheck={showTestCheck}/>
            })
            }
            {addCompany &&
            <Modal active={addCompany} setActive={setAddCompany}>
                <AddNewCompanyContainer active={addCompany} setActive={setAddCompany}/>
            </Modal>
            }
            {lastIdCompany &&
            <Modal active={lastIdCompany} setActive={setLastIdCompany}>
                <div>
                    <NavLink to={"/company/profile/" + lastIdCompany[0] ?? ''}
                       target='_blank'>
                        <h3>Перейти в карточку клиента</h3>
                    </NavLink>
                    <br />
                    <hr />
                    <div><b>Название полное:</b> {lastIdCompany[1] ?? ''}</div>
                    <div><b>Внутреннее название:</b> {lastIdCompany[2] ?? ''}</div>
                </div>
            </Modal>
            }
        </div>

    )
}

let SalesWithRedirect = SalesPersonal

export default withRouter(SalesWithRedirect);
