import React, {useState} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import s from './AddNewElemFromStorage.module.css'
import {Grid} from "@mui/material";

const AddNewElemFromStorage = (props) => {

    //allComponentsFromStorage, changeElem
    let [elem, setElem] = useState("");
    const setElemF = (e) => {
        setElem(e.target.value)
    }
    let [coll, setColl] = useState(1);
    const setCollF = (e) => {
        setColl(e.target.value)
    }
    const addNewElemF = () => {
        props.addNewElem(elem, coll)
        setElem("")
        setColl(1)
        props.handleClose()
    }
    console.log(props.curID)
    return (
        <div className={s.main} align="center">
            <Grid container>
                <Grid item l={12} md={12}>
                    <input
                        type="text"
                        list="component"
                        name="idElem"
                        value={elem}
                        onChange={setElemF}
                        placeholder="Выбрать элемент"
                    />
                    <datalist id="component">
                        {props.allComponentsFromStorage.map((j, index) => {
                            return <option key={"allComponentsFromStorage_" + index}
                                           value={j.specification_id}>{j.name}</option>
                        })
                        }
                    </datalist>
                </Grid>
                <Grid item l={12} md={12}>
                    <input type="number" value={coll} onChange={setCollF}/>
                </Grid>
                <Grid item l={12} md={12}>
                    <button
                        onClick={() => addNewElemF()}
                        disabled={props.curID === 0 || elem.length === 0}
                    >
                        Добавить
                    </button>
                </Grid>
            </Grid>
        </div>
    );
}
export default withRouter(AddNewElemFromStorage);