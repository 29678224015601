import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {getCompanyList} from '../../../../redux/other-reducer';
import {getAgentsPersonalCompany, setNewCompanyAgent} from '../../../../redux/company-reducer';
import {getLastIdKp, addNewKp, editKp, deleteKp, getKpById, updateKp} from '../../../../redux/check-reducer';
import {getCourse} from '../../../../redux/admin-reducer';
import {getWorkersData} from '../../../../redux/worker-reducer';
import {getStatusKp} from '../../../../redux/sales-reducer';
import AddNewKp from './AddNewKp';
import Cookies from "js-cookie";

const AddNewKpContainer = ({
                               getCompanyList,
                               getCourse,
                               getLastIdKp,
                               getStatusKp,
                               match,
                               getKpById,
                               getWorkersData,
                               ...props
                           }) => {
    let [isLoading, setIsLoading] = useState(true);

    let isAdmin = !!Cookies.get('adminID');
    let currentPersonalId = match?.params?.idManager ?? 0;
    if (!isAdmin) {
        if (Cookies.get('id') != currentPersonalId) {
            currentPersonalId = Cookies.get('id');
        }
    }
    let invoiceNumber = match.params.isInvoice == 1 ? match.params.idKp : null;


    console.log(match.params)
    useEffect(() => {
        async function loadData() {
            const apiCalls = [
                getCompanyList(),
                getCourse(),
                match.params.idKp !== undefined && match.params.idKp !== null
                    ? getKpById({
                        idManager: currentPersonalId,
                        idKp: match.params.idKp,
                        isInvoice: match.params.isInvoice,
                    })
                    : getLastIdKp({
                        idManager: currentPersonalId,
                        isInvoice: match.params.isInvoice,
                    }),
                getStatusKp(),
                getWorkersData(currentPersonalId),
            ];

            await Promise.all(apiCalls);

            setIsLoading(false);
        }

        loadData();

    }, [getCompanyList, getCourse, getLastIdKp, getStatusKp, match.params.idKp, getKpById, getWorkersData]);

    return isLoading ? 'Загрузка...' :
        <AddNewKp
            {...props}
            personalId={currentPersonalId}
            course={props.course}
            invoiceID={invoiceNumber}
            is_invoice={match.params.isInvoice === 'true'}
            is_invoice_edit={match.params.isInvoice === 'true' && match.params.idKp !== undefined}
            invoice_number={invoiceNumber}
            idKP = {match.params.idKp}
        />;
};

const mapStateToProps = (state) => ({
    companyList: state.other.companyList,
    companyAgents: state.company.companyAgents,
    course: state.admin.course,
    lastIdKp: state.check.lastIdKp,
    statusKp: state.sales.statusKp,
    kpInfoByID: state.check.kpInfoByID,
    workerData: state.worker.workerData,
});

export default compose(
    connect(mapStateToProps, {
        getCompanyList,
        getAgentsPersonalCompany,
        getCourse,
        setNewCompanyAgent,
        getLastIdKp,
        getKpById,
        editKp,
        updateKp,
        deleteKp,
        getStatusKp,
        getWorkersData,
    }),
    withRouter
)(AddNewKpContainer);
