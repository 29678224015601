import React from 'react';
import { NavLink } from 'react-router-dom';
import s from "./MonthBlock.module.css";
const MonthBlock = (props) => {
    let month = Number(props.month)
    let monthArray = ["Январь","Февраль","Март","Апрель","Май","Июнь","Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь"]
    return(
        <div className={s.main}>
            {
                monthArray.map((k,index)=>{
                    index = index + 1//увеличиваем индекс на один, для того чтоб цифра совпадала с месяцем
                    return <NavLink to={"/"+props.path+"/"+index} key={"month_"+index}><div className={month===index ? s.active : ""}>{k}</div></NavLink>
                })
            }
        </div>
    )
}
export default MonthBlock;