import React, { useState }  from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import s from './Specifications.module.css'

const Specifications = (props) =>{
    console.log(props.allComponentsFromStorage)
    console.log(props.specificationList)
    let [mainColl, setMainColl] = useState(1);
    const setMainCollF = (e) =>{
        e.target.value >= 0 ? setMainColl(e.target.value) : setMainColl(1);
    }

    const allComponentsFromStorage = props.allComponentsFromStorage;
    const getCollWithStorage = (id) =>{
        return  allComponentsFromStorage.map(p =>{
            if(p.id == id){
                return p.coll 
            }
        })
    }
    const comparisonColl = (id, coll) =>{
        return  allComponentsFromStorage.map(p =>{
            if(p.id === id){
                if(Number(p.coll) > Number(coll) || Number(p.coll) === Number(coll)){
                    return "Да";
                }else{
                    return "Нужно купить";
                }
            }
        })
    }
    let [seacrhNomenclatures, setSeacrhNomenclatures] = useState(props.productId)
    const setSeacrhNomenclaturesF = (e) =>{
        setSeacrhNomenclatures(e.target.value);
    }
    const goToNewNomenclatures = () =>{
        props.history.push("/admin/specification/"+seacrhNomenclatures) 
    }
    //высвечиваем стоимость и считаем себестоимость
    const sumTotal = arr => arr.reduce((sum, { unit_price }) => Number(sum) + Number(unit_price), 0)
    const total = sumTotal(props.specificationList)
    //поиск по нажатию энтера
    let handleKeyPress = (e) =>{
        if (e.charCode === 13){ // Клавиша Enter
            goToNewNomenclatures();
        }
      }
    return (
    <>
    <div className={s.main + " " + s.mainThreeHeader}>
        <div></div>
        <div className={s.name}>{props.nomenclaturesData && <p>{props.nomenclaturesData.NAME}-{props.productId}</p>}</div>
        
       
        <div className={s.inputColl}>
            <h3>Поиск по номенклатурам</h3>
            <input type="number" value={seacrhNomenclatures} onChange={setSeacrhNomenclaturesF} onKeyPress={handleKeyPress}/>
            <button onClick={ () => goToNewNomenclatures()}>Search</button>
        </div>
        <div className={s.inputColl}>
            {
               total.toFixed(2)
            }
        </div>
        <div>#</div>
        <div>Компонент</div>
        <div>Необходимое кол.</div>
        {/* <div>На складе</div> */}
        {/* <div>Хватает?</div> */}
        <div>Сумма за позицию</div>
    </div>
        {
            props.specificationList.map( (p, index) => {
                return(
                    <div className={s.main} key={"specificationList_"+index}>
                        <div>{index+1}</div>
                        <div><NavLink to={"/suppliers/"+p.storage_component_id}>{p.storage_component_name}</NavLink></div>
                        <div>{p.coll * mainColl}</div>
                        {/* <div>{getCollWithStorage(p.storage_component_id)}</div> */}
                        {/* <div>{comparisonColl(p.storage_component_id, p.coll * mainColl)}</div> */}
                        <div>{Number(p.unit_price).toFixed(2)}</div>
                    </div>
                )
            })
        }
    </>
    );
}
export default withRouter(Specifications);