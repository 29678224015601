import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import {
    getStatisticsPaidFromModulesNomenclature,
    getPersonalBalancesInStock,
    getModulesFinanceInfo
} from '../../redux/moduls-reducer';
import PersonalModule from './PersonalModule';
import Cookies from "js-cookie";

class PersonalModuleContainer extends React.Component {
    constructor() {
        super();
        this.canSeeSelfCost = false;
    }
    componentDidMount() {
        this.props.getStatisticsPaidFromModulesNomenclature(this.props.match.params.nom);
        this.props.getPersonalBalancesInStock(this.props.match.params.nom);
        let managerID = parseInt(Cookies.get('id'));

        if(managerID === 34 || managerID === 44){
            this.props.getModulesFinanceInfo(this.props.match.params.nom);
            this.canSeeSelfCost=true;
        }
    }

    render() {
        return <PersonalModule
            statisticsPaidFromModulesNomenclature={this.props.statisticsPaidFromModulesNomenclature}
            getStatisticsPaidFromModulesNomenclature={this.props.getStatisticsPaidFromModulesNomenclature}
            nom={this.props.match.params.nom}
            getPersonalBalancesInStock={this.props.getPersonalBalancesInStock}
            personalBalancesInStock={this.props.personalBalancesInStock}
            modulesFinanceInfo={this.props.modulesFinanceInfo}
            canSeeSelfcost={this.canSeeSelfCost}
        />
    }
}

let mapStateToProps = (state) => ({
    statisticsPaidFromModulesNomenclature: state.moduls.statisticsPaidFromModulesNomenclature,
    personalBalancesInStock: state.moduls.personalBalancesInStock,
    modulesFinanceInfo: state.moduls.modulesFinanceInfo
})
export default compose(connect(mapStateToProps, {
    getStatisticsPaidFromModulesNomenclature,
    getPersonalBalancesInStock,
    getModulesFinanceInfo
}), withRouter)(PersonalModuleContainer);