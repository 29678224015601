import React from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {getModulsWithComponent} from '../../../redux/moduls-reducer';
import ModulesWithComponent from './ModulesWithComponent';

class ModulesWithComponentContainer extends React.Component {
    componentDidMount() {
        const modulsWithComponentId = this.props.match.params.modulsWithComponent || this.props.id;
        this.props.getModulsWithComponent(modulsWithComponentId);
    }

    render() {
        return (
            <ModulesWithComponent modulsWithComponent={this.props.modulsWithComponent}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modulsWithComponent: state.moduls.modulsWithComponent
    };
};

export default compose(
    connect(mapStateToProps, {getModulsWithComponent}),
    withRouter
)(ModulesWithComponentContainer);
