import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { withRouter } from 'react-router-dom';
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate';
import s from './Shipment.module.css';

const Shipment = (props) =>{
    //========================================================================возможно нужно удалить sentInvoicesForMonth
    console.log(props.invoiceForMonthWithDateManager)
    //вычисляем норму дней на работу производства
    let normaPlan = props.invoiceForMonthWithDateManager.reduce(function(p,c){return Number(p)+Number(c.intervalDaysPlan);},'');
    let fact = props.invoiceForMonthWithDateManager.reduce(function(p,c){return Number(p)+Number(c.intervalDaysFact);},'');
    let timelyPlan = (1+(normaPlan-fact)/normaPlan)*100
    const getRightSum = (sum)=>{
        let s = Math.round(sum)
        return Number(s).toLocaleString('ru-RU');
    }
    let mainSum = Number(props.sumSentInvoicesForMonth[0])+Number(props.sumSentInvoicesForMonth[1]);
    mainSum = getRightSum(mainSum);
    //формируем для графика
    const data = [];
    data.push({
        name: 0,
        "сумма": 0
    })
    props.sumSentInvoicesForYear.map((k,index)=>{
        data.push({
            name: index+1,
            "сумма": k,
            // "План": k.plan,
            // "Росс": sumRu[index],
        })
    })
    //закрашиваем цветом
    const getColor = (plan, fact)=>{
        let a = Number(plan) - Number(fact)
        console.log(a)
        switch(a){
            case a>0:
                return s.red
            default: return "red"
        }
        
        // return s.main
    }
    return(
        <div>
            <ResponsiveContainer width="95%" height={250}>
                <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 10,
                }}
                >
                <CartesianGrid strokeDasharray="2 2" />
                <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} />
                {/* <YAxis /> */}
                {/* <Tooltip /> */}
                <Legend />
                <Line type="monotone" dataKey="сумма" stroke="#8884d8" label={<CustomizedLabel />} />
                {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                </LineChart>
            </ResponsiveContainer>
            <div className={s.div3}>
                <div>Норма план: {normaPlan+" дней"}</div>
                <div>Норма факт: {fact+" дней"}</div>
                <div>% своевременного выполнения плана: {Math.round(timelyPlan)+" %"}</div>
            </div>
            <h1>Отгрузки - {mainSum+" грн"}</h1>
            <div className={s.main+" "+s.bold}>
                <div>Номер счёта</div>
                <div>Дата оплаты заказа</div>
                <div>Дата Плановая</div>
                <div>Дата Факт</div>
                <div>Срок план</div>
                <div>Срок факт</div>
            </div>
            {props.invoiceForMonthWithDateManager.map((k,index)=>{
                return(
                    <div className={s.main} key={"invoiceForMonthWithDateManager"+index}>
                        <div>{k.number}</div>
                        <div>{<AnyFormatDate date={k.p_date_main} />}</div>
                        <div>{<AnyFormatDate date={k.date_manager} />}</div>
                        <div className={(k.intervalDaysPlan - k.intervalDaysFact) < 0 && s.red }>{<AnyFormatDate date={k.date_op} />}</div>
                        <div>{k.intervalDaysPlan}</div>
                        <div>{k.intervalDaysFact}</div>
                    </div>
                )
            })
            }
            
        </div>
    )
}
export default withRouter(Shipment);

class CustomizedLabel extends PureComponent {
    render() {
      const { x, y, stroke, value } = this.props;
      return (
        <text x={x} y={y} dy={-4} fill={stroke} textAnchor="middle" fontSize={16} fontWeight={900} >
          {value.toLocaleString()}
        </text>
      );
    }
  }
  
class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, stroke, payload } = this.props;
    
        return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload.value}
            </text>
        </g>
        );
    }
}