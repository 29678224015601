import React from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {getAllCompanyOutdatedWithManager} from '../../../redux/sales-reducer';
import AllCompanyOutdatedWithManager from './AllCompanyOutdatedWithManager';
import Cookies from 'js-cookie'

class AllCompanyOutdatedWithManagerContainer extends React.Component {
    componentDidMount() {
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.match.params.manager;
        if(!isAdmin){
            if(Cookies.get('id')!=currentPersonalId){
                currentPersonalId = Cookies.get('id');
            }
        }
        this.props.getAllCompanyOutdatedWithManager(currentPersonalId);
    }

    render() {
        return (
            <div>
                <AllCompanyOutdatedWithManager {...this.props}/>
            </div>
        )
    }
}

let mapStateToProps = (state) => ({
    allCompanyOutdatedWithManager: state.sales.companyOutdatedSegmentation,
})
export default compose(connect(mapStateToProps, {
    getAllCompanyOutdatedWithManager
}), withRouter)(AllCompanyOutdatedWithManagerContainer);