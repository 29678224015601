import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getNomenclaturesData, getALLNomenclatures} from '../../../redux/specification-reducer';
import {getAllComponentsFromStorage} from '../../../redux/addStorage-reducer';
import {getSpecificationListAdmin} from '../../../redux/admin-reducer';
import Specifications from './Specifications';

class SpecificationsContainer extends React.Component{
    componentDidMount(){
        this.props.getSpecificationListAdmin(this.props.match.params.productId);
        this.props.getAllComponentsFromStorage();
        this.props.getNomenclaturesData(this.props.match.params.productId);
        this.props.getALLNomenclatures();
    }
    componentDidUpdate(prevProps){
        if(prevProps.match.params.productId !== this.props.match.params.productId){
            this.props.getSpecificationListAdmin(this.props.match.params.productId);
            this.props.getNomenclaturesData(this.props.match.params.productId);
            this.props.getALLNomenclatures();
        }
    }
    
    render(){
        return (
            <div>
                <Specifications specificationList={this.props.specificationList} productId={this.props.match.params.productId} allComponentsFromStorage={this.props.allComponentsFromStorage} nomenclaturesData={this.props.nomenclaturesData} allNomenclatures={this.props.allNomenclatures}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    specificationList: state.admin.specificationList,
    nomenclaturesData: state.specification.nomenclaturesData,
    allNomenclatures: state.specification.allNomenclatures,
    allComponentsFromStorage: state.addStorage.allComponentsFromStorage
})
export default compose( connect( mapStateToProps, {getSpecificationListAdmin, getAllComponentsFromStorage, getNomenclaturesData, getALLNomenclatures}), withRouter )(SpecificationsContainer);