import React, { useState,useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate';
import s from './TechStorage.module.css';


const techStorage = (props) =>{
    console.log(props.techStorage)
    return(
        <div>
            <div className={s.main+" "+s.hei}>
                <div>Название</div>
                <div>Количество</div>
                <div>Дата прихода</div>
            </div>
            {props.techStorage.map(k=>{
                return(
                    <div className={s.main} key={"techStorage_"+k.id}>
                        <div>{k.moduls_name}</div>
                        <div>{k.moduls_coll}</div>
                        <div><AnyFormatDate date={k.date_add} /></div>
                    </div>
                )
            })
            }
        </div>
    )
}
export default withRouter(techStorage);