import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getAllCompanyCategories, getAllCompanySegmentation} from '../../../../redux/sales-reducer';
import {addNewCompany, getCompanyByEdrpou} from '../../../../redux/company-reducer';
import AddNewCompany from './AddNewCompany';

class AddNewCompanyContainer extends React.Component{
    componentDidMount(){
        this.props.getAllCompanyCategories();
        this.props.getAllCompanySegmentation();
    }

    render(){
        return (
            <div>
                <AddNewCompany {...this.props}/>
            </div>
        )
    }
}

let mapStateToProps = (state) =>({
    companyCategories: state.sales.companyCategories,
    companySegmentation: state.sales.companySegmentation,
    lastIdCompany: state.company.lastIdCompany,

})
export default compose(
    connect(
        mapStateToProps,
        {
            getAllCompanyCategories,
            getAllCompanySegmentation,
            addNewCompany,
            getCompanyByEdrpou
        }), withRouter )(AddNewCompanyContainer);
