import React, {useState} from 'react';
import s from './AllSharedCompanyWithManager.module.css';
import main from '../../Main.module.css';
import Cookies from 'js-cookie'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faMinus, faAlignLeft, faAlignRight} from '@fortawesome/free-solid-svg-icons';
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate';
import MenuManager from "../common/MenuManager/MenuManager";
import {NavLink} from "react-router-dom";

const AllSharedCompanyWithManager = (props) => {
    let personalId;
    if (Cookies.get('id') == "44" || Cookies.get('id') == "34") {
        personalId = props.match.params.manager
    } else {
        personalId = Cookies.get('id')
    }
    let managerID = personalId == 44
        ? 1
        : personalId == 486 ? 2 : parseInt(personalId);

    let request = {
        'managerID': personalId,
        'controlID': Cookies.get('id'),
        //    'year': this.props.match.params.year,
        //    'month': this.props.match.params.month,
    }
    console.log('request');
    console.log(request);
    console.log('params');
    console.log(props);
    //console.log(this.props.match.params);
    //console.log('params.manager' + personalId);
    //console.log('params.year' + this.props.match.params.year);
    //console.log('params.month' + this.props.match.params.month);

    //this.props.getRelatedPaymentForYearMonth(request);

    const getClass = (endDateText) => {

        let startDate = new Date();
        let endDate = new Date(endDateText);
        let monthsDifference = (endDate.getFullYear() - startDate.getFullYear()) * 12 +
            (endDate.getMonth() - startDate.getMonth());
        //let daysDifference = endDate.getDate() - startDate.getDate();

        if (monthsDifference < 1) return main.red
        if (monthsDifference < 3) return main.blue
        return main.green
    }
    const acceptCompany = (id) => {

        let request = {
            'managerID': Cookies.get('id'),
            'companyID': id,
            'rawManagerID': personalId,
        };
        props.acceptCompany2ndManager(request)

        console.log('accept ' + id)
    }
    const rejectCompany = (id) => {

        let request = {
            'managerID': Cookies.get('id'),
            'companyID': id,
            'rawManagerID': personalId,
        };
        props.rejectCompany2ndManager(request)

    }
    const getManagerNameByID = (id) => {
        switch (id) {
            case 1:
            case 44:
                return 'Дунаев Алексей';
            case 2:
            case 486:
                return 'Осадчий Олег';
            case 1055:
                return 'Мартиненко Євгеній';
            case 1056:
                return 'Кучер Євген';
            case 1061:
                return 'Сталин Дмитрий';
            default:
                return "??";
        }
    }

    console.log(props.allSharedCompanyWithManager)
    return (
        <div>
            {
                (Cookies.get('id') == "44" || Cookies.get('id') == "34")
                &&
                <MenuManager link={"/sales/allSharedCompany"}
                             idNow={props.match.params.manager}/>
            }

            <h1>Страница всех расшареных компаний менеджера</h1>

            <div className={s.main + " " + main.bold}>
                <div>#</div>
                <div>Название</div>
                <div>Менеджер 1</div>
                <div>Менеджер 2</div>
                <div>Расшарено</div>
                <div>Действительно до</div>
            </div>
            {props.allSharedCompanyWithManager && props.allSharedCompanyWithManager.length === 0 ?

                <>
                    <div className={s.main + main.colspanGrid6}>
                        <div>Пока нет расшаренных компаний</div>
                    </div>
                </>
                :


                props.allSharedCompanyWithManager.map((k, index) => {
                    return (
                        <div key={"allSharedCompanyWithManager_" + index} className={s.main}>
                            <div className=''>{index + 1}</div>
                            <div className=''>
                                <NavLink to={"/company/profile/" + k.id}
                                   target={"_blank"}>{k.name_with_akon}</NavLink>
                            </div>
                            <div className=''>{getManagerNameByID(k.whose_company) ?? '???'}</div>
                            <div className=''>{k.whose_company2_name ?? '???'}</div>
                            <div className=''><AnyFormatDate date={k.whose_company2_date_add}/></div>
                            <div className=''>
                                {

                                    k.whose_company2_expire_date != null ? (
                                        <AnyFormatDate
                                            date={k.whose_company2_expire_date}
                                            class={getClass(k.whose_company2_expire_date)}
                                        />
                                    ) : (
                                        <span
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                        >
                                        {
                                            (managerID === parseInt(k.whose_company) || parseInt(Cookies.get('id')) === 44) ? (
                                                <>
                                                    <FontAwesomeIcon
                                                        icon={faMinus}
                                                        onClick={() => rejectCompany(k.id)}
                                                        style={{marginRight: 'auto'}}
                                                        className={main.red}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        onClick={() => acceptCompany(k.id)}
                                                        className={main.green}
                                                        style={{marginLeft: 'auto'}}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    Не принята
                                                </>
                                            )}
                                </span>
                                    )
                                }
                            </div>
                        </div>
                    )
                })
            }

        </div>

    )
}

export default AllSharedCompanyWithManager;
