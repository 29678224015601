import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getComponentSuppliers, getAllSuppliers, setComponentSuppliers, setComponentId, getComponentLinkForBuy, setNewLinkForBy, getStorageComponentPersonalData, getPurchasedComponents} from '../../../redux/suppliers-reducer'
import Suppliers from './Suppliers';

class SuppliersContainer extends React.Component{

    componentDidMount(){
        this.props.getAllSuppliers();
        this.props.setComponentId(this.props.match.params.suppliers);
        this.props.getComponentSuppliers(this.props.match.params.suppliers);
        this.props.getComponentLinkForBuy(this.props.match.params.suppliers);
        this.props.getStorageComponentPersonalData(this.props.match.params.suppliers);
        this.props.getPurchasedComponents(this.props.match.params.suppliers);
    }

    render(){
        return(
           <Suppliers allSuppliers={this.props.allSuppliers} componentSuppliers={this.props.componentSuppliers} setComponentSuppliers={this.props.setComponentSuppliers} componentId={this.props.componentId} linkForBuy={this.props.linkForBuy} setNewLinkForBy={this.props.setNewLinkForBy} storageComponentPersonalData={this.props.storageComponentPersonalData} purchasedComponents={this.props.purchasedComponents}/>
            )
    }
}
let mapStateToProps = (state) =>{
    return{
        allSuppliers: state.suppliers.allSuppliers,
        componentSuppliers: state.suppliers.componentSuppliers,
        componentId: state.suppliers.componentId,
        linkForBuy: state.suppliers.linkForBuy,
        storageComponentPersonalData: state.suppliers.storageComponentPersonalData,
        purchasedComponents: state.suppliers.purchasedComponents,
    }
}
export default compose(
    connect(mapStateToProps, {getComponentSuppliers, getAllSuppliers, setComponentSuppliers, setComponentId, getComponentLinkForBuy, setNewLinkForBy, getStorageComponentPersonalData, getPurchasedComponents}),
    withRouter
 )(SuppliersContainer);