import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import MonthBlock from '../../Production/common/MonthBlock/MonthBlock';
import CostsContainer from '../Costs/CostsContainer';
import IncomeContainer from '../Income/IncomeContainer';
import BalanceContainer from './Balance/BalanceContainer';
import s from './DDC.module.css';
import NewScheduleContainer from './newSchedule/NewScheduleContainer';


const DDC = (props) =>{
    let sum = props.sum.map(k=>k.sum);
    let sumRu = props.sumRu.map(k=>k.sum);
    let sumCosts = props.sumCosts.map(k=>k.sum);
    let month, year;
    if(props.match.params.month){
        month = props.match.params.month
    }else{
        let date = new Date();
        month = date.getMonth()+1
    }
    if(props.match.params.year){
        year = props.match.params.year
    }else{
        let date = new Date();
        year = date.getFullYear()
    }
    let num = Number(month)-1
    let result = sum[num]-sumCosts[num]+sumRu[num];
    let resultMain = result.toLocaleString();
    return(
        <div>
            <div className={s.main}>
                {/* <div className={year==="2021" ? s.active : ""}><NavLink to="/admin/ddc/2021/12">2021</NavLink></div> */}
                <div className={year==="2022" ? s.active : ""}><NavLink to="/admin/ddc/2022/12">2022</NavLink></div>
                <div className={year==="2023" ? s.active : ""}><NavLink to="/admin/ddc/2023/12">2023</NavLink></div>
                <div className={year==="2024" ? s.active : ""}><NavLink to="/admin/ddc/2024/12">2024</NavLink></div>
            </div>
            <MonthBlock path={"admin/ddc/"+year} month={month}/>
            {/* {props.sumMoneyDelivery!=0
            ? <div className={s.moneyDelivery+" "+s.red}>{"Не разнесена сумма Новой Почты "+props.sumMoneyDelivery+" грн"}</div>
            : <div className={s.moneyDelivery+" "+s.green}>Сумма Новой Почты разнесена полностью</div>
            } */}
            
            <IncomeContainer month={month} year={year}/>
            <CostsContainer month={month} year={year}/>
            <h2><span className={s.title}>Результат: {resultMain+" грн"}</span></h2>
            <BalanceContainer month={month} result={result} year={year}/>
            
            <NewScheduleContainer />
            
        </div>
    )
}
export default withRouter(DDC);