import React from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {getCompanyList} from '../../../../redux/other-reducer';
import {getAgentsPersonalCompany, setNewCompanyAgent} from '../../../../redux/company-reducer';
import {getLastIdKp, addNewKp} from '../../../../redux/check-reducer';
import {getCourse} from '../../../../redux/admin-reducer';
import {getStatusKp} from '../../../../redux/sales-reducer';
import CompanyAndAgents from './CompanyAndAgents';

class CompanyAndAgentsContainer extends React.Component {
    componentDidMount() {
        // this.props.getCompanyList(this.props.match.params.idManager);
        this.props.getCompanyList();
        this.props.getCourse();
        //this.props.getLastIdKp({idCompany: this.props.match.params.idCompany,});
        this.props.getStatusKp();
    }

    render() {
        return (
            <div>
                <CompanyAndAgents {...this.props} personalId={this.props.match.params.idManager}/>
            </div>
        )
    }
}

let mapStateToProps = (state) => ({
    companyList: state.other.companyList,
    companyAgents: state.company.companyAgents,
    course: state.admin.course,
    //lastIdKp: state.check.lastIdKp,
    statusKp: state.sales.statusKp,
})
export default compose(connect(mapStateToProps, {
    getCompanyList,
    getAgentsPersonalCompany,
    getCourse,
    setNewCompanyAgent,
    //getLastIdKp,
    //addNewKp,
    getStatusKp
}), withRouter)(CompanyAndAgentsContainer);