import React, { useState } from 'react';
import { NavLink, withRouter, Link } from 'react-router-dom';
import MainSupplierList from './MainSupplierList/MainSupplierList';
import s from './Sales.module.css';
import { useCookies } from 'react-cookie';

const Sales = (props) =>{
    // console.log(props.emailCompanyAgents)
    // console.log(props.companyInSegmentCategory)
    //выводим список email или весь список компаний
    let [showEmail, setShowRmail] = useState(false)
    //храним данные с исключениями тут
    let [mainSup, setMainSup] = useState([]);
    if(props.mainSuppliers && mainSup.length < 1){
        let array = props.mainSuppliers.split(",");
        setMainSup(array)
    } 

    let [segment, setSegment] = useState(0);
    let [category, setCategory] = useState(0);
    const getCompanySegment = (e) =>{
        setSegment(e.target.value);
        getCompany(e.target.value, category);
    }
    const getCompanyCategory = (e) =>{
        setCategory(e.target.value);
        getCompany(segment, e.target.value);
    }
    //собираем массив с фильтрами
    const newArray = (segmentNow = segment, categoryNow = category)=>{
        let array = []
        array.push(segmentNow)
        array.push(categoryNow)
        array.push(mainSup)
        return array
    }
    const getCompany = (segmentNow, categoryNow) =>{
        let array = newArray(segmentNow, categoryNow)
        props.getCompanyInSegmentationCategories(array);
    }
    const getSegment = (id) =>{
        return props.companySegmentation.map( p=>{
            if(id == p.id) return p.name
        }) 
    }
    const getCategory = (id) =>{
        return props.companyCategories.map( p=>{
            if(id == p.id) return p.name
        }) 
    }
    //добавляем компанию в игнор лист и больше не выводим в этой статистике
    const setCompanyNotActiveF = (id) =>{
        let array = newArray()
        props.setCompanyNotActive(id, array);
    }
    //добавляем в адресную строку данные для фильтра
    const getHistoryForFiltr = (id)=>{
        let check = checkMainSup(id)
        if(check != -1){
            //если это последний элемент в массиве, то очищяем полностью массив
            if(mainSup.length<=1){
                mainSup = []
            }else{
                mainSup.splice(check, 1)
            }
            setMainSup(mainSup)
        }else{
            mainSup.push(id)
            setMainSup(mainSup)
        }
        //записываем в адресную строку
        let eArray = mainSup.join();
        props.history.push("/sales/"+eArray)
        getCompany(segment, category)
    }
    
    //проверяем выбрана ли компания в исключения
    const checkMainSup = (id)=>{
        let findI = mainSup.indexOf(id)
        return findI
    }
    //выводим email списком для продажника
    const getEmail = (companyId)=>{
        return props.emailCompanyAgents.map(k=>{
            if(k.company_id==companyId) return <tr><td>{k.email}</td></tr>
        })
    }
    const [cookies, setCookie] = useCookies();
    return(
        <div>
            <div>{(cookies.id==44 || cookies.id==987) && <><NavLink to="/statistics">Статистика</NavLink> <Link to="/maps">Карта</Link> <Link to="/company">Компании</Link></>}</div>
            <div className={s.mainFilter}>
                <div>
                    <h3>Выбрать Сегмент:</h3>
                    <div>
                        <select onChange={getCompanySegment}>
                            <option value="0">Все компании</option>
                            {
                            props.companySegmentation.map( (p,i)=>{
                                return <option value={p.id} key={"companySegmentation"+i}>{p.name}</option>
                            }) 
                            }
                        </select>
                    </div>
                </div>
                <div>
                    <h3>Выбрать Отрасль:</h3>
                    <div>
                        <select onChange={getCompanyCategory}>
                            <option value="0">Все компании</option>
                            {
                            props.companyCategories.map( (p,i)=>{
                                return <option value={p.id} key={"companyCategories_"+i}>{p.name}</option>
                            }) 
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div><input type="checkbox" onClick={()=>setShowRmail(!showEmail)} />Показать все email</div>
            {!showEmail
            ?<>
            <h3>Список исключений</h3>
            <div className={s.mainSupplier}>
                {props.mainSupplier.map((k,i)=>{
                    return <div onClick={()=>getHistoryForFiltr(k.id)} key={"mainSupplier_"+i} className={checkMainSup(k.id)!=-1 ? s.active : ""}>{k.supplier}</div>
                })
                }
            </div>
            <h2 className={s.center}>Информация собрана за период начиная с апреля 2020 года</h2>
            <div className={s.main+" "+s.bold}>
                <div>Название</div>
                <div>Сегмент</div>
                <div>Отрасль</div>
                <div>Модули</div>
                <div>Поставщики</div>
                <div>Действия</div>
            </div>
            {
            props.companyInSegmentCategory.length>0 && props.companyInSegmentCategory.map( (p,i)=>{
                    return(
                        <div className={s.main} key={"companyInSegmentCategory_"+i}>
                            <div><NavLink to={"/company/profile/"+p.id} target="_blank">{p.name_with_akon}</NavLink></div>
                            <div>{getSegment(p.p_company_segmentation)}</div>
                            <div>{getCategory(p.p_client_segmentation)}</div>
                            <div>
                                {
                                    props.moduls.map((k,index)=>{
                                        return(
                                            p.id == k.company_id &&
                                            <div className={s.mainFilter+" "+s.modulsList} key={"moduls_"+index}>
                                                <div>{k.moduls_name}</div>
                                                <div>{k.sum_moduls_coll}</div>
                                            </div>
                                        ) 
                                    })
                                }    
                            </div>
                            <div><MainSupplierList id={p.id} supplierList={props.supplierList} /></div>
                            <div><input type="button" value="Не рабочая компания" onClick={()=>setCompanyNotActiveF(p.id)} /></div>
                        </div>) 
                })
            }
            </>
            : 
            <table>
                {props.companyInSegmentCategory.length>0 && props.companyInSegmentCategory.map( (p,i)=>{
                    return getEmail(p.id)
                })
                }
            </table>
        }
        </div>
        
    )
}
export default withRouter(Sales);