import React from 'react';
import Engineer from './Engineer';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {
    getWorkersModuls,
    getPersonalWorkersModuls,
    setModulsStatus,
    cancelAssembly,
    changeAssembler,
    setModulsStatusNew
} from '../../../redux/worker-reducer';
import {getWorkersList} from '../../../redux/productionMain-reducer';

class EngineerContainer extends React.Component {
    componentDidMount() {
        //this.props.getPersonalWorkersModuls(this.props.match.params.workerId)
        this.props.getWorkersModuls();
        this.props.getWorkersList();
    }

    filterModulesByWorkerID = (workerId) => {
        if (workerId === undefined) return this.props.workersModuls;
        console.log(this.props.workersModuls)
        return this.props.workersModuls.filter(modul => modul.who_make == workerId);
    }
    getWorkerModules = (workerId) => {
        return this.filterModulesByWorkerID(workerId);
    }

    getUniqueWhoMakeWithModulesCount = () => {
        let uniqueWhoMake = [];
        this.props.workersModuls.forEach(modul => {
            if (!uniqueWhoMake.includes(modul.who_make)) {
                uniqueWhoMake.push(modul.who_make);
            }
        });
        return uniqueWhoMake;
    }


    render() {

        return (
            <Engineer
                uniqueWhomake={this.getUniqueWhoMakeWithModulesCount()}
                workers={this.props.workers}
                workersModuls={this.getWorkerModules(this.props.match.params.workerId)}
                rawWorkersModuls={this.getWorkerModules()}
                workerId={this.props.match.params.workerId}
                setModulsStatus={this.props.setModulsStatus}
                setModulsStatusNew={this.props.setModulsStatusNew}
                cancelAssembly={this.props.cancelAssembly}
                changeAssembler={this.props.changeAssembler}
            />
        )
    }

}

let mapStateToProps = (state) => {
    return {
        workers: state.productionMain.workers,
        workersModuls: state.worker.workersModuls
    }
}

export default compose(
    connect(mapStateToProps, {
        getWorkersModuls,
        getPersonalWorkersModuls,
        setModulsStatus,
        cancelAssembly,
        changeAssembler,
        setModulsStatusNew,
        getWorkersList
    }),
    withRouter
)(EngineerContainer);