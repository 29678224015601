import React, {useEffect} from 'react';
import './App.css';
import Header from './components/Header/Header';
import ProductionContainer from './components/Production/ProductionMain/ProductionContainer';
import {BrowserRouter, Route, Router} from 'react-router-dom';
import ChiefContainer from './components/Production/Chief/ChiefContainer';
import EngineerContainer from './components/Production/Engineer/EngineerContainer';
import StorageContainer from './components/Production/Storage/StorageContainer';
import TechEngineerContainer from './components/Production/Engineer/TechEngineer/TechEngineerContainer';
import SuppliersContainer from './components/Production/Suppliers/SuppliersContainer';
import SpecificationsContainer from './components/Production/Specifications/SpecificationsContainer';
import AddNewSpecificationContainer from './components/Production/Specifications/AddNewSpecificationContainer.jsx';
import AdminContainer from './components/Admin/AdminContainer';
import MoneyDeliveryContainer from './components/Admin/MoneyDelivery/MoneyDeliveryContainer';
import SalesContainer from './components/Sales/SalesContainer';
import Statistics from './components/Statistics/Statistics';
import StatisticsModuleContainer from './components/Statistics/StatisticsModules/StatisticsModulesContainer';
import StatisticsBranchContainer from './components/Statistics/StatisticsBranch/StatisticsBranchContainer';
import CashContainer from './components/Cash/CashContainer';
import DDCContainer from './components/Admin/DDC/DDCContainer';
import DDCNewContainer from './components/Admin/DDCNew/DDCNewContainer';
import Menu from './components/Admin/Menu/Menu';
import MainSupplierContainer from './components/Sales/MainSupplier/MainSupplierContainer';
import Specifications from './components/Admin/Specifications/SpecificationsContainer.jsx';
import ShipmentContainer from './components/Admin/Shipment/ShipmentContainer';
import ReceivablesMainContainer from './components/Admin/Receivables/Receivables/ReceivablesMainContainer';
import ShipmentStatisticsContainer from './components/Admin/ShipmentStatistics/ShipmentStatisticsContainer';
import AccountsPayableContainer from './components/Admin/Receivables/AccountsPayable/AccountsPayableContainer';
import PlanContainer from './components/Admin/Plan/PlanContainer';
import SalesContainerAdmin from './components/Admin/Sales/SalesContainer';
import ShipmentContainerChief from './components/Production/Chief/Shipment/ShipmentContainer';
import TaskContainer from './components/Tech/Task/TaskContainer';
import FiltrWantsContainer from './components/Sales/FiltrWants/FiltrWantsContainer';
import KpContainer from './components/Sales/Kp/KpContainer';
import Login from './components/Login/Login';
import TechStorageContainer from './components/Tech/TechStorage/TechStorageContainer';
import TaskContainerAll from './components/Task/TaskContainer';
import StatisticsModelModulesContainer
    from './components/Statistics/StatisticsModelModules/StatisticsModelModulesContainer';
import CheckTestContainer from './components/Sales/CheckTest/CheckTestContainer';
import CheckTestContainerProduction from './components/Production/CheckTest/CheckTestContainer';
import StatisticsMenu from './components/Sales/Statistics/StatisticsMenu';
import CheckValueContainer from './components/Sales/Statistics/CheckValue/CheckValueContainer';
import PrivateOfficeContainer from './components/PersonalArea/PrivateOfficeContainer';
import PersonalAreaSalesContainer from './components/PersonalArea/PersonalAreaSales/PersonalAreaSalesContainer';
import CheckContainer from './components/Production/Check/CheckContainer';
import KpPlanContainer from './components/Sales/Kp/KpPlan/KpPlanContainer';
import ProgrammerContainer from './components/Production/Engineer/Programmer/ProgrammerContainer';
import CostsListContainer from './components/Admin/Costs/List/CostsListContainer';
import RepairContainer from './components/Production/Repair/RepairContainer';
import RepairTechContainer from './components/Production/Repair/RepairTech/RepairTechContainer';
import ApplicationContainer from './components/Sales/Application/ApplicationContainer';
import PersonalModuleContainer from './components/PersonalModule/PersonalModuleContainer';
import SalesPersonalContainer from './components/Sales/SalesPersonal/SalesPersonalContainer';
import DistributionContainer from './components/Sales/Distribution/DistributionContainer';
import SalesCheckContainer from './components/Sales/SalesCheck/SalesCheckContainer';
import ProjectContainer from './components/Sales/Project/ProjectContainer';
import FutureContainer from './components/Tech/Future/FutureContainer';
import FutureContainerDev from './components/Tech/FutureDev/FutureContainer';
import NomenclaturesNameContainer from './components/Tech/FutureDev/NomenclaturesName/NomenclaturesNameContainer';
import AddNewKpContainer from './components/Sales/Kp/AddNewKp/AddNewKpContainer';
import AllCompanyWithManagerContainer from './components/Sales/AllCompanyWithManager/AllCompanyWithManagerContainer';
import AllCompanyOutdatedWithManagerContainer
    from './components/Sales/AllCompanyOutdatedWithManager/AllCompanyOutdatedWithManagerContainer';
import AllSharedCompanyWithManagerContainer
    from './components/Sales/AllSharedCompanyWithManager/AllSharedCompanyWithManagerContainer';
import ChannelChainsContainer from './components/Tech/FutureDev/ChannelChains/ChannelChainsContainer';
import MeetingContainer from './components/Sales/Meeting/MeetingContainer';
import SalesCheckArchiveContainer from './components/Sales/SalesCheck/SalesCheckArchive/SalesCheckArchiveContainer';
import SalesPersonalStatisticsContainer
    from './components/Sales/SalesPersonalStatistics/SalesPersonalStatisticsContainer';
import ManagerSalaryContainer from "./components/Sales/ManagerSalaryMonthly/ManagerSalaryContainer";
import CompanyProfileContainer from "./components/Sales/Company/Profile/CompanyProfileContainer";
import {ToastContainer} from "react-toastify";
import AccessoriesListContainer from './components/Production/Accessories/AccessoriesList/AccessoriesListContainer';
import Cookies from "js-cookie";
import ProfilePage from "./components/Profile/ProfilePage";
import DynamicChartBuilder from "./components/DynamicCharts/DynamicChartBuilder";
import AdminSideMenu from "./components/Admin/AdminSideMenu";
import SalesMapCompanies from "./components/Sales/Company/Map/SalesMapCompanies";
import WarehouseDashboard from "./components/Admin/Dashboards/WarehouseDashboard";
import SalesFunnel from "./components/Admin/Dashboards/SalesFunnel";
import UnhandledCompanies from "./components/Sales/Company/UnhandledCompanies/UnhandledCompaniesContainer";
import AllCompanyWithManagerNew from "./components/Sales/AllCompanyWithManagerNew/AllCompanyWithManager";
import TechConsultationList from "./components/Tech/Techconsultation/TechConsultationList";
import FilterCategoriesCompanies
    from "./components/Sales/Company/FilterCategoriesCompanies/FilterCategoriesCompaniesContainer";
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
    //console.log(process.env); // Log all environment variables
    useEffect(() => {
        document.title = 'АКОН - Админпанель';
    },[]);
    // Check if the user is logged in
    if (!Cookies.get('token') || !Cookies.get('id')){
        Cookies.remove('token');
        Cookies.remove('id');
        Cookies.remove('admin_id');

        return (
            <>
                <Login/>
                <ToastContainer/>
            </>
        );
    }

    const isAdmin = () => {
        return Cookies.get('adminID') !== undefined
            && Cookies.get('adminID') !== null
            && Cookies.get('adminID') !== ''
            && ([1,34,44].includes(parseInt(Cookies.get('adminID'))));
    }


        return (
        <BrowserRouter
            basename='/'
        >
            {/*process.env.REACT_APP_HOME_URL*/}
            <div className="App">
                <Header
                    isAdmin={isAdmin()}
                />
                {<AdminSideMenu isAdmin={isAdmin()}/>}

                <Route path="/login" render={() => <Login/>}/>
{/*IDsecured*/} <Route path="/profile/:userID" render={() => <ProfilePage/>}/>

                <Route path="/check" exact render={() => <ProductionContainer/>}/>
                {/* <Route path="/checkPrint/:idCheck/:typeCheck" exact render={() => <PrintVersionContainer/>}/> */}
                <Route path="/statisticsModuls" exact render={() => <StatisticsMenu/>}/>
                <Route path="/statisticsModuls/averageValue" render={() => <CheckValueContainer/>}/>
                {/* <Route path="/production" render={() => <ProductionMainContainer/>}/> */}
                 <Route path="/check-t-production" render={() => <CheckTestContainerProduction/>}/>
                <Route path="/check/:idCheck/:typeCheck" render={() => <CheckContainer/>}/>
                <Route path="/salesCheck/" render={() => <SalesCheckContainer/>}/>
                <Route path="/salesCheckArchive" render={() => <SalesCheckArchiveContainer/>}/>
                <Route path="/chief" exact render={() => <ChiefContainer/>}/>
{/*IDsecured*/} <Route path="/task/:personalId" exact render={() => <TaskContainerAll/>}/>
                <Route path="/chief/statistics/:year/:month" exact render={() => <ShipmentContainerChief/>}/>
                <Route path="/engineer/:workerId?" render={() => <EngineerContainer/>}/>
                <Route path="/techEngineer" render={() => <TechEngineerContainer/>}/>
                <Route path="/techconsultation" render={() => <TechConsultationList/>}/>

                <Route path="/tech/tasks" render={() => <TaskContainer/>}/>
                <Route path="/tech/techStorage" render={() => <TechStorageContainer/>}/>
                <Route path="/tech/future" render={() => <FutureContainer/>}/>
                <Route path="/tech/nomenclaturesName" render={() => <NomenclaturesNameContainer/>}/>
                <Route path="/tech/futuredev" exact render={() => <FutureContainerDev/>}/>
                <Route path="/tech/futuredev/channelchains" exact render={() => <ChannelChainsContainer/>}/>
                {/* <Route path="/tech" exact render={() => <Tech/>}/> */}

                <Route path="/storage/:section?" render={() => <StorageContainer/>}/>
                <Route path="/suppliers/:suppliers?" render={() => <SuppliersContainer/>}/>
                {/* <Route path="/modulsWithComponent/:modulsWithComponent?" */}
                       {/* render={() => <ModulesWithComponentContainer />}/> */}
                {/* <Route path="/order" render={() => <OrderComponent/>}/> */}
                <Route path="/add_specifications" exact render={() => <AddNewSpecificationContainer/>}/>
                <Route path="/specifications/:productId?" exact render={() => <SpecificationsContainer/>}/>

                <Route path="/admin" exact render={() => <><Menu/><AdminContainer/></>}/>
                <Route path="/admin/ddc/:year?/:month?" render={() => <><Menu/><DDCContainer/></>}/>
                <Route path="/admin/ddcNew/:year?" render={() => <><Menu/><DDCNewContainer/></>}/>
                <Route path="/admin/costslist/:year/:month" render={() => <><Menu/><CostsListContainer/></>}/>
                <Route path="/admin/moneydel" render={() => <><Menu/><MoneyDeliveryContainer/></>}/>
                <Route path="/admin/specification/:productId?" render={() => <><Menu/><Specifications/></>}/>
                <Route path="/admin/shipment/:month?" render={() => <><Menu/><ShipmentContainer/></>}/>
                <Route path="/admin/receivables" render={() => <><Menu/><ReceivablesMainContainer/></>}/>
                <Route path="/admin/accountsPayable" render={() => <><Menu/><AccountsPayableContainer/></>}/>
                <Route path="/admin/shipmentStatistics/:year"
                       render={() => <><Menu/><ShipmentStatisticsContainer/></>}/>
                <Route path="/admin/plan2022" render={() => <><Menu/><PlanContainer/></>}/>
{/*IDsecured*/} <Route path="/admin/sales/:personalId" render={() => <><Menu/><SalesContainerAdmin/> </>}/>

                <Route path="/wants/:personalId" exact render={() => <FiltrWantsContainer/>}/>
                {/* <Route path="/maps" exact render={() => <MapsContainer/>}/> */}
                {/* <Route path="/company" exact render={() => <CompanyContainer/>}/> */}
                {/*<Route path="/sales" exact render={ () => <SalesContainer /> } />*/}
                <Route path="/sales" exact render={ () => <FilterCategoriesCompanies /> } />
                <Route path="/kp/:idManager" exact render={() => <KpContainer/>}/>

                <Route path="/kp/add/:idManager" exact render={() => <AddNewKpContainer/>}/> {/* Add new / edit KP */}
                <Route path="/kp/add/:idManager/:idKp" exact render={() => <AddNewKpContainer/>}/>
                <Route path="/kp/add/:idManager/:idKp/:isInvoice" exact render={() => <AddNewKpContainer/>}/>

                <Route path="/kpPlan/:idManager" render={() => <KpPlanContainer/>}/>

                {/*<Route path="/sales/:arrayMainSuppliers?" exact render={() => <SalesContainer/>}/>*/}
                <Route path="/sales/personalStatistics/:personalId?" exact
                       render={() => <SalesPersonalStatisticsContainer/>}/>
                <Route path="/sales/mainSupplier" render={() => <MainSupplierContainer/>}/>
                <Route path="/sales/allCompany/:manager" exact render={() => <AllCompanyWithManagerContainer/>}/>
                <Route path="/sales/allCompanyNew/:manager" exact render={() => <AllCompanyWithManagerNew/>}/>
                <Route path="/sales/allCompanyOutdated/:manager" exact
                       render={() => <AllCompanyOutdatedWithManagerContainer/>}/>
                <Route path="/sales/allSharedCompany/:manager" exact
                       render={() => <AllSharedCompanyWithManagerContainer/>}/>
                <Route path="/sales/meeting/:idManager" exact render={() => <MeetingContainer/>}/>
                <Route path="/sales/salary/:idManager/:year?/:month?" exact render={() => <ManagerSalaryContainer/>}/>
                <Route path="/company/profile/:companyID" exact render={() => <CompanyProfileContainer/>}/>
                <Route path="/company/unhandled" exact render={() => <UnhandledCompanies/>}/>
                {/*<Route path="/company/filter-category" exact render={() => <FilterCategoriesCompanies/>}/>*/}
                <Route path="/salesPersonal/:personalId" exact render={() => <SalesPersonalContainer/>}/>

                <Route path="/check-t" render={() => <CheckTestContainer/>}/>

                <Route path="/statistics" render={() => <Statistics/>}/>
                <Route path="/statisticsModules" render={() => <StatisticsModuleContainer/>}/>
                <Route path="/statisticsModelModules" render={() => <StatisticsModelModulesContainer/>}/>
                <Route path="/statisticsBranch" render={() => <StatisticsBranchContainer/>}/>
                <Route path="/cash/:month?" render={() => <><Menu/><CashContainer/></>}/>
                <Route path="/personalAreaSalesCheck/:personalId" render={() => <PersonalAreaSalesContainer/>}/>

                <Route path="/privateOffice/:personalId" render={() => <PrivateOfficeContainer/>}/>

                <Route path="/progProduction" render={() => <ProgrammerContainer/>}/>

                <Route path="/repair" exact render={() => <RepairContainer/>}/>
                <Route path="/repair/tech" exact render={() => <RepairTechContainer/>}/>
                <Route path="/application" exact render={() => <ApplicationContainer/>}/>
                <Route path="/personalModule/:nom" render={() => <PersonalModuleContainer/>}/>
                <Route path="/distribution/:personalId" exact render={() => <DistributionContainer/>}/>
                <Route path="/project" exact render={() => <ProjectContainer/>}/>



                <Route path="/accessories/accessoriesList" exact render={() => <AccessoriesListContainer/>}/>

                {/* Dashboards */}
                <Route path="/dashboards/new" exact render={() => <DynamicChartBuilder/>}/>
                <Route path="/dashboards/warehouse" exact render={() => <WarehouseDashboard/>}/>
                <Route path="/dashboards/sales-funnel" exact render={() => <SalesFunnel/>}/>
                <Route path="/company/map" exact render={() => <SalesMapCompanies/>}/>

                <ToastContainer/>
            </div>

        </BrowserRouter>
    );
}

export default App;
