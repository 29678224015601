import React from 'react';
import s from './Production.module.css';
import Status from '../common/MainTable/Status';
import styleMain from '../common/style.module.css';
import AnyFormatDate from '../common/AnyFormatDate/AnyFormatDate'
import {useState} from 'react';
import Modal from '../common/Modal/Modal';
import AddTTN from './AddTTN';
import {useCookies} from 'react-cookie';
import {NavLink} from 'react-router-dom';
import LongText from "../common/LongText/LongText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import Separator from "../common/Separator/Separator";
import {setSeparatorInBase} from "../../../redux/modulsPaid-reducer";


const Production = (props) => {
    // console.log(props.listProduction)
    // console.log(props.moduls)
    const [cookies, setCookie] = useCookies();
    //функция принимает объёкт с датой и выдаёт дату в формате Г-м-д
    const getDateFormatYmd = (date) => {
        let month = date.getMonth() + 1
        month = "" + month
        if (month.length == 1) month = "0" + month
        let day = date.getDate()
        day = "" + day
        if (day.length == 1) day = "0" + day
        let dateNew = date.getFullYear() + "-" + month + "-" + day
        return dateNew
    }
    let colorDate = (date) => {
        let now = new Date();
        now = getDateFormatYmd(now)
        date = new Date(date)
        date = getDateFormatYmd(date)
        if (date < now) {
            return styleMain.red;
        } else if (date == now) {
            return styleMain.green;
        } else {
            return "";
        }
    }
    //массив для модулей, которые мы отгружаем сегодня
    let now = new Date();
    now = getDateFormatYmd(now)
    let shippingToday = []
    props.listProduction.map(k => {
        if (k.date_manager == now) shippingToday.push(k.number)
    })

    let ahref = (id, type) => {
        return "/check/" + id + "/" + type;
    }
    //сортируем по дате оплаты, для выставления правильного порядка для работы
    let asd = (arr) => {
        return arr.sort((a, b) => a.id > b.id ? 1 : -1).sort((a, b) => a.date_manager > b.date_manager ? 1 : -1);
    }
    //добавляем новую ТТН
    let [TTN, setTTN] = useState(false)
    let [typeCheck, setTypeCheck] = useState(false)
    const setTTNF = (ttn, paid) => {
        setTTN(ttn)
        let type;
        paid ? type = 1 : type = 2
        setTypeCheck(type)
    }

    let [separator, setSeparator] = useState(0)
    let [separatorId, setSeparatorId] = useState(0)

    let redactionColl = (number, id, modulsType) => {
        setSeparator(number)
        setSeparatorId(id)
    }

    return (<>

        {TTN &&
            <Modal active={TTN} setActive={setTTN}>
                <AddTTN active={TTN} setActive={setTTN} setNewTTN={props.setNewTTN} typeCheck={typeCheck}/>
            </Modal>
        }
        {
            separator !== 0 &&
            <Separator number={separator}
                       setSeparator={setSeparator}
                       id={separatorId}
            />
        }

        <div className={s.main +  " " + s.bold + (separator === 0 ? (" " + s.mainSection) : '')}>
            <div className={s.blue}>Сегодня мы отгружаем
                счета: {shippingToday.length > 0 && shippingToday.map(k => k + " ")}
            </div>
            <div className={s.mainModuls}>
                <div>Название - кол.</div>
                <div className={s.main_stage}>
                    <div><NavLink to="/chief">Распределение</NavLink></div>
                    <div><NavLink to="/engineer">Монтаж</NavLink></div>
                    <div><NavLink to="/techEngineer">Первичка</NavLink></div>
                    <div><NavLink to="/engineer">Корпусировка</NavLink></div>
                    <div><NavLink to="/techEngineer">Вых. контроль</NavLink></div>
                    <div>Склад</div>
                </div>
            </div>
        </div>
        {
            asd(props.listProduction).map((p, index) => {
                return (
                    <div className={s.main} style={{verticalAlign: 'middle'}} key={"listProduction_" + index}>
                        <div>
                            <NavLink to={ahref(p.id, p.type_check)} target="_blank">
                                        <span
                                            className={s.bold}>{p.type_check == 0 ? p.number : p.number + " - Т"}</span><br/>
                            </NavLink>

                            <span className={colorDate(p.date_manager)}><AnyFormatDate
                                date={p.date_manager}/><br/></span>
                            <LongText text={p.company} maxLength={15} shawAllText={'...'}/><br/>


                            {p.numeral === p.paid
                                ? <button
                                    onClick={() => setTTNF(p.id, p.paid)} className={s.buttonTTN}
                                >
                                    Добавить ТТН
                                </button>
                                : <span className={styleMain.red}>Нужна доплата</span>
                            }
                        </div>
                        <div className={s.mainModuls}>
                            {

                                props.moduls.sort((a, b) => a.id > b.id ? 1 : -1).map(k => {
                                    if (k.status === 6) return;
                                    if (p.id == k.id_invoice) {
                                        return (
                                            <React.Fragment
                                                key={"moduls_" + k.id + "_" + "_" + k.moduls_type_in_production + "_" + k.status + "_" + p.type_check + '_' + p.paid + '_' + p.numeral + '_' + p.date_manager + '_' + k.date_start_installation}
                                            >
                                                <div className={s.twoBlocks}>
                                                        <span>
                                                            {k.moduls_name}<br/>({k.workerName})
                                                        </span>
                                                    <span onClick={() =>
                                                        k.moduls_coll > 1 ? redactionColl(k.moduls_coll, k.id) : null
                                                    }>{k.moduls_coll} шт
                                                        {k.moduls_coll > 1 ? <FontAwesomeIcon
                                                                icon={faPencilAlt}
                                                            /> : null}
                                                    </span>
                                                </div>
                                                <div className={s.main_stage}>
                                                    <Status status={k.status}
                                                            modulsId={k.id}
                                                            moduls_type_in_production={k.moduls_type_in_production}
                                                            personalId={cookies.id}
                                                            setModulsStatus={props.setModulsStatus}
                                                            setModulsStatusNew={props.setModulsStatusNew}
                                                            moduleInfo={k}
                                                    />
                                                </div>
                                            </React.Fragment>)
                                    }
                                })
                            }
                        </div>
                    </div>)
            })
        }
        {//выводим все модули, которые в производстве, но идут не по счёту
            <div className={s.main}>
                <div>
                    Cклад
                </div>
                <div className={s.mainModuls}>
                    {
                        props.moduls.map(k => {
                            if (k.id_invoice == 0 && ![5, 6].includes(k.status))
                                return (
                                    <React.Fragment
                                        key={"moduls_warehouse_" + k.id + "_" + "_" + k.moduls_type_in_production + "_" + k.status}
                                    >
                                        <div>
                                            <div className={s.twoBlocks}>
                                                <span>
                                                    {k.moduls_name}<br/> (дата монтажа {k.start_date_installation})
</span>
                                                <span onClick={() =>
                                                    k.moduls_coll > 1 ? redactionColl(k.moduls_coll, k.id) : null
                                                }>{k.moduls_coll} шт
                                                    {k.moduls_coll > 1 ? <FontAwesomeIcon
                                                        icon={faPencilAlt}
                                                    /> : null}
                                                    </span>
                                            </div>
                                            <div className={s.main2}>
                                                <div onClick={() => props.changeStorageFromTech(k.id, 1)}>Лабы</div>
                                                <div onClick={() => props.changeStorageFromTech(k.id, 2)}>Программисту
                                                </div>
                                                <div onClick={() => props.changeStorageFromTech(k.id, 3)}>Заготовки
                                                </div>
                                            </div>
                                        </div>
                                        <div className={s.main_stage}>
                                            <Status
                                                status={k.status}
                                                modulsId={k.id}
                                                moduls_type_in_production={k.moduls_type_in_production}
                                                personalId={parseInt(cookies.id)}
                                                setModulsStatus={props.setModulsStatus}
                                                setModulsStatusNew={props.setModulsStatusNew}
                                            /></div>
                                    </React.Fragment>)
                        })
                    }
                </div>
            </div>

        }

    </>);
}
export default Production;
