import React from 'react';
import {  withRouter, Route } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getTasks, setNewTask, addCommentForTask, addNewDateInTask, setNewWorkerInTask, getTasksComment, setTaskWithoutArchive, deleteTask, setDateReminder, changeDateForTask, changeTextTask} from '../../redux/task-reducer';
import {getWorkersListForTasks} from '../../redux/worker-reducer';
import Task from './Task';
import Cookie from "js-cookie";
import Cookies from "js-cookie";

class TaskContainer extends React.Component{
    componentDidMount(){
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.match.params.personalId;
        if(!isAdmin){
            if(Cookies.get('personalId')!=currentPersonalId){
                currentPersonalId = Cookies.get('id');
            }
        }
        this.props.getTasks(currentPersonalId);
        this.props.getWorkersListForTasks();
        this.props.getTasksComment(currentPersonalId);
    }

    render(){
        return <Task tasks={this.props.tasks} setNewTask={this.props.setNewTask} addCommentForTask={this.props.addCommentForTask} workersListTech={this.props.workersList} addNewDateInTask={this.props.addNewDateInTask} setNewWorkerInTask={this.props.setNewWorkerInTask} tasksComment={this.props.tasksComment} getTasks={this.props.getTasks} setTaskWithoutArchive={this.props.setTaskWithoutArchive} personalId={this.props.match.params.personalId} deleteTask={this.props.deleteTask} setDateReminder={this.props.setDateReminder} changeDateForTask={this.props.changeDateForTask} changeTextTask={this.props.changeTextTask}/>
    } 
}

let mapStateToProps = (state) =>({
    tasks: state.techTask.tasks,
    tasksComment: state.techTask.tasksComment,
    workersList: state.worker.workersList,
})
export default compose( connect( mapStateToProps, {getTasks, setNewTask, addCommentForTask, getWorkersListForTasks, addNewDateInTask, setNewWorkerInTask, getTasksComment, setTaskWithoutArchive, deleteTask, setDateReminder, changeDateForTask, changeTextTask}), withRouter )(TaskContainer);