import React from 'react';
import {  withRouter, Route } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getSentInvoicesForMonth, getSumSentInvoicesForMonth, getSumSentInvoicesForYear, getSentInvoiceForMonthWithDateManager} from '../../../redux/shipment-reducer';
import Shipment from './Shipment';
import MonthBlock from '../../Production/common/MonthBlock/MonthBlock';

class ShipmentContainer extends React.Component{
    
    componentDidMount(){
        this.props.getSentInvoicesForMonth(this.props.match.params.month)
        this.props.getSumSentInvoicesForMonth(this.props.match.params.month)
        this.props.getSumSentInvoicesForYear()
        this.props.getSentInvoiceForMonthWithDateManager(this.props.match.params.month)
    }
    componentDidUpdate(prevProps){
        if(prevProps.match.params.month !== this.props.match.params.month){
            this.props.getSentInvoicesForMonth(this.props.match.params.month)
            this.props.getSumSentInvoicesForMonth(this.props.match.params.month)
            this.props.getSentInvoiceForMonthWithDateManager(this.props.match.params.month)
        }
    }
    
    render(){
        return (
        <div>
            <MonthBlock path={"admin/shipment"} month={this.props.match.params.month} />
            <Shipment sentInvoicesForMonth={this.props.sentInvoicesForMonth} sumSentInvoicesForMonth={this.props.sumSentInvoicesForMonth} sumSentInvoicesForYear={this.props.sumSentInvoicesForYear} invoiceForMonthWithDateManager={this.props.invoiceForMonthWithDateManager}/>
        </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    sentInvoicesForMonth: state.shipment.sentInvoicesForMonth,
    sumSentInvoicesForMonth: state.shipment.sumSentInvoicesForMonth,
    sumSentInvoicesForYear: state.shipment.sumSentInvoicesForYear,
    invoiceForMonthWithDateManager: state.shipment.invoiceForMonthWithDateManager,
})
export default compose( connect( mapStateToProps, {getSentInvoicesForMonth, getSumSentInvoicesForMonth, getSumSentInvoicesForYear, getSentInvoiceForMonthWithDateManager}), withRouter )(ShipmentContainer);