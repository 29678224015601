import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getSumSubentriesGrupMonth} from '../../../redux/admin-reducer';
import {getAllArticles} from '../../../redux/cash-reducer';
import DDCNew from './DDCNew';

class DDCNewContainer extends React.Component{
    componentDidMount(){
        this.props.getSumSubentriesGrupMonth(this.props.match.params.year);
        this.props.getAllArticles();
    }
    
    render(){
        return (
            <div>
                <DDCNew {...this.props}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    sumSubentriesGrupMonth: state.admin.sumSubentriesGrupMonth,
    articles: state.cash.articles,
    
})
export default compose( connect( mapStateToProps, {getSumSubentriesGrupMonth, getAllArticles}), withRouter )(DDCNewContainer);