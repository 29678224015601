import {specificationAPI} from "../api/api";
import {toast} from "react-toastify";

const SET_SPECIFICATION_LIST = "SET_SPECIFICATION_LIST";
const SET_NOMENCLATURES_DATA = "SET_NOMENCLATURES_DATA";
const SET_ALL_NOMENCLATURES = "SET_ALL_NOMENCLATURES";
const SET_NEW_SPECIFICATION_ACTION = "SET_NEW_SPECIFICATION_ACTION";
const SET_SPECIFICATIONS_ELEMENTS = "SET_SPECIFICATIONS_ELEMENTS";
const SET_VERSION_SPECIFICATIONS = "SET_VERSION_SPECIFICATIONS";


let initialState = {
    specificationList: [],
    nomenclaturesData: [],
    allNomenclatures: [],
    isFetching: false,
    specificationElements: [],
    versionSpecification: [],
}

const specificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SPECIFICATION_LIST:
            return {
                ...state,
                specificationList: action.specificationList
            }
        case SET_NOMENCLATURES_DATA:
            return {
                ...state,
                nomenclaturesData: action.nomenclaturesData
            }
        case SET_ALL_NOMENCLATURES:
            return {
                ...state,
                allNomenclatures: action.allNomenclatures
            }
        case SET_NEW_SPECIFICATION_ACTION:
            return {
                ...state,
                isFetching: true
            }
        case SET_SPECIFICATIONS_ELEMENTS:
            return {
                ...state,
                specificationElements: action.specificationElements
            }
        case SET_VERSION_SPECIFICATIONS:
            return {
                ...state,
                versionSpecification: action.versionSpecification
            }
        default:
            return state
    }
}

export const setSpecificationList = (specificationList) => ({type: SET_SPECIFICATION_LIST, specificationList});
export const setNomenclaturesData = (nomenclaturesData) => ({type: SET_NOMENCLATURES_DATA, nomenclaturesData});
export const setAllNomenclatures = (allNomenclatures) => ({type: SET_ALL_NOMENCLATURES, allNomenclatures});
export const setNewSpecificationAction = (isFetching) => ({type: SET_NEW_SPECIFICATION_ACTION, isFetching});
export const setSpecificationsElements = (specificationElements) => ({
    type: SET_SPECIFICATIONS_ELEMENTS,
    specificationElements
});
export const setVersionSpecifications = (versionSpecification) => ({
    type: SET_VERSION_SPECIFICATIONS,
    versionSpecification
});

export const getSpecificationList = (id) => {
    return (dispatch) => {
        specificationAPI.getSpecificationList(id)
            .then(data => {
                dispatch(setSpecificationList(data));
            });
    }
}
export const getNomenclaturesData = (id) => {
    return (dispatch) => {
        specificationAPI.getNomenclaturesData(id)
            .then(data => {
                dispatch(setNomenclaturesData(data[0]));
            });
    }
}
export const getALLNomenclatures = () => {
    return (dispatch) => {
        specificationAPI.getALLNomenclatures()
            .then(data => {
                dispatch(setAllNomenclatures(data));
            });
    }
}


export const setNewSpecification = (array) => {
    return async (dispatch) => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await specificationAPI.setNewSpecification(array);
                dispatch(setNewSpecificationAction(true));
                toast.success("Спецификация успешно создана")
                resolve(data);
            }catch (error) {
                console.error("Error:", error);
                toast.error("Ошибка при создании спецификации")
                reject(error);
            }
        });
    }
}
export const getSpecificationsElements = (numberSpecification, currentVersion) => {
    return async (dispatch) => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await specificationAPI.getSpecificationsElements(numberSpecification, currentVersion ?? 0);
                dispatch(setSpecificationsElements(data));
                toast.success("Данные успешно получены")
                resolve(data);
            } catch (error) {
                console.error("Error:", error);
                toast.error("Ошибка при получении данных")
                reject(error);
            }
        });
    };
};
export const changeSpecificationProps = (newProps) => {
    return async (dispatch) => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await specificationAPI.changeSpecificationProps(newProps);
                //dispatch(setSpecificationsElements(data));
                toast.success("Данные успешно получены")
                resolve(data);
            } catch (error) {
                console.error("Error:", error);
                toast.error("Ошибка при получении данных")
                reject(error);
            }
        });
    };
};

export const uploadXLSXFile = (requestData) => {
    return async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await specificationAPI.uploadXLSXFile(requestData);
                toast.success("Файл распознан")
                resolve(data);
            } catch (error) {
                console.error("Error:", error);
                toast.error("Ошибка при парсинге файла")
                reject(error);
            }
        });
    };
};

export const getVersionSpecifications = (numberSpecification) => {
    return (dispatch) => {
        specificationAPI.getVersionSpecifications(numberSpecification)
            .then(data => {
                dispatch(setVersionSpecifications(data));
            });
    }
}


export default specificationReducer;

