import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getAllApplication} from '../../../redux/future-reducer';
import Application from './Application';

class ApplicationContainer extends React.Component{
    componentDidMount(){
        this.props.getAllApplication();
    }
    componentDidUpdate(prevProps){
    }
    
    render(){
        return (
            <div>
                <Application allApplication={this.props.allApplication}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    allApplication: state.future.allApplication
})
export default compose( connect( mapStateToProps, {getAllApplication}), withRouter )(ApplicationContainer);