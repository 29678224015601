import React, { useState } from 'react';
import s from './SalesCheck.module.css';
import main from '../../Main.module.css';
import AnyFormatDate from "../../Production/common/AnyFormatDate/AnyFormatDate"
import Modal from '../../Production/common/Modal/Modal';
import { useCookies } from 'react-cookie';
import AllComment from './Modal/AllComment';
import AddComment from './Modal/AddComment';
import { faComment} from "@fortawesome/free-regular-svg-icons";
import { faComment as faCommentF}  from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom';
import AreYouSure from './Modal/AreYouSure';
import CurrencyFormatter from "../common/CurrencyFormatter/CurrencyFormatter";

const SalesCheck = (props) =>{
    console.log(props.salesCheck)
    let [showBuh, setShowBuh] = useState(false)
    let [manager, setManager] = useState(false)
    let [text, setText] = useState(false)
    let subject = "Повторна видаткова накладна АКОН-ГРУП";
    const [cookies, setCookie] = useCookies();
    let idCookies = cookies.id
    //добавляем комментарии
    let [allComment, setAllComment] = useState(false)
    let [addComment, setAddComment] = useState(false)
    //фильтр для вывода расходным по определённому менеджеру
    let [filter, setFilter] = useState(0)
    //спрашиваем проводить ли расходку
    let [sure, setSure] = useState(false)
    let [name, setName] = useState(false)
    const setSureF=(id, name)=>{
        setName(name)
        setSure(id)
    }
    return(
        <div>
            <div className={s.mainFilter}>
                <div onClick={()=>setFilter(0)}>Все</div>
                <div onClick={()=>setFilter(1)}>Дунаев Алексей</div>
                <div onClick={()=>setFilter(2)}>Осадчий Олег</div>
                <div onClick={()=>setFilter(1055)}>Мартиненко Євгеній</div>
                <div onClick={()=>setFilter(1056)}>Кучер Євген</div>
                <div onClick={()=>setFilter(1061)}>Сталин Дмитрий</div>
            </div>
            {(idCookies == 897 || idCookies == 44 || idCookies == 34 || idCookies == 1) && <div className={s.archive}><NavLink to="/salesCheckArchive">Aрхив</NavLink></div>}
            <h1 className={main.center}>Список счетов по которым не приехали расходные</h1>
            <div className={s.info}>
                <p>После отправки модулей заказчику, счёт высвечивается на этой странице.</p>
                <ul>
                    <li>В ячейке "Продажи" высвечивается дата до которой мы ожидаем расходную.</li>
                    <li>Если оригинал расходной пришёл - главный бухгалтер нажимает на кнопку "Получили"</li>
                    <li>Если в ячейке "Продажи" кнопка "Написал заказчику" значит время ожидания вышло и менеджер пишет заказчику повторный запрос.</li>
                    <li>После отправки запроса заказчику, менеджер нажимает на кнопку "Написал заказчику" и дата с напоминанием переносится на 7 дней</li>
                    <li><a href={"mailto:?subject="+subject+"&bcc=booker@akon.com.ua"} className={s.greenText}>Ссылка на письмо с нужными параметрами</a>. Не забудьте вставить xlsx файл с расходной накладной и текст ниже!!!</li>
                    <li>Вы можете скопировать текст <span onClick={()=>setText(true)} className={s.greenText}>отсюда</span>.</li>
                </ul>
            </div>
            <div className={s.main+" "+s.header}>
                <div>Номер счёта</div>
                <div>Ответственный</div>
                <div>Сумма</div>
                <div>Дата отгрузки</div>
                <div>Бухгалтерия <span onClick={()=>setShowBuh(true)}>i</span></div>
                <div>Продажи <span onClick={()=>setManager(true)}>i</span></div>
                <div>Коммент</div>
            </div>
            {props.salesCheck?.invoices?.map((k,index)=>{
                // Filter records based on the current company ID
                const currentCompanyId = k?.company_id; // Replace with your actual current company ID
                const filteredRecords = props.salesCheck?.bindings?.filter(record => {
                    return record.main_company === currentCompanyId;
                });
               
                return(
                    (filter==0 || filter==k.whose_company) &&
                    <div key={"salesCheck"+index} className={s.main}>
                        <div>
                            <NavLink to={"/check/"+k.check_id+"/0"} target="_blank">
                                {k.name}
                            </NavLink>
                            {filteredRecords.map((obj, i) => (
                                //console.log(name,i)
                                <div key={`companyName${i}`}>{(obj.name_with_akon ?? obj?.name ?? '').toLowerCase()}</div>
                            ))}
                            {k.manager_status!=null && <p className={s.m0+" "+s.greenText}>счёт в работе</p>}
                        </div>
                        <div className={main.center}>
                            {k.whose_company==1 ? "Дунаев Алексей" : ""}
                            {k.whose_company==2 ? "Осадчий Олег" : ""}
                            {k.whose_company==1055 ? "Мартиненко Євгеній" : ""}
                            {k.whose_company==1056 ? "Кучер Євген" : ""}
                            {k.whose_company==1061 ? "Сталин Дмитрий" : ""}
                        </div>
                        <div className={main.center}><CurrencyFormatter amount={k.numeral} withSpaces={true} onlyHRN={false} /> </div>
                        <div className={main.center}><AnyFormatDate date={k.date_delivery} /></div>
                        <div className={main.center}>
                            {idCookies != 897 && <button>Нет доступа</button>}
                            {idCookies == 897 && <button onClick={()=>setSureF(k.id, k.name)}>Получили</button>}
                        </div>
                        
                        <div className={main.center}>{k.mangerDateStatus ? <button onClick={()=>props.setDateInSalesCheck(k.id, 2)}>Написал заказчику</button> : <AnyFormatDate date={k.manager_date} />}</div>
                        <div className={s.fs1_5rem}><span className={s.greenText} onClick={()=>setAllComment(k.check_id)}><FontAwesomeIcon icon={k.countComment=="0" ? faComment : faCommentF}  /></span><span className={s.commentCount}>{k.countComment}</span></div>
                    </div> 
                )
            })}
            {showBuh &&
                <Modal active={showBuh} setActive={setShowBuh}>
                    <div><p>Когда приходит оригинал расходной, главный бухгалтер отмечает счёт, нажатием на кнопку "Получили".</p></div>
                </Modal>
            }
            {manager &&
                <Modal active={manager} setActive={setManager}>
                    <div>
                        <p>После нажатия на кнопку "Написал заказчику", дата оповещения переносится на 7 дней</p>
                    </div>
                </Modal>
            }
            {text &&
                <Modal active={text} setActive={setText}>
                    <div>
                        <p>Доброго дня.</p>
                        <p>На жаль ми не отримали оригінал видаткової накладної.</p>
                        <p>У вкладенні ексель файл для друку.</p>
                        <p>Просимо відправити підписану видаткову на адресу: 04201, м. Київ, вул. Полярна 5-А, кв. 164</p>
                        <p>Заздалегідь дякуємо!</p>
                    </div>
                </Modal>
            }
            {sure &&
                <Modal active={sure} setActive={setSure}>
                    <AreYouSure setDateInSalesCheck={props.setDateInSalesCheck} id={sure} setActive={setSure} name={name}/>
                </Modal>

            }
            {allComment && <Modal active={allComment} setActive={setAllComment}><AllComment id={allComment} setAllComment={setAllComment} salesInvoceComment={props.salesInvoceComment} setAddComment={setAddComment} workersList={props.workersList} deleteCommentForSalesCheck={props.deleteCommentForSalesCheck}/></Modal>}
            {addComment && <Modal active={addComment} setActive={setAddComment}><AddComment id={addComment} setAllComment={setAllComment} addCommentForSalesCheck={props.addCommentForSalesCheck} setAddComment={setAddComment}/></Modal>}
        </div>
        
    )
}
export default SalesCheck;