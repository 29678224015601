import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getAllActivity, addNewNomenclatures} from '../../../redux/salesPersonal-reducer';
import SalesPersonal from './SalesPersonal';
import Cookies from 'js-cookie'

class SalesPersonalContainer extends React.Component{
    componentDidMount(){
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.match.params.personalId;
        if(!isAdmin){
            if(Cookies.get('id')!=currentPersonalId){
                currentPersonalId = Cookies.get('id');
            }
        }
        this.props.getAllActivity(currentPersonalId)
    }
    render(){
        return (
            <div>
                <SalesPersonal allActivity={this.props.allActivity} getAllActivity={this.props.getAllActivity} personalId={this.props.match.params.personalId} addNewNomenclatures={this.props.addNewNomenclatures} statusNomenclatures={this.props.statusNomenclatures} lastIdCompany={this.props.lastIdCompany}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    allActivity: state.salesPersonal.allActivity,
    statusNomenclatures: state.salesPersonal.statusNomenclatures,
    lastIdCompany: state.company.lastIdCompany,
})
export default compose( connect( mapStateToProps, {getAllActivity, addNewNomenclatures}), withRouter )(SalesPersonalContainer);