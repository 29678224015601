import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getAllMeetingWithManager, addNewMeeting, changeMeetingText, deleteMeeting, сloseMeeting, changeDateReminderKp} from '../../../redux/sales-reducer';
import {getAllAgents} from '../../../redux/company-reducer';
import {getCompanyList} from '../../../redux/other-reducer';
import Meeting from './Meeting';
import Cookies from 'js-cookie'

class MeetingContainer extends React.Component{
    componentDidMount(){
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.match.params.idManager;
        if(!isAdmin){
            if(Cookies.get('id')!=currentPersonalId){
                currentPersonalId = Cookies.get('id');
            }
        }this.props.getAllMeetingWithManager(currentPersonalId);
        this.props.getAllAgents();
        this.props.getCompanyList();
    }

    render(){
        return (
            <div>
                <Meeting {...this.props} />
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    allMeetingWithManager: state.sales.allMeetingWithManager,
    allAgents: state.company.allAgents,
    companyList: state.other.companyList,
})
export default compose( connect( mapStateToProps, {getAllMeetingWithManager, getAllAgents, getCompanyList, addNewMeeting, changeMeetingText, deleteMeeting, сloseMeeting, changeDateReminderKp}), withRouter )(MeetingContainer);