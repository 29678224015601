import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {getNomenclaturesData} from '../../../../redux/specification-reducer';
import DescriptionPersonalModule from './DescriptionPersonalModule';

class DescriptionPersonalModuleContainer extends React.Component{
    componentDidMount(){
        this.props.getNomenclaturesData(this.props.productId);
    }
    componentDidUpdate(prevProps){
        if(prevProps.productId !== this.props.productId){
            this.props.getNomenclaturesData(this.props.productId);
        }
    }
    render(){
        return <DescriptionPersonalModule nomenclaturesData={this.props.nomenclaturesData} productId={this.props.productId}/>
    } 
}

let mapStateToProps = (state) =>({
    nomenclaturesData: state.specification.nomenclaturesData,
})
export default compose(connect( mapStateToProps, {getNomenclaturesData}), withRouter)(DescriptionPersonalModuleContainer);