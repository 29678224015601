import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import s from '../Task.module.css';

const AddComment = (props) =>{
    
    const [cookies, setCookie] = useCookies();
    let [text, setText] = useState("")
    const setTextF=(e)=>{
        setText(e.target.value)
    }
    const AddComment=()=>{
        let array = [];
        array.push(props.tasksId, text, worker)
        props.addCommentForTask(array, cookies.id)
        props.setShowAddComment(false)
    }
    let worker = props.workerList.find(k=>Number(k.login_id)==Number(cookies.id)).id
    return(
        <div className={s.addComment}>
           <div><h3>Добавление комментария к записи №{props.tasksId}</h3></div>
           <div><textarea rows="10" onChange={setTextF}>{text}</textarea></div>
           <div className={s.center}><button onClick={()=>AddComment()}>Добавить</button></div>
        </div>
    )
}
export default withRouter(AddComment);