import React from 'react';
import {  withRouter, Route } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getListWithCompanyUnpin} from '../../../redux/salesAdmin-reducer';
import Sales from './Sales';
import Cookies from "js-cookie";

class SalesContainer extends React.Component{
    
    componentDidMount(){
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.match.params.personalId;
        if(!isAdmin){
            window.location.href = "/privateOffice/"+Cookies.get('personalId');
            if(Cookies.get('personalId')!=currentPersonalId){
                currentPersonalId = Cookies.get('id');
            }
        }
        this.props.getListWithCompanyUnpin()
    }
    
    render(){
        return <Sales listWithCompanyUnpin={this.props.listWithCompanyUnpin}/>
    } 
}

let mapStateToProps = (state) =>({
    listWithCompanyUnpin: state.salesAdmin.listWithCompanyUnpin
})
export default compose( connect( mapStateToProps, {getListWithCompanyUnpin}), withRouter )(SalesContainer);