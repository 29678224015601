import React, {useEffect, useMemo} from 'react';
import {useCookies} from 'react-cookie';
import s from './Check.module.css';
import AnyFormatDate from '../common/AnyFormatDate/AnyFormatDate'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCalendarDay,
    faEnvelope,
    faTruck,
    faCode,
    faPencilAlt,
    faPrint,
    faCalendarPlus,
    faComment,
    faMap,
    faFilter,
    faBars, faFolder
} from '@fortawesome/free-solid-svg-icons'
import {useState} from 'react';
import Modal from '../common/Modal/Modal';
import {NavLink} from 'react-router-dom';
import Settings from './Settings/Settings';
import MessagesCheckContainer from './Messages/MessagesCheckContainer';
import BindRepairToCheckContainer from '../common/BindRepairToCheck/BindRepairToCheckContainer';
import CurrencyFormatter from "../../Sales/common/CurrencyFormatter/CurrencyFormatter";
import Cookies from "js-cookie";
import {AttachMoney, Cancel, Visibility} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import main from "../../Main.module.css";
import Select from "react-select";
import {InputTextarea} from "primereact/inputtextarea";
import {toast} from "react-toastify";
import {companyAPI} from "../../../api/api";
import {Autocomplete, CardContent, Chip, Grid, MenuItem, OutlinedInput, TextField} from "@mui/material";
import Card from "@mui/joy/Card";
import Typography from "@mui/material/Typography";
import {faEdit, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {Stack} from "@mui/joy";

const Check = (props) => {
    const [cookies, setCookie] = useCookies();
    let access = false;

    const [showSelfCostSelector, setShowSelfCostSelector] = useState(false)

    let canSeeSelfCost = [1, 34, 44].includes(parseInt(Cookies.get('id')));
    if ([1, 34, 44, 486, 1055, 1056, 1061].includes(parseInt(Cookies.get('id')))) access = true

    const getSelfCost = () => {
        let selfCost = 0;
        props.moduls.map((k) => {
            selfCost += k.selfcost_sum
        })
        return selfCost
    }

    const [useCases, setUseCases] = useState([])
    let check = {...props.info}


    useEffect(() => {
        loadUseCases();
    }, [])

    const [usedProducts, setUsedProducts] = useState([]);
    const loadUseCases = async () => {
        try {
            const response = await companyAPI.getUseCases(props.idCheck, props.type);
            if (response?.status === 1) {
                setUseCases(response?.data);
                response?.industries ? setIndustriesList(response?.industries) : setIndustriesList([]);
                response?.products_binding ? setUsedProducts(response?.products_binding) : setUsedProducts([]);
                console.log('response:', response);
                console.log('usedProducts:', usedProducts);
            } else {
                toast.error('Ошибка при загрузке кейсов использования: ' + response?.message);
                setUseCases([]);
            }
        } catch (error) {
            toast.error('Ошибка при загрузке кейсов использования: ' + error);
        }
    };


    //редактируем курс и общую сумму
    let [showUAHmodale, setShowUAHmodale] = useState(false)
    let [course, setCourse] = useState(0)
    const setCourseF = (e) => setCourse(e.target.value)
    let [sumCheck, setSumCheck] = useState(0)
    const setSumCheckF = (e) => setSumCheck(e.target.value)
    const setShowUAHmodaleF = (idCheck, course, sumCheck) => {
        setShowUAHmodale(idCheck)
        setCourse(course)
        setSumCheck(sumCheck)
    }
    const setNewCourseAndSumCheckF = () => {
        let array = [];
        array.push(showUAHmodale)
        array.push(course)
        array.push(sumCheck)
        array.push(props.type)
        props.setNewCourseAndSumCheck(array)
        setShowUAHmodale(false)
    }
    const getType = (type) => {
        switch (type) {
            case "0":
                return "Счёт"
            case "3":
                return "Тест"
            case "4":
                return "КП"
            default:
                return ""
        }
    }
    //меняем количество дней на изготовление продукции
    let [showDaysManager, setShowDaysManager] = useState(false)
    let [daysManager, setDaysManager] = useState(0)
    const setShowDaysManagerF = (checkId, days) => {
        setDaysManager(days)
        setShowDaysManager(checkId)
    }
    const setDaysManagerF = (e) => {
        setDaysManager(e.target.value)
    }
    const setDaysManagerFromCheckF = () => {
        props.setDaysManagerFromCheck(showDaysManager, daysManager, props.type)
        setShowDaysManager(false)
    }
    //меняем плановую дату оплаты
    let [showDatePlan, setShowDatePlan] = useState(false)
    let [planDate, setPlanDate] = useState("")
    const setPlanDateF = (e) => setPlanDate(e.target.value)
    const setPlanDateFromCheckF = () => {
        props.setPlanDateFromCheck(showDatePlan, planDate, props.type)
        setShowDatePlan(false)
    }
    //меняем комментарий
    let [showComment, setShowComment] = useState(false)
    let [comment, setComment] = useState("")
    const setShowCommentF = (id, text) => {
        setShowComment(id)
        setComment(text)
    }
    const setCommentF = (e) => setComment(e.target.value)
    const setCommentForCheckF = () => {
        let array = []
        array.push(showComment)
        array.push(comment)
        array.push(props.type)
        props.setCommentForCheck(showComment, array, props.type)
        setShowComment(false)
    }
    //меняем количество модулей позиции в счёте
    let [collModulsInCheck, setCollModulsInCheck] = useState(false)
    let [changeCollModulsInCheck, setChangeCCollModulsInCheck] = useState(0)
    const setChangeCCollModulsInCheckF = (e) => {
        setChangeCCollModulsInCheck(e.target.value)
    }
    const setCollModulsInCheckF = () => {
        changeCollModulsInCheck !== 0 && props.changeCollModulsInCheck(collModulsInCheck, changeCollModulsInCheck, check.id, props.type)
        setCollModulsInCheck(false)
    }
    //отправляем запрос о статусе КП на заказчика и скрываем возможность повторной отправки
    let [requestForKpStatus, setRequestForKpStatus] = useState(false)
    const setRequestForKpStatusF = (id, type) => {
        setRequestForKpStatus(true)
        props.requestForKpStatus(id, type)
    }
    //настройки к счёту
    let [settings, showSettings] = useState(false)
    //удалить счёт
    let [deleteCheck, setDeleteCheck] = useState(false)
    const deleteCheckF = () => {
        props.deleteCheck(deleteCheck, props.type)
        setDeleteCheck(false)
    }
    //привязываем ремонт к счету
    let [bindRepairToCheck, setBindRepairToCheck] = useState(false)
    const setBindRepairToCheckF = (id) => {
        setBindRepairToCheck(id)
    }

    const [openSendMessageWindow, setOpenSendMessageWindow] = React.useState(false);
    const [selectedEquipment, setSelectedEquipment] = useState([]);

    const equipmentOptions = Array.isArray(props.moduls) ? props.moduls.map(modul => ({
        value: modul.name,
        label: modul.name,
    })) : [];

    const [newDealMessage, setNewDealMessage] = useState('')
    const [newDealMessageAgentVal, setNewCompanyMessageAgentVal] = useState(null)
    const [newDealMessageAgent, setNewDealMessageAgent] = useState(null)
    const setNewDealMessageVal = (e) => {
        setNewDealMessage(e.target.value)
    }
    const setNewCompanyMessageValF = (e) => {
        setNewDealMessageVal(e.target.value)
    }
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const handleFileChange = (files) => {
        setUploadedFiles(files);
    };

    const handleClickOpen = () => {
        getIndustries();

        // Автоматично вибираємо все обладнання з props.moduls
        if (Array.isArray(props.moduls)) {
            const defaultSelectedEquipment = props.moduls.map(modul => ({
                value: modul.name,
                label: modul.name,
            }));
            setSelectedEquipment(defaultSelectedEquipment);
        }

        setOpenSendMessageWindow(true);
    };


    const handleClose = () => {

        setSelectedIndustries([]);
        setOpenSendMessageWindow(false);
    };

    const [newDealMessageAgentArr, setNewDealMessageAgentArr] = useState([])

    //make async api call to save new description
    const [btnPressed, setBtnPressed] = useState(false)
    const addDescription = async () => {
        if (newDealMessage.length === 0 && uploadedFiles.length === 0) {
            toast.error('Введите сообщение или загрузите файл')
            return false;
        }
        if(selectedIndustries.length === 0) {
            toast.error('Выберите отрасль')
            return false;
        }
        if(selectedEquipment.length === 0) {
            toast.error('Выберите оборудование')
            return false;
        }
        setBtnPressed(true)
        const request = new FormData();
        request.append('message', newDealMessage);
        request.append('companyID', check.company_id);
        if (props.type == 0) request.append('invoiceID', check.id);
        if (props.type == 4) request.append('kpmainID', check.id);
        if (selectedIndustries.length > 0) {
            selectedIndustries.forEach((industry) => {
                request.append('industries[]', industry.value);
            });
        }
        if (selectedEquipment.length > 0) {
            selectedEquipment.forEach((equipment) => {
                request.append('equipment[]', equipment.value);
            });
        }

        if (uploadedFiles.length > 0) {
            for (let i = 0; i < uploadedFiles.length; i++) {
                request.append('files[]', uploadedFiles[i]);
            }
        }

        companyAPI.addNewMessageToOrder(request).then((response) => {
            if (response?.status === 1) {
                toast.success('Сообщение успешно добавлено');
                setUseCases(response?.data ?? []);
                setBtnPressed(false);
                setUploadedFiles([]);
                setNewDealMessage('');
                setSelectedIndustries([]);
                setSelectedEquipment([]);
                setOpenSendMessageWindow(false);
            } else {
                toast.error('Ошибка при добавлении сообщения' + response.data.message)
                setBtnPressed(false)
            }

        }).catch((error) => {
            console.log(error)
            toast.error('Ошибка при добавлении сообщения')
            setBtnPressed(false)
        })
    }
    const getAgents = async () => {
        companyAPI.getCompanyAgents(check.company_id).then((response) => {
            console.log(response)
            let agents = response.data.map((agent) => {
                return {
                    value: agent.id,
                    label: agent.name
                }
            })
            setNewDealMessageAgentArr(agents)
        }).catch((error) => {
            console.log(error)
        })
    }
    const [industriesList, setIndustriesList] = useState([])
    const [selectedIndustries, setSelectedIndustries] = useState([])
    const [isIndustriesLoaded, setIsIndustriesLoaded] = useState(false)
    const getIndustries = async () => {
        setIsIndustriesLoaded(false); // Set to false before loading starts
        try {
            const response = await companyAPI.getIndustriesList();
            if (response?.data) {
                let industries = response.data.map((industry) => ({
                    value: industry.id,
                    label: industry.name,
                }));
                setIndustriesList(industries);
            } else {
                setIndustriesList([]);
            }
        } catch (error) {
            console.log(error);
            setIndustriesList([]);
        } finally {
            setIsIndustriesLoaded(true); // Set to true when loading is done
        }
    };


    //mui dialog window for usecases
    const [openUseCases, setOpenUseCases] = React.useState(false);
    const handleClickOpenUseCases = () => {
        getIndustries();
        setOpenUseCases(true);
    };
    const handleCloseUseCases = () => {
        setOpenUseCases(false);
        handleCancelEdit();
        setSelectedIndustries([]);
    };

    const handleEditClick = (useCase) => {
        setEditingUseCase(useCase.id);
        setEditingText(useCase.description);

        // Перевіряємо, чи industriesList є масивом
        const validIndustriesList = Array.isArray(industriesList) ? industriesList : [];

        setSelectedIndustries(
            useCase.industry_ids
                ? useCase.industry_ids.split(',').map((id) => {
                    const industry = validIndustriesList.find((ind) => ind.value == id);
                    return industry ? { value: industry.value, label: industry.label } : null;
                }).filter(Boolean) // Фільтруємо null значення, якщо не знайдено галузь
                : []
        );

        setSelectedEquipment(
            usedProducts
                .filter((item) => item.order_description_id === useCase.id)
                .map((product) => ({ value: product.product_id, label: product.product_name }))
        );
    };


    const [editingUseCase, setEditingUseCase] = useState(null);
    const [editingText, setEditingText] = useState('');

    const handleSaveEdit = async () => {
        try {
            const response = await companyAPI.updateUseCase({
                descriptionID: editingUseCase,
                description: editingText,
                industries: selectedIndustries.map((industry) => industry.value),
                products: selectedEquipment.map((equipment) => equipment.value)
            });
            if (response?.status === 1) {
                window.location.reload();
                setUseCases(useCases.map(useCase =>
                    useCase.id === editingUseCase
                        ? {
                            ...useCase,
                            description: editingText,
                            industry_ids: selectedIndustries.map(industry => industry.value).join(','), // Оновлення отраслей
                            products: selectedEquipment.map(equipment => equipment.value)  // Оновлення обладнання
                        }
                        : useCase
                ));
                setEditingUseCase(null);  // Скасування редагування після збереження
                setSelectedIndustries([]);  // Очищення вибраних галузей
                setSelectedEquipment([]);  // Очищення вибраного обладнання
                toast.success('Описание успешно обновлено');
            } else {
                toast.error('Ошибка при обновлении описания: ' + response?.message);
            }
        } catch (error) {
            toast.error('Ошибка при обновлении описания: ' + error);
        }
    };


    const handleCancelEdit = () => {
        setEditingUseCase(null);
        setEditingText('');
    };

    const handleDeleteFile = async (fileId) => {
        try {
            const response = await companyAPI.deleteFile({fileID: fileId});
            if (response?.status === 1) {
                setUseCases(prevUseCases =>
                    prevUseCases.map(useCase =>
                        useCase.id === editingUseCase
                            ? {
                                ...useCase,
                                files: useCase.files.map(file =>
                                    file.id === fileId
                                        ? {...file, deleted_at: new Date().toISOString()}
                                        : file
                                )
                            }
                            : useCase)
                );
                toast.success('Файл успешно удалён');
            } else {
                toast.error('Ошибка при удалении файла: ' + response?.message);
            }
        } catch (error) {
            toast.error('Ошибка при удалении файла: ' + error);
        }
    };

    const [openFinalCompanySelector, setOpenFinalCompanySelector] = React.useState(false);
    const handleClickOpenFinalCompanySelector = () => {
        getCompanyList();
        setOpenFinalCompanySelector(true);
    };



    const [companyName, setCompanyName] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null); // Стан для вибраної компанії
    const [inputValue, setInputValue] = useState(''); // Стан для збереження введеного тексту
    const [companyList, setCompanyList] = useState([]);
    const memoizedCompanyList = useMemo(() => companyList, [companyList]);

    const handleCloseFinalCompanySelector = () => {
        setCompanyId(null);
        setOpenFinalCompanySelector(false);
    };
    const getCompanyList = () => {
        if(companyList.length === 0) {
            companyAPI.getCompanyList().then((response) => {
                if (response?.status === 1) {
                    setCompanyList(response?.data);
                } else {
                    toast.error('Ошибка при загрузке списка компаний: ' + response?.message);
                }
            }).catch((error) => {
                toast.error('Ошибка при загрузке списка компаний: ' + error);
            });
        }
    }
    const filteredCompanyList = useMemo(() => {
        return companyList.filter(company =>
            company.name_with_akon.toLowerCase().includes(inputValue.toLowerCase())
        );
    }, [companyList, inputValue]);
    function setCompanyIdF(event) {
        const selectedCompanyName = event.target.value;

        // Знаходимо компанію за її ім'ям
        const selectedCompany = companyList.find(company => company.name_with_akon === selectedCompanyName);

        // Оновлюємо ім'я компанії
        setCompanyName(selectedCompanyName);

        // Якщо компанія знайдена, встановлюємо її ID
        if (selectedCompany) {
            setCompanyId(selectedCompany.id);
        } else {
            setCompanyId(null); // Якщо компанія не знайдена, очищаємо ID
        }
    }


    const saveFinalCompany = async () => {
        if (!companyId) {
            toast.error('Выберите компанию');
            return;
        }

        const response = await companyAPI.setFinalCompanyToKpinvoice({
            companyID: companyId,
            orderID: parseInt(check.id),
            orderType: parseInt(props.type),
        });

        if (response?.status === 1) {
            toast.success('Компания успешно выбрана');
            window.location.reload();
        } else {
            toast.error('Ошибка при выборе компании: ' + response?.message);
        }
    }


    return (
        <div>

            {showUAHmodale &&
                <Modal active={showUAHmodale} setActive={setShowUAHmodale}>
                    <div>
                        <h2 className={s.center}>Изменения в счёте/КП</h2>
                        <div className={s.twoBlocksModal}>
                            <div><h3>Новый курс валют</h3></div>
                            <div><input type="number" value={course} onChange={setCourseF} className={s.inputModal}/>
                            </div>
                            <div><h3>Новая сумма счёта</h3></div>
                            <div><input type="number" value={sumCheck} onChange={setSumCheckF}
                                        className={s.inputModal}/></div>
                        </div>
                        <div className={s.center}><input type="button" value="Изменить" className={s.button}
                                                         onClick={() => setNewCourseAndSumCheckF()}/></div>
                    </div>
                </Modal>
            }
            {showDaysManager &&
                <Modal active={showDaysManager} setActive={setShowDaysManager}>
                    <div>
                        <h2 className={s.center}>Изменение дней на изготовление</h2>
                        <div><input type="number" value={daysManager} onChange={setDaysManagerF}
                                    className={s.inputModal}/></div>
                        <div className={s.center}><input type="button" value="Изменить" className={s.button}
                                                         onClick={() => setDaysManagerFromCheckF()}/></div>
                    </div>
                </Modal>
            }
            {showDatePlan &&
                <Modal active={showDatePlan} setActive={setShowDatePlan}>
                    <div>
                        <h2 className={s.center}>Изменить плановую дату оплаты</h2>
                        <div><input type="date" value={planDate} onChange={setPlanDateF} className={s.inputModal}/>
                        </div>
                        <div className={s.center}><input type="button" value="Изменить" className={s.button}
                                                         onClick={() => setPlanDateFromCheckF()}/></div>
                    </div>
                </Modal>
            }
            {showComment &&
                <Modal active={showComment} setActive={setShowComment}>
                    <div className={s.comment}>
                        <h2 className={s.center}>Добавляем комментарий</h2>
                        <div><textarea onChange={setCommentF} value={comment}></textarea></div>
                        <div className={s.center}><input type="button" value="Добавить" className={s.button}
                                                         onClick={() => setCommentForCheckF()}/></div>
                    </div>
                </Modal>
            }
            {collModulsInCheck &&
                <Modal active={collModulsInCheck} setActive={setCollModulsInCheck}>
                    <div className={s.comment}>
                        <h2 className={s.center}>Новое количество</h2>
                        <div><input type="number" value={changeCollModulsInCheck}
                                    onChange={setChangeCCollModulsInCheckF} className={s.inputModal}/></div>
                        <div className={s.center}><input type="button" value="Изменить" className={s.button}
                                                         onClick={() => setCollModulsInCheckF()}/></div>
                    </div>
                </Modal>
            }
            {settings &&
                <Modal active={settings} setActive={showSettings}>
                    <Settings setActive={showSettings} allProject={props.allProject}/>
                </Modal>
            }
            {deleteCheck &&
                <Modal active={deleteCheck} setActive={setDeleteCheck}>
                    <div className={s.comment}>
                        <h2 className={s.center}>Удаление счёта</h2>
                        <div className={s.center}><input type="button" value="Удалить" className={s.button}
                                                         onClick={() => deleteCheckF()}/></div>
                    </div>
                </Modal>
            }
            {bindRepairToCheck &&
                <Modal active={bindRepairToCheck} setActive={setBindRepairToCheck}>
                    <BindRepairToCheckContainer checkId={bindRepairToCheck} setActive={setBindRepairToCheck}/>
                </Modal>
            }
            {/* {access && <div onClick={()=>showSettings(!settings)} className={s.settings}><FontAwesomeIcon icon={faBars}/></div>} */}

            {/* Привязываем ремонт к счету */
                props.type == 0 &&
                <div onClick={() => setBindRepairToCheckF(check.id)} className={s.repair}>Ремонт</div>
            }
            {access && check.paid === null && props.type == 0 &&
                <div className={s.settings}><span onClick={() => setDeleteCheck(check.id)}>Удалить</span></div>}

            <Dialog open={openUseCases} onClose={handleCloseUseCases} fullWidth maxWidth={false}
                PaperProps={{ style: {
                        minHeight: '50%',
                        maxHeight: '90%',
                    }}}
            >
                <DialogTitle>Референс лист</DialogTitle>
                <DialogContent>
                    {useCases.length > 0 ? (
                        useCases.map((useCase, index) => {
                            let useCaseProducts = usedProducts.filter((item) => item.order_description_id === useCase.id) || [];
                            return (
                                <Grid key={index} container>
                                    <Grid item lg={12} maxWidth>
                                        <Card>
                                            {editingUseCase === useCase.id ? (
                                                <div>
                                                    <div style={{marginTop: '20px', marginBottom: '20px'}}>
                                                    <Select
                                                        value={selectedIndustries}
                                                        onChange={(selected) => setSelectedIndustries(Array.isArray(selected) ? selected : [])}
                                                        options={industriesList}
                                                        isSearchable
                                                        isMulti
                                                        placeholder="Выберите отрасль"
                                                    />
                                                    </div>
                                                    <div style={{marginTop: '20px', marginBottom: '20px'}}>
                                                        <Select
                                                            value={selectedEquipment}
                                                            onChange={(selected) => {
                                                                setSelectedEquipment(Array.isArray(selected) ? selected : []);
                                                            }}
                                                            options={equipmentOptions}
                                                            isSearchable
                                                            isMulti
                                                            placeholder="Выберите оборудование"
                                                        />
                                                    </div>
                                                    <div style={{marginTop: '20px', marginBottom: '20px'}}>
                                                        <InputTextarea
                                                            value={editingText}
                                                            onChange={(e) => setEditingText(e.target.value)}
                                                            rows={6}
                                                            style={{width: '100%', textAlign: 'left'}}
                                                        />
                                                    </div>
                                                    <Button onClick={handleSaveEdit} variant="contained" color="primary">
                                                        Сохранить
                                                    </Button>
                                                    <Button onClick={handleCancelEdit} variant="outlined" style={{ marginLeft: '10px' }}>
                                                        Отмена
                                                    </Button>
                                                </div>
                                            ) : (
                                                <div>
                                                    {useCase.description}
                                                    <FontAwesomeIcon icon={faEdit} onClick={() => handleEditClick(useCase)} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                                                    {useCase.files && useCase.files.length > 0 && (
                                                        <div>
                                                            <Typography variant='h6'component="div" >   Файлы: </Typography>{
                                                            useCase.files.map((file, index) => (
                                                                //список файлів в рядок з іконкою для видалення
                                                                <Stack direction="row" spacing={1} key={index}>
                                                                    <a href={file.file_path} target="_blank" rel="noreferrer">
                                                                        {file.file_name}
                                                                    </a>
                                                                    <Cancel onClick={() => handleDeleteFile(file.id)} />
                                                                </Stack>
                                                            ))}
                                                        </div>
                                                    )}
                                                    {useCase.industry_ids && useCase.industry_ids.length > 0 && Array.isArray(industriesList) && (
                                                        <div>
                                                            Отрасли: {useCase.industry_ids?.split(',').map((industry_id, index) => {
                                                            const industry = industriesList.find(ind => ind.value == industry_id);
                                                            return industry ? <Chip key={index} label={industry.label} /> : null;
                                                        })}
                                                        </div>
                                                    )}


                                                    {useCaseProducts.length > 0 && (
                                                        <div>
                                                            Продукты: {useCaseProducts?.map((product, index) => (
                                                            <Chip key={index} label={product.product_name} />
                                                        ))}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Card>
                                    </Grid>
                                </Grid>
                            );
                        })
                    ) : (
                        <div>Нет данных</div>
                    )}
                </DialogContent>
            </Dialog>
            <Dialog
                open={openSendMessageWindow}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle align='center'>Добавление нового описания проекта</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div>
                            <Select
                                value={selectedIndustries}
                                onChange={(selected) => setSelectedIndustries(Array.isArray(selected) ? selected : [])}
                                options={industriesList ?? []}
                                isSearchable={true}
                                isMulti
                                placeholder="Выбор отраслей"
                                isLoading={!isIndustriesLoaded}  // Shows loading indicator
                                isDisabled={!isIndustriesLoaded} // Disables select until loaded
                            />
                            <div style={{marginTop: '20px', marginBottom: '20px'}}>
                                <Select
                                    value={selectedEquipment}
                                    onChange={(selected) => setSelectedEquipment(Array.isArray(selected) ? selected : [])}
                                    options={equipmentOptions}
                                    isSearchable={true}
                                    isMulti
                                    placeholder="Выбор оборудования"
                                />
                            </div>
                            <InputTextarea
                                autoResize
                                value={newDealMessage}
                                onChange={(e) => setNewDealMessage(e.target.value)}
                                rows={5}
                                className={main.textarea}
                                style={{marginTop: '20px'}}
                            />
                        </div>

                        <div style={{marginTop: '20px'}}>
                            <input
                                type="file"
                                multiple={true}
                                onChange={(e) => handleFileChange(e.target.files)}
                            />
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={addDescription} disabled={btnPressed}>Сохранить</Button>
                </DialogActions>
            </Dialog>

            <div
                className={(showSelfCostSelector && canSeeSelfCost && getSelfCost() > 0) ? s.grid_9_3 : s.grid_9_3}>
                <div>

                    <h2 className={s.center}>
                        {getType(props.type)} №{check.number} -
                        компания <NavLink
                        to={"/company/profile/" + check.company_id}
                        target="_blank"
                    >
                        {check.name_with_akon}
                    </NavLink> (конечная компания: {check?.final_company_name ? <NavLink to={'/company/profile/'+check?.company_final_from_kp_invoice_company_id} target='_blank'>{check?.final_company_name}</NavLink> : <Button onClick={handleClickOpenFinalCompanySelector}>Назначить</Button>})
                        {check.paid === null ?
                            <span className={s.print}>
                            <NavLink to={"/kp/add/" + cookies.id + '/' + check.id + (props.type == 0 ? '/true' : '' )}>
                                <FontAwesomeIcon icon={faPencilAlt}/>
                            </NavLink>
                        </span>
                            : null}
                    </h2>
                </div>

                <Dialog
                    open={openFinalCompanySelector}
                    onClose={handleCloseFinalCompanySelector}
                    PaperProps={{ style: {
                            minHeight: '30%',
                            maxHeight: '90%',
                        }}}
                >
                    <DialogTitle>Выбор компании, которая в результате будет использовать модуль</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Дополнительная привязка {getType(props.type)} №{check.number} к компании:
                        </DialogContentText>
                        <div>
                            <input
                                type="text"
                                list="company"
                                value={companyName}
                                placeholder="Не выбрано"
                                onChange={(event) => setCompanyIdF(event)}
                                className={main.input}
                            />
                            <datalist id="company">
                                {companyList.map((company) => (
                                    <option
                                        key={"company" + company.id}
                                        value={company.name_with_akon}
                                        data-id={company.id}
                                    >
                                        {company.name_with_akon}
                                    </option>
                                ))}
                            </datalist>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseFinalCompanySelector}>Отмена</Button>
                        <Button onClick={saveFinalCompany} disabled={companyId === null}>Сохранить</Button>
                    </DialogActions>
                </Dialog>

                {/* {props.type=="4" &&
                    <div className={s.mainThree}>
                        <div onClick={()=>props.setDontShowKp(check.id, null, props.type)} className={check.showKp==null ? s.active+" "+s.showKp+" "+s.bgGreen : s.showKp+" "+s.bgGreen}>Aктуально</div>
                        <div onClick={()=>props.setDontShowKp(check.id, 2, props.type)} className={check.showKp==2 ? s.active+" "+s.showKp+" "+s.bgYellow : s.showKp+" "+s.bgYellow}>Уточнение</div>
                        <div onClick={()=>props.setDontShowKp(check.id, 1, props.type)} className={check.showKp==1 ? s.active+" "+s.showKp+" "+s.bgRed : s.showKp+" "+s.bgRed}>Не актуально</div>
                    </div>
                } */}
            </div>

            <div>
                <div
                    className={(showSelfCostSelector && canSeeSelfCost ? s.mainThreeBlocks : s.mainTwoBlocks) + " " + s.bold}>
                    <div>Название модуля</div>
                    <div>Количество</div>
                    {showSelfCostSelector && canSeeSelfCost &&
                        <div>Себестоимость</div>
                    }
                </div>
                {props.moduls?.map((k, index) => {
                    return (
                        <div key={"allModulesFromCheck_" + index}
                             className={showSelfCostSelector && canSeeSelfCost ? s.mainThreeBlocks : s.mainTwoBlocks}>

                            <div>{showSelfCostSelector && canSeeSelfCost && (index + 1 + ". ")}
                                <NavLink to={"/personalModule/" + k.nomenclatures_id}
                                         target="_blank">{k.name}</NavLink> {k.newDevice === "0" &&
                                    <span className={s.red}>- Это новое изделие</span>}
                            </div>
                            <div>{k.coll} {/*check.paid === null &&
                                <span className={s.pensil} onClick={() => {
                                    //console.log(k)
                                    setCollModulsInCheck(k.id)
                                }}><FontAwesomeIcon
                                    icon={faPencilAlt}/></span>*/}
                                {showSelfCostSelector && canSeeSelfCost &&
                                k.selfcost_sum > 0 ?
                                    <>
                                        <i>
                                            <span
                                                color={parseInt(k.count_selfcost) === k.coll ? 'green' : 'yellow'}
                                            >
                                                / {k.count_selfcost} ({(parseInt(k.count_selfcost) / k.coll * 100).toFixed(2)}%)
                                            </span>

                                        </i>
                                    </>
                                    : null
                                }
                            </div>
                            {showSelfCostSelector && canSeeSelfCost
                                ? parseFloat(k.selfcost_sum) > 0 ?
                                    (parseInt(k.selfcost_sum) > 0 &&
                                        <div>
                                            <CurrencyFormatter
                                                amount={k.selfcost_sum ?? 0}
                                                withSpaces={true}
                                            />
                                            <i>  <span
                                                style={{color: parseInt(k.count_selfcost) === parseInt(k.coll) ? 'green' : 'red'}}
                                            >

                                                {parseInt(k.count_selfcost) < k.coll &&

                                                    <>
                                                        <CurrencyFormatter
                                                            amount={(k.selfcost_sum / parseInt(k.count_selfcost) * parseInt(k.coll)).toFixed(2)}
                                                            withSpaces={true}
                                                        /> грн
                                                    </>
                                                }
                                                </span>

                                            </i>
                                        </div>

                                    )
                                    :
                                    <div><span style={{color: 'red'}}>Нет данных</span></div>
                                : null
                            }
                        </div>
                    )
                })}
            </div>
            {
                access && props.type != 3 &&
                <div
                    className={((showSelfCostSelector && canSeeSelfCost && getSelfCost() > 0) ? s.mainThreeBlocks : s.mainTwoBlocks) + " " + s.bold}>
                    <div>Курс выставления счёта: {check.exchange_rates} грн {/*check.paid === null &&
                        <span
                            className={s.pensil}
                            onClick={() =>
                                setShowUAHmodaleF(check.id, check.exchange_rates, check.numeral)}>
                            <FontAwesomeIcon icon={faPencilAlt}/>
                        </span>*/
                    }
                    </div>
                    <div>Сумма по счёту: <CurrencyFormatter
                        amount={parseFloat(check.numeral) ?? 0}
                        withSpaces={true}
                    /> {/*canSeeSelfCost && getSelfCost() > 0 &&
                        <span style={{color: showSelfCostSelector ? 'red' : 'green'}}
                              onClick={() => setShowSelfCostSelector(!showSelfCostSelector)}>
                                <Visibility/>
                            </span>
                    */}
                        {/*check.paid === null &&
                            <span
                                className={s.pensil}
                                onClick={() =>
                                    setShowUAHmodaleF(check.id, check.exchange_rates, check.numeral)}>
                            <FontAwesomeIcon icon={faPencilAlt}/>
                        </span>*/}
                    </div>
                    {showSelfCostSelector && canSeeSelfCost && getSelfCost() > 0 &&
                        <div></div>
                    }
                </div>
            }
            {
                showSelfCostSelector && canSeeSelfCost && getSelfCost() > 0 &&
                <div className={s.mainThreeBlocks + " " + s.bold}>
                    <div>Себестоимость по компонентам:</div>
                    <div></div>
                    <div><CurrencyFormatter
                        amount={getSelfCost() ?? 0}
                        withSpaces={true}/>
                    </div>
                </div>
            }
            {
                showSelfCostSelector && canSeeSelfCost && getSelfCost() > 0 &&
                <div className={s.mainThreeBlocks + " " + s.bold}>
                    <div>Маржа по компонентам:</div>
                    <div></div>
                    <div>{Math.round((check.numeral - getSelfCost()) / check.numeral * 100)} %</div>
                </div>
            }


            <div className={s.infoBlock}>
                <div>
                    <FontAwesomeIcon icon={faFolder}/> Use cases: {useCases.length} шт.
                    {useCases.length > 0 && (
                        <Button onClick={handleClickOpenUseCases} variant={"outlined"} style={{marginLeft: '20px'}}>
                            Просмотр
                        </Button>
                    )}
                    <Button onClick={handleClickOpen}
                            variant={'outlined'} style={{marginLeft: '20px'}}>
                        Добавить
                    </Button>
                </div>

                <div className={s.commentText}><FontAwesomeIcon
                    icon={faComment}/>Комментарий: <span>{check.cash_non_cash == 1 && "Отправка модулей осуществляется без счета и расходной. "}{check.comment}</span><span
                    className={s.pensil}
                    onClick={() => setShowCommentF(check.id, check.comment)}><FontAwesomeIcon
                    icon={faPencilAlt}/></span></div>
                {check.paid === null
                    ? <div><FontAwesomeIcon icon={faCalendarPlus}/>За сколько
                        сделаем: <span>{check.days_manager} дней <span className={s.pensil}
                                                                       onClick={() => setShowDaysManagerF(check.id, check.days_manager)}><FontAwesomeIcon
                            icon={faPencilAlt}/></span></span></div>
                    : <div><FontAwesomeIcon icon={faCalendarDay}/>Предварительная дата
                        отгрузки: <span><AnyFormatDate
                            date={check.date_manager}/></span></div>
                }
                {check.paid === null &&
                    <div><FontAwesomeIcon icon={faCalendarPlus}/>Плановая дата
                        оплаты: <span><AnyFormatDate
                            date={check.date_plan}/><span className={s.pensil}
                                                          onClick={() => setShowDatePlan(check.id)}><FontAwesomeIcon
                            icon={faPencilAlt}/></span></span></div>
                }
                <div><FontAwesomeIcon icon={faEnvelope}/>Почта на которую отправлен
                    счёт: <span>{check.email}</span>
                </div>
                <div><FontAwesomeIcon icon={faCode}/>ЕДРПОУ компании: <span>{check.edrpou}</span>
                </div>
                {check.type_check != 0 &&
                    <div><FontAwesomeIcon icon={faCode}/>Ремонтная
                        заявка <span>№ {check.type_check}</span></div>}
                <div><FontAwesomeIcon icon={faTruck}/>Адрес доставки Новой
                    Почты: <span>{check.delivery_address == "" ? "не заполнено" : check.delivery_address}</span>
                </div>

                <div><FontAwesomeIcon icon={faMap}/>{check.TTN !== "" ?
                    <span>ТТН: {check.TTN} (<AnyFormatDate
                        date={check.date_shipping}/>)</span> : "Счёт не отправлен"}
                </div>
                {access && props.type == 4 && !requestForKpStatus &&
                    <div><FontAwesomeIcon icon={faEnvelope}/><span
                        onClick={() => setRequestForKpStatusF(check.id, props.type)}>Отправить письмо с уточнением статуса КП</span>
                    </div>
                }
            </div>
            <div>
                {check.company_id && props.type == 4 &&
                    <MessagesCheckContainer companyId={check.company_id} checkId={check.id}/>}
            </div>
        </div>

    )
}
export default Check;