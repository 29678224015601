import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {getAllDistribution, getAllDistributionNomenclatures, addNewDistribution, addNewNom, addNewDistributionStatistics, changeDateDistribution, deleteDistribution, changeNameDistribution} from '../../../redux/sales-reducer';
import Distribution from './Distribution';
import Cookies from "js-cookie";

class DistributionContainer extends React.Component{
    currentPersonalId = this.props.match.params.personalId;
    componentDidMount(){
        let isAdmin = !!Cookies.get('adminID');
        if(!isAdmin){
            if(Cookies.get('id')!=this.currentPersonalId){
                this.currentPersonalId = Cookies.get('id');
            }
        }

        this.props.getAllDistribution(this.currentPersonalId);
        this.props.getAllDistributionNomenclatures(this.currentPersonalId);
    }
    componentDidUpdate(prevProps){
        //после изменения списка с компаниями выбираем все модули по счетам этих компаний
        if(prevProps.match.params.personalId !== this.props.match.params.personalId){
            this.props.getAllDistribution(this.currentPersonalId);
            this.props.getAllDistributionNomenclatures(this.currentPersonalId);
        }
    }
    
    render(){
        return (
            <div>
                <Distribution allDistribution={this.props.allDistribution} allDistributionNomenclatures={this.props.allDistributionNomenclatures} addNewDistribution={this.props.addNewDistribution} personalId={this.props.match.params.personalId} addNewNom={this.props.addNewNom} addNewDistributionStatistics={this.props.addNewDistributionStatistics} changeDateDistribution={this.props.changeDateDistribution} deleteDistribution={this.props.deleteDistribution} changeNameDistribution={this.props.changeNameDistribution}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    allDistribution: state.sales.allDistribution,
    allDistributionNomenclatures: state.sales.allDistributionNomenclatures,
})
export default compose( connect( mapStateToProps, {getAllDistribution, getAllDistributionNomenclatures, addNewDistribution, addNewNom, addNewDistributionStatistics, changeDateDistribution, deleteDistribution, changeNameDistribution}), withRouter)(DistributionContainer);