import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getCheckTest, getCheckTestModuls, getReturnModulsTest} from '../../../redux/sales-reducer';
import CheckTest from './CheckTest';

class CheckTestContainer extends React.Component{
    componentDidMount(){
        this.props.getCheckTest(2);
        this.props.getCheckTestModuls(2);
    }
    
    render(){
        return (
            <div>
                <CheckTest checkTest={this.props.checkTest} checkTestModuls={this.props.checkTestModuls} getReturnModulsTest={this.props.getReturnModulsTest}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    checkTest: state.sales.checkTest,
    checkTestModuls: state.sales.checkTestModuls,
})
export default compose( connect( mapStateToProps, {getCheckTest, getCheckTestModuls, getReturnModulsTest}), withRouter )(CheckTestContainer);