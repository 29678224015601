import React from 'react';
import {  withRouter, Route } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getTasks, setNewTask, addCommentForTask, addNewDateInTask, setNewWorkerInTask, getTasksComment, setTaskWithoutArchive} from '../../../redux/tech-task-reducer';
import {getWorkersListTech} from '../../../redux/worker-reducer';
import Task from './Task';

class TaskContainer extends React.Component{
    
    componentDidMount(){
        this.props.getTasks();
        this.props.getWorkersListTech();
        this.props.getTasksComment();
    }
    componentDidUpdate(prevProps){
        // if(prevProps.tasksComment!=this.props.tasksComment){
        //     this.props.getTasksComment();
        // }
    }
    
    render(){
        return <Task tasks={this.props.tasks} setNewTask={this.props.setNewTask} addCommentForTask={this.props.addCommentForTask} workersListTech={this.props.workersListTech} addNewDateInTask={this.props.addNewDateInTask} setNewWorkerInTask={this.props.setNewWorkerInTask} tasksComment={this.props.tasksComment} getTasks={this.props.getTasks} setTaskWithoutArchive={this.props.setTaskWithoutArchive}/>
    } 
}

let mapStateToProps = (state) =>({
    tasks: state.techTask.tasks,
    tasksComment: state.techTask.tasksComment,
    workersListTech: state.worker.workersListTech,
})
export default compose( connect( mapStateToProps, {getTasks, setNewTask, addCommentForTask, getWorkersListTech, addNewDateInTask, setNewWorkerInTask, getTasksComment, setTaskWithoutArchive}), withRouter )(TaskContainer);