import React, { useState } from 'react';
import MenuStatistics from '../MenuStatistics';
import s from './StatisticsModules.module.css';

const Statistics = (props) =>{
    let [year, setYear] = useState("2023");
    let handleClick = (e)=>{
        props.getModuleSalesStatistics(e);
        setYear(e);
    }
    let statisticsFor = ()=>{
        switch(year){
            case "0":
                return "Статистика по Украине за период с 15 апреля 2020 по текущий момент"
            case "2020":
                return "Статистика по Украине за период с 15 апреля по 31 декабря 2020 года"
            case "2021":
                return "Статистика по Украине за период с 01 января 2021 года по текущий момент"
            case "2022":
                return "Статистика по Украине за период с 01 января 2022 года по текущий момент"
            case "2023":
                return "Статистика по Украине за период с 01 января 2023 года по текущий момент"
            default:
                return "Статистика по Украине за период с 15 апреля по текущий момент"
        }
    }
    return(
        <div>
            <MenuStatistics />
            <div>
                <h1>Выбрать год:</h1>
                <div className={s.mainYear}>
                    <div onClick={()=>handleClick("0")}>Все</div>
                    <div onClick={()=>handleClick("2020")}>2020</div>
                    <div onClick={()=>handleClick("2021")}>2021</div>
                    <div onClick={()=>handleClick("2022")}>2022</div>
                    <div onClick={()=>handleClick("2023")}>2023</div>
                </div>
            </div>
            <div>
            <h1>
                {
                    statisticsFor()
                }
                
            </h1>
            <div className={s.main+" "+s.header}>
                    <div>#</div>
                    <div>Название</div>
                    <div>Кол. шт.</div>
                </div>
            {
                props.moduleSalesStatistics.map((p, i)=>{
                    return(
                        <div className={s.main} key={i}>
                            <div>{i+1}</div>
                            <div>{p.moduls_name}</div>
                            <div>{p.sum_coll}</div>
                        </div>
                    )
                })
                
            }
            
            </div>
        </div>
    )
}
export default Statistics;