import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getAllComponentsFromAllPaidNomenclatures, getMoneyDelivery} from '../../../redux/admin-reducer';
import DDC from './DDC';

class DDCContainer extends React.Component{
    
    componentDidMount(){
        this.props.getAllComponentsFromAllPaidNomenclatures();
        this.props.getMoneyDelivery();
    }
    componentDidUpdate(prevProps){
        
    }
    
    render(){
        return (
            <div>
                {!this.props.match.params.month && <Redirect to="/admin/ddc/2024/1"/>}
                <DDC components={this.props.components} sumCosts={this.props.sumCosts} sum={this.props.sum} sumRu={this.props.sumRu} sumMoneyDelivery={this.props.sumMoneyDelivery}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    articles: state.admin.articles,
    sumCosts: state.admin.sumCosts,
    sum: state.admin.sum,
    sumRu: state.admin.sumRu,
    sumMoneyDelivery: state.admin.sumMoneyDelivery,
    
})
export default compose( connect( mapStateToProps, {getAllComponentsFromAllPaidNomenclatures, getMoneyDelivery}), withRouter )(DDCContainer);