import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getAllArticles, getSubentries, addNewCash, getMoneyCash, getCashFlowForMonth, getSumCashFlowForMonth} from '../../redux/cash-reducer';
import Cash from './Cash';
import MonthBlock from '../Production/common/MonthBlock/MonthBlock';

class CashContainer extends React.Component{
    componentDidMount(){
        this.props.getAllArticles();
        this.props.getMoneyCash();
        this.props.getCashFlowForMonth(this.props.match.params.month);
        this.props.getSumCashFlowForMonth(this.props.match.params.month);
    }
    componentDidUpdate(prevProps){
        if(prevProps.match.params.month !== this.props.match.params.month){
            this.props.getCashFlowForMonth(this.props.match.params.month);
            this.props.getSumCashFlowForMonth(this.props.match.params.month);
        }
    }
    
    render(){
        return (
            <div>
                <MonthBlock path={"cash"} month={this.props.match.params.month} />
                <Cash articles={this.props.articles} getSubentries={this.props.getSubentries} subentries={this.props.subentries} addNewCash={this.props.addNewCash} cash={this.props.cash} cashFlowForMonth={this.props.cashFlowForMonth} sumCashFlowForMonth={this.props.sumCashFlowForMonth} month={this.props.match.params.month}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    articles: state.cash.articles,
    subentries: state.cash.subentries,
    cash: state.cash.cash,
    cashFlowForMonth: state.cash.cashFlowForMonth,
    sumCashFlowForMonth: state.cash.sumCashFlowForMonth,
})
export default compose( connect( mapStateToProps, {getAllArticles, getSubentries, addNewCash, getMoneyCash, getCashFlowForMonth, getSumCashFlowForMonth}), withRouter )(CashContainer);