import React from 'react';
import {  withRouter, Route } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {} from '../../redux/admin-reducer';
import Admin from './Admin';
import Menu from './Menu/Menu';

class AdminContainer extends React.Component{
    
    componentDidMount(){
    }
    componentDidUpdate(prevProps){
        
    }
    
    render(){
        return (
        <div>
            <Admin />
            <Menu />
        </div>
        )
    } 
}

let mapStateToProps = (state) =>({
})
export default compose( connect( mapStateToProps, {}), withRouter )(AdminContainer);