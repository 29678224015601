import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getApplicationPersonal} from '../../../../redux/future-reducer';
import ApplicationPersonal from './ApplicationPersonal';

class ApplicationPersonalContainer extends React.Component{
    componentDidMount(){
        this.props.getApplicationPersonal(this.props.id);
    }
    componentDidUpdate(prevProps){
        if(prevProps.id!==this.props.id) this.props.getApplicationPersonal(this.props.id);
    }
    
    render(){
        return <ApplicationPersonal applicationPersonal={this.props.applicationPersonal} idApp={this.props.id}/>
    } 
}

let mapStateToProps = (state) =>({
    applicationPersonal: state.future.applicationPersonal
})
export default compose( connect( mapStateToProps, {getApplicationPersonal}), withRouter )(ApplicationPersonalContainer);