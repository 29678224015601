import React from 'react';
import s from './Filtr.module.css';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faHeadSideMask} from '@fortawesome/free-solid-svg-icons'

const Filtr = (props) =>{
    let [state, setState] = useState(false)
    const check=()=>{
        props.showCheckFor6Mounth()
        setState(!state)
        props.setShowFiltr(true)
    }
    return(
    <div className={s.mainDiv}>
        <div className={state ? s.show+" "+s.main : s.none}>
            <div className={s.close} onClick={()=>setState(!state)}>Х</div>
            <div onClick={()=>check()}>Счета за 6 месяцев</div>
        </div>
        <div className={state ? s.none : s.show+" "+s.button} onClick={()=>setState(!state)}>
            <FontAwesomeIcon icon={faFilter} />
        </div>
    </div>)
}
export default Filtr;