import React from 'react';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {getCookie} from '../../redux/admin-reducer'
import Cookies from 'js-cookie'



export const withAuthRedirect = (Component)=>{
    
    class RedirectComponent extends React.Component{
        render(){
            if(!Cookies.get('id')) return <Redirect to="/login" />

            return <Component {...this.props} />
        }
    }
    return RedirectComponent
}