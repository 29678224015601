import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {getWorkersModulsOld, setModulsStatus} from '../../../../redux/worker-reducer';
import TechEngineer from './TechEngineer';

class TechEngineerContainer extends React.Component{
    componentDidMount(){
        this.props.getWorkersModulsOld();
    }
    render(){
        return(
            <TechEngineer
                workersModuls={this.props.workersModuls}
                setModulsStatus={this.props.setModulsStatus}
            />
        )
    }
    
}

let mapStateToProps = (state) =>{
    return{
        workersModuls: state.worker.workersModuls
    }
    
}

export default compose(
    connect(mapStateToProps, {getWorkersModulsOld, setModulsStatus}),
    withRouter
 )(TechEngineerContainer);