import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getPersonalAreaSalesCheck} from '../../../redux/personalArea-reducer';
import {getCountOverdueCheckTest} from '../../../redux/sales-reducer';
import PersonalAreaSalesCheck from './PersonalAreaSalesCheck';
import Cookies from "js-cookie";

class PersonalAreaSalesContainer extends React.Component{
    currentPersonalId = this.props.match.params.personalId;
    componentDidMount(){
        let isAdmin = !!Cookies.get('adminID');
        if(!isAdmin){
            if(Cookies.get('id')!=this.currentPersonalId){
                this.currentPersonalId = Cookies.get('id');
            }
        }

        this.props.getPersonalAreaSalesCheck(this.currentPersonalId);
        this.props.getCountOverdueCheckTest(1);
    }
    
    render(){
        return (
            // <PersonalAreaSales personalAreaSalesCheck={this.props.personalAreaSalesCheck} personalId={this.props.personalId} countOverdueCheckTest={this.props.countOverdueCheckTest}/>
            <PersonalAreaSalesCheck personalId={this.currentPersonalId} personalAreaSalesCheck={this.props.personalAreaSalesCheck} />
        )
    } 
}

let mapStateToProps = (state) =>({
    personalAreaSalesCheck: state.personalarea.personalAreaSalesCheck,
    countOverdueCheckTest: state.sales.countOverdueCheckTest
})
export default compose( connect( mapStateToProps, {getPersonalAreaSalesCheck, getCountOverdueCheckTest}), withRouter )(PersonalAreaSalesContainer);