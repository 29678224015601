import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    TextField,
    Button,
    Chip,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { companyAPI } from '../../../../api/api';
import Cookies from 'js-cookie';
import { salesManagersList } from '../../../global/ManagersList';

const FilterCategoriesCompanies = () => {
    const [companies, setCompanies] = useState({});
    const [categories, setCategories] = useState({});
    const [industries, setIndustries] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [filterMode, setFilterMode] = useState(false); // false for 'loose', true for 'strict'
    const [showEmails, setShowEmails] = useState(false);
    const [emailOnly, setEmailOnly] = useState(false);
    const [selectedManagerID, setSelectedManagerID] = useState(null); // Для збереження вибраного менеджера

    const isAdmin = !!Cookies.get('adminID'); // Перевірка, чи є користувач адміністратором
    const currentUserID = Cookies.get('userid'); // Поточний ID користувача

    const loadCompanies = async () => {
        const watchAsUserID = currentUserID; // Поточний користувач
        const watchUserID = selectedManagerID || currentUserID; // Обраний користувач або поточний

        await companyAPI.getCompanyListWithCategoriesAndIndustries({
            watchAsUserID,
            watchUserID,
        }).then((response) => {
            setCompanies(response.data);
            setCategories(response.categories);
            setIndustries(response.industries);
        });
    };

    useEffect(() => {
        loadCompanies();
    }, [selectedManagerID]); // Виклик при зміні обраного менеджера

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleCategoryChipDelete = (chipToDelete) => () => {
        setSelectedCategories((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleIndustryChipDelete = (chipToDelete) => () => {
        setSelectedIndustries((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleFilterModeChange = (event) => {
        setFilterMode(event.target.checked);
    };

    const handleShowEmailsChange = () => {
        if (showEmails) setEmailOnly(false);
        setShowEmails((prev) => !prev);
    };

    const handleEmailOnlyChange = () => {
        setEmailOnly((prev) => !prev);
    };

    const handleManagerChange = (id) => {
        setSelectedManagerID(id === 'all' ? null : id);
    };

    // Генеруємо ряди для таблиці компаній
    const rows = [];

    const filteredCompanies = Object.keys(companies).filter((key) => {
        const company = companies[key];
        const matchesSearchQuery = company.name_with_akon.toLowerCase().includes(searchQuery.toLowerCase());

        const companyCategoryIDs = company.categories ? Object.keys(company.categories) : [];
        const companyIndustryIDs = company.industries ? Object.keys(company.industries) : [];

        let matchesCategories = true;
        let matchesIndustries = true;

        if (selectedCategories.length > 0) {
            if (filterMode) {
                matchesCategories = selectedCategories.every((id) => companyCategoryIDs.includes(id));
            } else {
                matchesCategories = selectedCategories.some((id) => companyCategoryIDs.includes(id));
            }
        }

        if (selectedIndustries.length > 0) {
            if (filterMode) {
                matchesIndustries = selectedIndustries.every((id) => companyIndustryIDs.includes(id));
            } else {
                matchesIndustries = selectedIndustries.some((id) => companyIndustryIDs.includes(id));
            }
        }

        return matchesSearchQuery && matchesCategories && matchesIndustries;
    });

    filteredCompanies.forEach((key) => {
        const company = companies[key];
        const companyInfo = {
            id: key,
            name_with_akon: company.name_with_akon,
            categories: Object.values(company.categories || {}).join(', '),
            industries: Object.values(company.industries || {}).join(', '),
        };

        if (showEmails && company.contacts && Object.keys(company.contacts).length > 0) {
            Object.values(company.contacts).forEach((contact) => {
                rows.push({
                    ...companyInfo,
                    email: contact.email,
                });
            });
        } else if (!emailOnly) {
            rows.push({
                ...companyInfo,
                email: '',
            });
        }
    });

    return (
        <Box p={2}>
            <Typography variant="h6">Список компаний (всего: {Object.keys(companies).length})</Typography>

            {/* Вибір менеджера за допомогою кнопок */}
            {isAdmin && (
                <Box display="flex" alignItems="center" mt={2} flexWrap="wrap">
                    <Button
                        variant={selectedManagerID === null ? 'contained' : 'outlined'}
                        onClick={() => handleManagerChange('all')}
                        style={{ marginRight: '8px' }}
                    >
                        Все
                    </Button>
                    {salesManagersList.map((manager) => (
                        <Button
                            key={manager.id}
                            variant={selectedManagerID === manager.id ? 'contained' : 'outlined'}
                            onClick={() => handleManagerChange(manager.id)}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        >
                            {manager.name}
                        </Button>
                    ))}
                </Box>
            )}

            <Box display="flex" alignItems="center" flexWrap="wrap" mt={2}>
                <TextField
                    label="Поиск по названию компании"
                    variant="outlined"
                    value={searchQuery}
                    onChange={handleSearch}
                    size="small"
                    style={{ marginRight: '16px', flexGrow: 1 }}
                />
                <FormControlLabel
                    control={<Checkbox checked={filterMode} onChange={handleFilterModeChange} />}
                    label="Жёсткий фильтр"
                />
                <Button variant="contained" onClick={handleShowEmailsChange} style={{ marginLeft: '16px' }}>
                    {showEmails ? 'Скрыть Email' : 'Показать Email'}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleEmailOnlyChange}
                    style={{ marginLeft: '16px', display: showEmails ? 'block' : 'none' }}
                >
                    {emailOnly ? 'Показать все поля' : 'Показать только Email'}
                </Button>
            </Box>

            {/* Categories Chips */}
            <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                <Typography variant="body1" style={{ marginRight: '8px' }}>Категории:</Typography>
                {selectedCategories.map((id) => (
                    <Chip
                        key={id}
                        label={categories[id]}
                        onDelete={handleCategoryChipDelete(id)}
                        style={{ margin: '4px' }}
                    />
                ))}
                <Chip
                    label="Добавить категории"
                    style={{ backgroundColor: '#4caf50', color: 'white', margin: '4px' }}
                />
            </Box>

            {/* Industries Chips */}
            <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                <Typography variant="body1" style={{ marginRight: '8px' }}>Отрасли:</Typography>
                {selectedIndustries.map((id) => (
                    <Chip
                        key={id}
                        label={industries[id]}
                        onDelete={handleIndustryChipDelete(id)}
                        style={{ margin: '4px' }}
                    />
                ))}
                <Chip
                    label="Добавить отрасли"
                    style={{ backgroundColor: '#4caf50', color: 'white', margin: '4px' }}
                />
            </Box>

            {/* Companies Table */}
            <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {!emailOnly && <TableCell>Название компании</TableCell>}
                            {!emailOnly && <TableCell>Категории</TableCell>}
                            {!emailOnly && <TableCell>Отрасли</TableCell>}
                            {showEmails && <TableCell>Email</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                {!emailOnly && (
                                    <TableCell>
                                        <NavLink to={`/company/profile/${row.id}`}>
                                            {row.name_with_akon}
                                        </NavLink>
                                    </TableCell>
                                )}
                                {!emailOnly && <TableCell>{row.categories}</TableCell>}
                                {!emailOnly && <TableCell>{row.industries}</TableCell>}
                                {showEmails && <TableCell>{row.email}</TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default FilterCategoriesCompanies;
