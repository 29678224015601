import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import s from '../Task.module.css';

const ChangeTextTask = (props) =>{
    const [cookies, setCookie] = useCookies();
    let [text, setText] = useState("")
    const setTextF=(e)=>{
        setText(e.target.value)
    }
    const ChangeText=()=>{
        let array = [];
        array.push(props.id, text)
        // console.log(props)
        props.changeTextTask(array, cookies.id)
        props.setActive(false)
    }
    return(
        <div className={s.addComment}>
           <div><h3>Изменение текста записи №{props.id}</h3></div>
           <div><textarea rows="10" onChange={setTextF}>{props.active}</textarea></div>
           <div className={s.center}><button onClick={()=>ChangeText()}>Изменить</button></div>
        </div>
    )
}
export default withRouter(ChangeTextTask);