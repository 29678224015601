import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faDollarSign, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faListAlt, faFileAlt } from '@fortawesome/free-regular-svg-icons'
import s from './PersonalKp.module.css';
import AnyFormatDate from '../../../Production/common/AnyFormatDate/AnyFormatDate';
import { useState } from 'react';
import Modal from '../../../Production/common/Modal/Modal'
import DeleteKp from './DeleteKp'
import ChangeDatePlanKp from './ChangeDatePlanKp'

const PersonalKp = (props) =>{
    let [datePlanKp, setDatePlanKp] = useState(false)
    let [deleteKp, setDeleteKp] = useState(false)
    return(
        <div>
            {datePlanKp &&
            <Modal active={datePlanKp} setActive={setDatePlanKp}>
                <ChangeDatePlanKp active={datePlanKp} setActive={setDatePlanKp} changeAnything={props.changeDatePlanKp} personalId={props.personalId}/>
            </Modal>
            }
            {deleteKp &&
            <Modal active={deleteKp} setActive={setDeleteKp}>
                <DeleteKp active={deleteKp} setActive={setDeleteKp} changeAnything={props.setShowKp} personalId={props.personalId}/>
            </Modal>
            }
            <div className={s.mainCheck}>
            {
            props.personalKpForPeriod.map((k, index)=>{
                return(
                
                    <div className={s.mainCheckDiv} key={"mainCheckDiv_"+k.id}>
                        <div className={s.twoBlocksGrid}>
                            <div className={s.left}>
                                <NavLink to={"/task/"+props.personalId} target="_blank"><FontAwesomeIcon icon={faListAlt} /></NavLink>
                                <span className={s.iconDelete} onClick={()=>setDeleteKp(k.id)}><FontAwesomeIcon icon={faTrash} /></span>
                            </div>
                            <div className={s.checkDate}><span onClick={()=>setDatePlanKp(k.id)}><FontAwesomeIcon icon={faCalendarAlt} /><AnyFormatDate date={k.date_plan}/></span></div>
                        </div>
                        <NavLink to={"/check/"+k.id+"/4"} key={"check_"+index} target="_blank">
                            <div><h3><FontAwesomeIcon icon={faFileAlt} /> {k.number}</h3></div>
                            <div><span>{k.status_name}</span></div>
                            <div><span className={s.red}>Не оплачен</span></div>
                            <div>{k.name_with_akon}</div>
                            <div><FontAwesomeIcon icon={faDollarSign} />{k.exchange_rates}</div>
                        </NavLink>
                    </div>
                
                )
            })
            }
            </div>
        </div>
        
    )
}
export default withRouter(PersonalKp);