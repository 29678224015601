import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getAllCompanyWithManager, setCompanyToPlanner, getAllCompanyPlanerInThisDate} from '../../../redux/sales-reducer';
import AllCompanyWithManager from './AllCompanyWithManager';
import Cookies from 'js-cookie'

class AllCompanyWithManagerContainer extends React.Component{
    componentDidMount(){
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.match.params.manager;
        if(!isAdmin){
            if(Cookies.get('id')!=currentPersonalId){
                currentPersonalId = Cookies.get('id');
            }
        }
        this.props.getAllCompanyWithManager(currentPersonalId);
        console.log(currentPersonalId)
    }
    
    render(){
        return (
            <div>
                <AllCompanyWithManager {...this.props}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    allCompanyWithManager: state.sales.allCompanyWithManager,
    allSumCompanyWithManager: state.sales.allSumCompanyWithManager,
    countCompanyPlanerInThisDate: state.sales.countCompanyPlanerInThisDate,
})
export default compose( connect( mapStateToProps, {getAllCompanyWithManager, setCompanyToPlanner, getAllCompanyPlanerInThisDate}), withRouter )(AllCompanyWithManagerContainer);