import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import s from './PersonalAreaSales.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import { faCaretSquareLeft, faFileAlt, faListAlt } from '@fortawesome/free-regular-svg-icons'
import PersonalKpContainer from './PersonalKp/PersonalKpContainer';
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate';
const PersonalAreaSalesCheck = (props) =>{
    let paidCheck = (paid) =>{
        if(paid){
            return true
        }
        return false
    }
    return(
        <div>
            <div className={s.twoBlocksGrid}>
                <div>
                    <h1>Не оплаченные счета за 14 дней</h1>
                    <div className={s.mainCheck}>
                    {
                        props.personalAreaSalesCheck.map((k, index)=>{
                            return(
                                <NavLink to={"/check/"+k.id+"/0"} key={"check_"+index} target="_blank">
                                    {/* <div className={s.mainCheckDiv +" "+ }> */}
                                    <div className={s.mainCheckDiv}>
                                        <div className={s.twoBlocksGrid}>
                                            <div className={s.left}><NavLink to={"/task/"+props.personalId} target="_blank"><FontAwesomeIcon icon={faListAlt} /></NavLink></div>
                                            <div className={s.checkDate}><FontAwesomeIcon icon={faCalendarAlt} /><AnyFormatDate date={k.date_invoce_added}/></div>
                                        </div>
                                        <div><h3><FontAwesomeIcon icon={faFileAlt} /> {k.number}</h3></div>
                                        <div>{paidCheck(k.paid) ? <span className={s.green}>Оплачен</span> : <span className={s.red}>Не оплачен</span>}</div>
                                        <div>{k.name_with_akon}</div>
                                        <div><FontAwesomeIcon icon={faDollarSign} />{k.exchange_rates}</div>
                                    </div>
                                </NavLink>
                            )
                        })
                    }
                    </div>
                </div>
                <div>
                    <h1>План оплат КП за 14 дней</h1>
                    <PersonalKpContainer personalId={props.personalId}/>
                </div>
            </div>
            
            {/* <PlanCheckContainer personalId={props.personalId} /> */}
        </div>
    )
}
export default withRouter(PersonalAreaSalesCheck);