import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import s from '../Task.module.css';
import main from '../../Main.module.css';

const ChangeDate = (props) =>{
    console.log(props)
    const [cookies, setCookie] = useCookies();
    let [date, setDate] = useState("")
    const setDateF=(e)=>{
        setDate(e.target.value)
    }
    const сhangeDate=()=>{
        props.changeDateForTask(date, props.active, props.changeDateType, cookies.id)
        // console.log(date, props.active, props.changeDateType, cookies.id)
        props.setActive(false)
    }
    return(
        <div className={s.addComment}>
           <div><h3>Изменить дату в заявке №{props.active}</h3></div>
           <div><input type="date" onChange={setDateF} value={date} className={main.input}/></div>
           <div className={s.center}><button onClick={()=>сhangeDate()}>Изменить</button></div>
        </div>
    )
}
export default withRouter(ChangeDate);