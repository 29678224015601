import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {getKpPlan, getSumMonthKpPlan, getStatusKp} from '../../../../redux/sales-reducer';
import KpPlan from './KpPlan';
import Cookies from "js-cookie";

class KpPlanContainer extends React.Component{
    componentDidMount(){
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.match.params.idManager;
        if(!isAdmin){
            if(Cookies.get('id')!=currentPersonalId){
                currentPersonalId = Cookies.get('id');
            }
        }

        this.props.getKpPlan(currentPersonalId);
        this.props.getSumMonthKpPlan(currentPersonalId);
        this.props.getStatusKp();
    }
    
    render(){
        return (
            <div>
                <KpPlan {...this.props}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    kpPlan: state.sales.kpPlan,
    sumMonthKpPlan: state.sales.sumMonthKpPlan,
    statusKp: state.sales.statusKp,
})
export default compose( connect( mapStateToProps, {getKpPlan, getSumMonthKpPlan, getStatusKp}), withRouter )(KpPlanContainer);