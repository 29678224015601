import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    getPrivateOffice,
    getPrivateOfficeBlocks,
    addNewPersonalFiltrBlock,
    deletePersonalFiltrBlock,
    setShowPersonalOfficeField,
    setColorBgInPersonalFilter,
    getCountRepairFromSite,
    editPersonalFiltrBlockName
} from '../../redux/privateOffice-reducer';
import {getCourse} from '../../redux/admin-reducer';
import {getCountOverdueCheckTest} from '../../redux/sales-reducer';
import PrivateOffice from './PrivateOffice';
import Cookies from 'js-cookie'

class PrivateOfficeContainer extends React.Component{
    componentDidMount(){
        this.props.getPrivateOffice(Cookies.get('id') ?? 0);
        this.props.getPrivateOfficeBlocks(Cookies.get('id') ?? 0);
        this.props.getCountOverdueCheckTest(1);
        this.props.getCountOverdueCheckTest(2);
        this.props.getCourse();
        this.props.getCountRepairFromSite();
    }

    render(){
        return (
            <div>
                <PrivateOffice
                    privateOffice={this.props.privateOffice}
                    privateOfficeBlocks={this.props.privateOfficeBlocks}
                    addNewPersonalFiltrBlock={this.props.addNewPersonalFiltrBlock}
                    personalId={this.props.match.params.personalId}
                    deletePersonalFiltrBlock={this.props.deletePersonalFiltrBlock}
                    setShowPersonalOfficeField={this.props.setShowPersonalOfficeField}
                    setColorBgInPersonalFilter={this.props.setColorBgInPersonalFilter}
                    countOverdueCheckTest={this.props.countOverdueCheckTest}
                    countOverdueCheckTestBack={this.props.countOverdueCheckTestBack}
                    course={this.props.course}
                    countRepairFromSite={this.props.countRepairFromSite}
                    editPersonalFiltrBlockName = {this.props.editPersonalFiltrBlockName}
                />
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    privateOffice: state.privateOffice.privateOffice,
    privateOfficeBlocks: state.privateOffice.privateOfficeBlocks,
    countRepairFromSite: state.privateOffice.countRepairFromSite,
    countOverdueCheckTest: state.sales.countOverdueCheckTest,
    countOverdueCheckTestBack: state.sales.countOverdueCheckTestBack,
    course: state.admin.course,
})
export default compose( connect( mapStateToProps,
    {
        getPrivateOffice,
        getPrivateOfficeBlocks,
        addNewPersonalFiltrBlock,
        deletePersonalFiltrBlock,
        setShowPersonalOfficeField,
        setColorBgInPersonalFilter,
        getCountOverdueCheckTest,
        getCourse,
        getCountRepairFromSite,
        editPersonalFiltrBlockName
    }), withRouter )(PrivateOfficeContainer);
